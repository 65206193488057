import apiClient from "./BaseService";

export default {
    callCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employeeCreate", payload);
    },
    callUpdateDetailApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employeeDetailUpdate", payload);
    },
    callReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employeeRead", payload);
    },
    callSkillCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("skillCreate", payload);
    },
    callSkillEditApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("skillUpdate", payload);
    },
    callSkillReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("skillRead", payload);
    },
    callEducationCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("educationCreate", payload);
    },
    callEducationEditApi(payload) {
        // console.log("Service Payload ==>>", payload)callEducationCreateApi
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("educationUpdate", payload);
    },
    callEducationReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("educationRead", payload);
    },
    callSkillCategoryReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("skillCategoryRead", payload);
    },
    callEmploymentCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employmentHistoryCreate", payload);
    },
    callEmploymentReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employmentHistoryRead", payload);
    },
    callEmploymentEditApi(payload) {
        // console.log("Service Payload ==>>", payload)callEducationCreateApi
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employmentHistoryUpdate", payload);
    },
    callEmployeeDetailStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)callEducationCreateApi
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employeeDetailStatus", payload);
    },
};
