<template>
    <span>
        <button @click="createAccount()" class="btn btn-sm pd-x-15 btn-white btn-uppercase">
            <i data-feather="plus" class="wd-10 mg-r-5"></i> Add Account</button>
        <template>
            <loading :active.sync="company.createAccountLoading"
                     :can-cancel="false"
                     color="#6d0606"
                     :is-full-page="fullPage"></loading>
        </template>
    </span>
</template>

<script>
    import { mapState } from "vuex";
    import Loading from 'vue-loading-overlay';
    export default {
            name: "NewAccountButton",
            components:{
                Loading
            },
            data(){
                return{
                    fullPage: true,
                    model:{}
                }
            },
            methods:{
                async createAccount(){
                    this.model = this.company.company[0]
                    this.model.firstName = this.system.userInfo.firstName
                    this.model.lastName = this.system.userInfo.lastName
                    this.model.dialogs = this.$dialogs
                    const options = {title: 'Info!', size: 'sm'}
                    this.$dialogs.alert('Are you sure you want to add a new account', options)
                        .then(res => {
                            console.log(res) // {ok: true|false|undefined}
                            //this.$router.go(-1)
                            if (res.ok){
                                this.$store.dispatch("company/createAccount", this.model, {root: true})
                            }
                        })
                }
            },
            computed: {
                ...mapState([
                    'company',
                    'document',
                    'system',
                    'officer',
                ]),
            },
        }
</script>

<style scoped>

</style>