import DepartmentService from "../../service/DepartmentService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    departments:[],
    departmentLoading: false,
    metrics: {
        type:'last30',
        startDate:null,
        endDate:null,
    },
    response:{}

}

export const mutations = {
    updateDepartments: (state, payload) =>{
        state.departments = payload
    },
    updateMetrics: (state, payload) =>{
        state.metrics = payload
    },
    updateDepartmentLoading: (state, payload) =>{
        state.departmentLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    }
}

export const actions = {
    updateDepartments: ({ commit, state }, payload)=>{
        if(state.departments.length < 1)
            commit("updateDepartmentLoading", true)
        return DepartmentService.callReadApi(payload)
            .then(response => {
                commit("updateDepartmentLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateDepartmentLoading", false)
                    let responseData = response.data;
                    commit("updateDepartments", responseData.data)
                }

            })
            .catch(error => {
                commit("updateDepartmentLoading", false)
                alert(error)
                // throw error;
            });
    },
    createDepartment: ({ commit }, payload)=>{
        commit("updateDepartmentLoading", true)
        return DepartmentService.callCreateApi(payload)
            .then(response => {
                commit("updateDepartmentLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateDepartmentLoading", false)
                alert(error)
                // throw error;
            });
    }
}