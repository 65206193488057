import apiClient from "./BaseService";

export default {
    callUpdateSettingsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("settingsUpdate", payload);
    },
    callReadSettingsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employerReadSingle", payload);
    },
    callUploadLogoApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("uploadLogo", payload);
    },
    callUpdateEmployerAccountApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("updateEmployerAccount", payload);
    },
    callUpdateCreateNewBusinessAccountApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquareProfile.post("createCompanySubAccount", payload);
    }
};
