import AttendanceService from "@/service/AttendanceService";
import swal from "sweetalert";
// import router from "@/router";

export const state = {
    screen:'passcode',
    attendanceByEmployee:{
        data: [
        ],
        responseCode: '00',
        responseMessage:'success'
    },
    attendanceByEmployer:{
        data: [
        ],
        responseCode: '00',
        responseMessage:'success'
    },
    qr:{},
    attendanceLoading: false,
    qrLoading: false,
    imageExpenseLoading: false
}
export  const getter = {

}
export  const mutations = {
    updateAttendanceByEmployee:(state, payload) =>{
        state.attendanceByEmployee = payload
    },
    updateAttendanceByEmployer:(state, payload) =>{
        state.attendanceByEmployer = payload
    },
    uploadImageAttendanceLoading:(state, payload) =>{
        state.imageAttendanceLoading = payload
    },
    updateAttendanceLoading:(state, payload) =>{
        state.attendanceLoading = payload
    },
    updateScreen:(state, payload) =>{
        state.screen = payload
    },
    updateQr:(state, payload) =>{
        state.qr = payload
    },
    updateQrLoading:(state, payload) =>{
        state.qrLoading = payload
    },
}
export const actions = {
    updateAttendanceByEmployee: ({commit,state}, payload) => {
        if (state.attendanceByEmployee.data.length < 1)
            commit('updateAttendanceLoading', true)
        return AttendanceService.callReadAttendanceByEmployeeApi(payload)
            .then(response => {
                commit('updateAttendanceLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateAttendanceByEmployee', responseData)
                    commit('updateScreen', 'attendance')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateAttendanceLoading", false)
                //alert(error)
                // throw error;
            });
    },

    updateAttendanceByEmployer: ({commit,state}, payload) => {
        if (state.attendanceByEmployer.data.length < 1)
            commit('updateAttendanceLoading', true)
        return AttendanceService.callReadAttendanceByEmployerApi(payload)
            .then(response => {
                commit('updateAttendanceLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateAttendanceByEmployer', responseData)
                    commit('updateScreen', 'attendance')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateAttendanceLoading", false)
                //alert(error)
                // throw error;
            });
    },

    editAttendanceStatus: ({commit}, payload) => {
        commit('updateAttendanceLoading', true)
        return AttendanceService.callEditAttendanceStatusApi(payload)
            .then(response => {
                commit('updateAttendanceLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Sucess!', responseData.responseMessage, 'sucess!')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateAttendanceLoading", false)
                //alert(error)
                // throw error;
            });
    },

    editAttendance: ({commit}, payload) => {
        commit('updateAttendanceLoading', true)
        return AttendanceService.callAttendanceApi(payload)
            .then(response => {
                commit('updateAttendanceLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Sucess!', responseData.responseMessage, 'sucess!')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateAttendanceLoading", false)
                //alert(error)
                // throw error;
            });
    },

    generateQR: ({commit}, payload) => {
        commit('updateQrLoading', true)
        return AttendanceService.callGenerateQRApi(payload)
            .then(response => {
                commit('updateQrLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateQr', responseData)
                    //swal('Sucess!', responseData.responseMessage, 'sucess!')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateQrLoading", false)
                //alert(error)
                // throw error;
            });
    },

    captureQR: ({commit}, payload) => {
        commit('updateAttendanceLoading', true)
        return AttendanceService.callCaptureQRApi(payload)
            .then(response => {
                commit('updateAttendanceLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Sucess!', responseData.responseMessage, 'sucess!')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateAttendanceLoading", false)
                //alert(error)
                // throw error;
            });
    }
}