import ExpenseService from "../../service/ExpenseService";
import swal from 'sweetalert'


export const state = {
    expenses:[],
    categories:[],
    expenseLoading: false,
    categoriesLoading: false,
    response:{}
}

export const mutations = {
    updateExpenses: (state, payload) =>{
        state.expenses = payload
    },
    updateCategories: (state, payload) =>{
        state.categories = payload
    },
    updateCategoriesLoading: (state, payload) =>{
        state.categoriesLoading = payload
    },
    updateExpenseLoading: (state, payload) =>{
        state.expenseLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    }
}

export const actions = {
    updateExpenses: ({ commit, state }, payload)=>{
        if(state.expenses.length < 1)
            commit("updateExpenseLoading", true)
        return ExpenseService.callReadApi(payload)
            .then(response => {
                commit("updateExpenseLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateExpenseLoading", false)
                    let responseData = response.data;
                    commit("updateExpenses", responseData.data)
                }

            })
            .catch(error => {
                commit("updateExpenseLoading", false)
                alert(error)
                // throw error;
            });
    },
    updateExpenseCategory: ({ commit, state }, payload)=>{
        if(state.expenses.length < 1)
            commit("updateCategoriesLoading", true)
        return ExpenseService.callReadCategoryApi(payload)
            .then(response => {
                commit("updateCategoriesLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateCategoriesLoading", false)
                    let responseData = response.data;
                    commit("updateCategories", responseData.data)
                }

            })
            .catch(error => {
                commit("updateExpenseLoading", false)
                alert(error)
                // throw error;
            });
    },
    createExpense: ({ commit }, payload)=>{
        commit("updateExpenseLoading", true)
        return ExpenseService.callCreateApi(payload)
            .then(response => {
                commit("updateExpenseLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateExpenseLoading", false)
                alert(error)
                // throw error;
            });
    },
    editExpense: ({ commit }, payload)=>{
        commit("updateExpenseLoading", true)
        return ExpenseService.callUpdateApi(payload)
            .then(response => {
                commit("updateExpenseLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateExpenseLoading", false)
                alert(error)
                // throw error;
            });
    },
    deleteExpense: ({ commit }, payload)=>{
        commit("updateExpenseLoading", true)
        return ExpenseService.callDeleteApi(payload)
            .then(response => {
                commit("updateExpenseLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateExpenseLoading", false)
                alert(error)
                // throw error;
            });
    },
    editStatusExpense: ({ commit }, payload)=>{
        commit("updateExpenseLoading", true)
        return ExpenseService.callUpdateStatusApi(payload)
            .then(response => {
                commit("updateExpenseLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateExpenseLoading", false)
                alert(error)
                // throw error;
            });
    }
}