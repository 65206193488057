import GradeService from "../../service/GradeService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    grades:[],
    gradeSalaries:[],
    gradeLoading: false,
    metrics: {
        type:'last30',
        startDate:null,
        endDate:null,
    },
    response:{}
}

export const mutations = {
    updateGrades: (state, payload) =>{
        state.grades = payload
    },
    updateGradeSalaries: (state, payload) =>{
        state.gradeSalaries = payload
    },
    updateMetrics: (state, payload) =>{
        state.metrics = payload
    },
    updateGradeLoading: (state, payload) =>{
        state.gradeLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    }
}

export const actions = {
    updateGrades: ({ commit, state }, payload)=>{
        if(state.grades.length < 1)
            commit("updateGradeLoading", true)
        return GradeService.callReadApi(payload)
            .then(response => {
                commit("updateGradeLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateGradeLoading", false)
                    let responseData = response.data;
                    commit("updateGrades", responseData.data)
                }

            })
            .catch(error => {
                commit("updateGradeLoading", false)
                alert(error)
                // throw error;
            });
    },
    createGrade: ({ commit }, payload)=>{
        commit("updateGradeLoading", true)
        return GradeService.callCreateApi(payload)
            .then(response => {
                commit("updateGradeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateGradeLoading", false)
                alert(error)
                // throw error;
            });
    },
    updateGradeSalaryStatus: ({ commit }, payload)=>{
        commit("updateGradeLoading", true)
        return GradeService.callUpdateStatusGradeSalaryApi(payload)
            .then(response => {
                commit("updateGradeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateGradeLoading", false)
                alert(error)
                // throw error;
            });
    },
    editGradeSalary: ({ commit }, payload)=>{
        commit("updateGradeLoading", true)
        return GradeService.callUpdateGradeSalaryApi(payload)
            .then(response => {
                commit("updateGradeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateGradeLoading", false)
                alert(error)
                // throw error;
            });
    },
    updateGradeSalaries: ({ commit }, payload)=>{
        commit("updateGradeLoading", true)
        return GradeService.callGradeSalaryReadApi(payload)
            .then(response => {
                commit("updateGradeLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateGradeLoading", false)
                    let responseData = response.data;
                    commit("updateGradeSalaries", responseData.data)
                }

            })
            .catch(error => {
                commit("updateGradeLoading", false)
                alert(error)
                // throw error;
            });
    },
    createGradeSalary: ({ commit }, payload)=>{
        commit("updateGradeLoading", true)
        return GradeService.callCreateGradeSalaryApi(payload)
            .then(response => {
                commit("updateGradeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateGradeLoading", false)
                alert(error)
                // throw error;
            });
    }
}