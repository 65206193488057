import Expense from "@/views/expense/Expense";

export default [
    {
        path: '/expenses',
        name: 'Expense',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Expense,
    },
];
