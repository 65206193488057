import SLARequest from "@/model/request/SLARequest";
import SLAService from "@/service/SLAService";
import swal from "sweetalert";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";



export const state = {
    slas: [],
    slaLoading: false,
    sla:{},
    slaSheet: [],
    slaCode: ""
};

export const getters = {
  getSlaCode: state => {
      return state.slaCode;
  }
};

export const mutations = {
    updateSlas: (state, payload) =>{
        state.slas = payload
    },
    updateSlaLoading: (state, payload) =>{
        state.slaLoading = payload
    },
    updateSla: (state, payload) =>{
        state.sla = payload
    },
    updateSlaSheet: (state, payload) =>{
        state.slaSheet = payload
    },
    updateSlaCode: (state, payload) => {
        state.slaCode = payload;
    }

};

export const actions = {
    createSla({commit}, payload = SLARequest.create){
        commit("updateSlaLoading", true)
        return SLAService.callCreateApi(payload).then(response => {
            commit("updateSlaLoading", false)
            let responseData = response.data
            if (responseData.responseCode === "00") {
                swal('Success!', responseData.responseMessage, 'success')
                StoreUtils.dispatch(StoreUtils.actions.sla.READ_BY_COMPANY_CODE).then()
            }else{
                swal('Error!', responseData.responseMessage, 'error')
            }
        })
            .catch(error => {
                commit("updateSlaLoading", false)
                alert(error)
                // throw error;
            });
    },
    editSla({commit}, payload = SLARequest.edit){
        commit("updateSlaLoading", true)
        return SLAService.callUpdateApi(payload).then(response => {
            commit("updateSlaLoading", false)
            let responseData = response.data
            if (responseData.responseCode === "00") {
                swal('Success!', responseData.responseMessage, 'success')
            }else{
                swal('Error!', responseData.responseMessage, 'error')
            }
        })
            .catch(error => {
                commit("updateSlaLoading", false)
                alert(error)
                // throw error;
            });
    },
    readSlaById({commit}, payload = SLARequest.readBy){
        commit("updateSlaLoading", true)
        return SLAService.callReadById(payload).then(response => {
            commit("updateSlaLoading", false)
            let responseData = response.data
            if (responseData.responseCode === "00") {
                commit("updateSla", responseData.data)
                swal('Success!', responseData.responseMessage, 'success')
            }else{
                swal('Error!', responseData.responseMessage, 'error')
            }
        })
            .catch(error => {
                commit("updateSlaLoading", false)
                alert(error)
                // throw error;
            });
    },
    readSlaByCompanyCode({commit}, payload = SLARequest.readByCompany){
        commit("updateSlaLoading", true)
        return SLAService.callReadByCompanyCode(payload).then(response => {
            commit("updateSlaLoading", false)
            let responseData = response.data
            if (responseData.responseCode === "00") {
            commit("updateSlas", responseData.data)
        }})
            .catch(error => {
                commit("updateSlaLoading", false)
                alert(error)
                // throw error;
            });
    },
    readSheetBySlaCode({commit}, payload){
        commit("updateSlaLoading", true)
        return SLAService.readSheetBySla(payload).then(response => {
            commit("updateSlaLoading", false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                commit("updateSlaSheet", responseData.data)
            }
        })
            .catch(error => {
                commit("updateSlaLoading", false)
                alert(error)
                // throw error;
            });
    }
};