import PayrollService from "../../service/PayrollService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    variablePayments:[],
    variablePaymentLoading: false,
    response:{}

}

export const mutations = {
    updateVariablePayments: (state, payload) =>{
        state.variablePayments = payload
    },
    updateVariablePaymentLoading: (state, payload) =>{
        state.variablePaymentLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
}

export const actions = {
    updateVariablePayments: ({ commit, state }, payload)=>{
        if(state.variablePayments.length < 1)
            commit("updateVariablePaymentLoading", true)
        return PayrollService.callReadVariablePayApi(payload)
            .then(response => {
                commit("updateVariablePaymentLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateVariablePaymentLoading", false)
                    let responseData = response.data;
                    commit("updateVariablePayments", responseData.data)
                }

            })
            .catch(error => {
                commit("updateVariablePaymentLoading", false)
                alert(error)
                // throw error;
            });
    },
    createVariablePayment: ({ commit }, payload)=>{
        commit("updateVariablePaymentLoading", true)
        return PayrollService.callCreateVariablePayApi(payload)
            .then(response => {
                commit("updateVariablePaymentLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateVariablePaymentLoading", false)
                alert(error)
                // throw error;
            });
    },
    uploadVariablePayment: ({ commit }, payload)=>{
        commit("updateVariablePaymentLoading", true)
        return PayrollService.callUploadVariablePayApi(payload)
            .then(response => {
                commit("updateVariablePaymentLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateVariablePaymentLoading", false)
                alert(error)
                // throw error;
            });
    },
    processVariablePayment: ({ commit }, payload)=>{
        commit("updateVariablePaymentLoading", true)
        return PayrollService.callProcessVariablePayApi(payload)
            .then(response => {
                commit("updateVariablePaymentLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateVariablePaymentLoading", false)
                alert(error)
                // throw error;
            });
    },
    updateStatusVariablePayment: ({ commit }, payload)=>{
        commit("updateVariablePaymentLoading", true)
        return PayrollService.callUpdateStatusVariablePayApi(payload)
            .then(response => {
                commit("updateVariablePaymentLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateVariablePaymentLoading", false)
                alert(error)
                // throw error;
            });
    },
    editVariablePayment: ({ commit }, payload)=>{
        commit("updateVariablePaymentLoading", true)
        return PayrollService.callUpdateVariablePayApi(payload)
            .then(response => {
                commit("updateVariablePaymentLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateVariablePaymentLoading", false)
                alert(error)
                // throw error;
            });
    },
}