import ProjectService from "../../service/ProjectService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    projects:[],
    projectSingle:{},
    projectTasks:[],
    tasks:[],
    projectTaskSingle:{},
    projectComments:[],
    taskComments:[],
    projectLoading: false,
    commentLoading: false,
    projectSingleLoading: false,
    response:{}

}

export const mutations = {
    updateProjects: (state, payload) =>{
        state.projects = payload
    },
    updateProjectSingle: (state, payload) =>{
        state.projectSingle = payload
    },
    updateProjectTasks: (state, payload) =>{
        state.projectTasks = payload
    },
    updateTasks: (state, payload) =>{
        state.tasks = payload
    },
    updateProjectTaskSingle: (state, payload) =>{
        state.projectTaskSingle = payload
    },
    updateProjectLoading: (state, payload) =>{
        state.projectLoading = payload
    },
    updateCommentLoading: (state, payload) =>{
        state.commentLoading = payload
    },
    updateProjectComments: (state, payload) =>{
        state.projectComments = payload
    },
    updateTaskComments: (state, payload) =>{
        state.taskComments = payload
    },
    updateProjectSingleLoading: (state, payload) =>{
        state.projectSingleLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
}

export const actions = {
    updateProjects: ({ commit, state }, payload)=>{
        if(state.projects.length < 1)
            commit("updateProjectLoading", true)
        return ProjectService.callReadProjectApi(payload)
            .then(response => {
                commit("updateProjectLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateProjectLoading", false)
                    let responseData = response.data;
                    commit("updateProjects", responseData.data)
                }

            })
            .catch(error => {
                commit("updateProjectLoading", false)
                alert(error)
                // throw error;
            });
    },
    editProject: ({ commit }, payload)=>{
        commit("updateProjectSingleLoading", true)
        return ProjectService.callUpdateProjectApi(payload)
            .then(response => {
                commit("updateProjectSingleLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateProjectSingleLoading", false)
                    let responseData = response.data;
                    commit("updateProjectSingle", responseData)
                }

            })
            .catch(error => {
                commit("updateProjectSingleLoading", false)
                alert(error)
                // throw error;
            });
    },
    createProject: ({ commit }, payload)=>{
        commit("updateProjectLoading", true)
        return ProjectService.callCreateProjectApi(payload)
            .then(response => {
                commit("updateProjectLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateProjectLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateProjectTasks: ({ commit, state }, payload)=>{
        if(state.projectTasks.length < 1)
            commit("updateProjectLoading", true)
        return ProjectService.callReadProjectTaskApi(payload)
            .then(response => {
                commit("updateProjectLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateProjectLoading", false)
                    let responseData = response.data;
                    commit("updateProjectTasks", responseData.data)
                }

            })
            .catch(error => {
                commit("updateProjectLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateTasks: ({ commit, state }, payload)=>{
        if(state.projectTasks.length < 1)
            commit("updateProjectLoading", true)
        return ProjectService.callReadTaskApi(payload)
            .then(response => {
                commit("updateProjectLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateProjectLoading", false)
                    let responseData = response.data;
                    commit("updateTasks", responseData.data)
                }

            })
            .catch(error => {
                commit("updateProjectLoading", false)
                alert(error)
                // throw error;
            });
    },
    editProjectTask: ({ commit }, payload)=>{
        commit("updateProjectSingleLoading", true)
        return ProjectService.callUpdateProjectTaskApi(payload)
            .then(response => {
                commit("updateProjectLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateProjectLoading", false)
                    let responseData = response.data;
                    commit("updateProject", responseData)
                }

            })
            .catch(error => {
                commit("updateProjectSingleLoading", false)
                alert(error)
                // throw error;
            });
    },
    createProjectTask: ({ commit }, payload)=>{
        commit("updateProjectLoading", true)
        return ProjectService.callCreateProjectTaskApi(payload)
            .then(response => {
                commit("updateProjectLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateProjectLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateProjectComments: ({ commit }, payload)=>{
        commit("updateCommentLoading", true)
        return ProjectService.callReadCommentApi(payload)
            .then(response => {
                commit("updateProjectLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateCommentLoading", false)
                    let responseData = response.data;
                    commit("updateProjectComments", responseData.data)
                }

            })
            .catch(error => {
                commit("updateCommentLoading", false)
                alert(error)
                // throw error;
            });
    },
    editProjectComment: ({ commit }, payload)=>{
        commit("updateProjectSingleLoading", true)
        return ProjectService.callUpdateProjectCommentApi(payload)
            .then(response => {
                commit("updateProjectLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateProjectLoading", false)
                    let responseData = response.data;
                    commit("updateProject", responseData)
                }

            })
            .catch(error => {
                commit("updateProjectSingleLoading", false)
                alert(error)
                // throw error;
            });
    },
    createProjectComment: ({ commit }, payload)=>{
        commit("updateProjectLoading", true)
        return ProjectService.callCreateProjectCommentApi(payload)
            .then(response => {
                commit("updateProjectLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateProjectLoading", false)
                alert(error)
                // throw error;
            });
    },

}