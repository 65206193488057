import Projects from "@/views/project/Projects";
import ProjectTask from "@/views/project/ProjectTask";
import SingleProjectTask from "@/views/project/SingleProjectTask";

export default [
    {
        path: '/projects',
        name: 'Projects',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Projects,
    },
    {
        path: '/project-tasks',
        name: 'ProjectTask',
        meta: {layout: 'on-boarding',authRequired:true},
        component: ProjectTask,
    },
    {
        path: '/single-project-tasks',
        name: 'SingleProjectTask',
        meta: {layout: 'on-boarding',authRequired:true},
        component: SingleProjectTask,
    },
];
