import apiClient from "./BaseService";

export default {
    callCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        // payload.clientCompanyCode = localStorage.orgId;
        return apiClient.timeSheet.post("/sla/create", payload);
    },
    callReadById(payload) {
        // payload.clientCompanyCode = localStorage.orgId;
        return apiClient.timeSheet.post("/sla/read-by-id", payload);
    },
    callReadByCompanyCode(payload) {
        // payload.clientCompanyCode = localStorage.orgId;
        return apiClient.timeSheet.post("/sla/read-by-sla-company-code", payload);
    },
    callUpdateApi(payload){
        return apiClient.timeSheet.post("/sla/update", payload)
    },
    readSheetBySla(payload){
        return apiClient.timeSheet.post("/sheet/read-by-sheet-sla-code", payload)
    }
}