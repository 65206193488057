import SettingsService from "../../service/SettingsService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    employer:{},
    settingSingle:{},
    settingLoading: false,
    settingSingleLoading: false,
    response:{}

}

export const mutations = {
    updateSettings: (state, payload) =>{
        state.employer = payload
    },
    updateSettingSingle: (state, payload) =>{
        state.settingSingle = payload
    },
    updateSettingLoading: (state, payload) =>{
        state.settingLoading = payload
    },
    updateSettingSingleLoading: (state, payload) =>{
        state.settingSingleLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
}

export const actions = {
    updateSettings: ({ commit }, payload)=>{
       if(state.employer.employerId != null)
            commit("updateSettingLoading", true)
        return SettingsService.callReadSettingsApi(payload)
            .then(response => {
                commit("updateSettingLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateSettingLoading", false)
                    let responseData = response.data;
                    commit("updateSettings", responseData)
                }

            })
            .catch(error => {
                commit("updateSettingLoading", false)
                alert(error)
                // throw error;
            });
    },
    editSettings: ({ commit }, payload)=>{
        commit("updateSettingLoading", true)
        return SettingsService.callUpdateSettingsApi(payload)
            .then(response => {
                commit("updateSettingLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateSettingLoading", false)
                alert(error)
                // throw error;
            });
    },
    uploadLogo: ({ commit }, payload)=>{
        commit("updateSettingLoading", true)
        return SettingsService.callUploadLogoApi(payload)
            .then(response => {
                commit("updateSettingLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateSettingLoading", false)
                alert(error)
                // throw error;
            });
    },
    updateEmployerAccount: ({ commit }, payload)=>{
        commit("updateSettingLoading", true)
        return SettingsService.callUpdateEmployerAccountApi(payload)
            .then(response => {
                commit("updateSettingLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateSettingLoading", false)
                alert(error)
                // throw error;
            });
    },
    createNewBusinessAccount: ({ commit }, payload)=>{
        commit("updateSettingLoading", false)
        return SettingsService.callUpdateCreateNewBusinessAccountApi(payload)
    },
}