<template>
    <b-modal id="image-upload-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="image-upload-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4>Upload File For Processing</h4>
                <p class="tx-color-03">Upload file for processing</p>
                <b-form @submit.prevent="" enctype="multipart/form-data">
                    <b-form-group>
                        <div class="dropbox">
                            <input multiple type="file" class="input-file" id="files" accept="image/*" :name="file.uploadFieldName"
                                   :disabled="isLoading"
                                   @change="uploadFile" ref="file">
                            <p v-if="!isLoading">
                                Drag your file(s) here to begin<br> or click to browse
                            </p>
                            <p v-if="isLoading">
                                Uploading files...
                            </p>

                        </div>
                    </b-form-group>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: 'Loan',
        props:['uploadType','readType','isLoading','uploadData','readData','header','show'],
        data() {
            return {
                model:{
                    data: ''
                },
                file: {
                    accountnumber: '',
                    file: '',
                    amount: '',
                    status: false,
                    bank: {},
                    narration: '',
                    uploadedFiles: [],
                    uploadError: null,
                    currentStatus: null,
                    uploadFieldName: 'photos',
                },
            }
        },
        components: {
            // BaseButton,
            // BaseTable,
            // BaseTopCards,
            // PrinterIcon,
            // PlusIcon,
        },
        computed: {
            grades(){
                return  this.grade.grades
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            ...mapState([
                'grade',
                'auth'
            ])
        },
        methods: {
            uploadFile(event) {
                console.log(event)
                this.handleUpload(event)
            },

            async handleUpload(event) {
                const file = event.target.files[0];
                const reader = new FileReader();
                try {
                    reader.onload = async e => {
                        this.model.data = e.target.result
                        console.log(this.model)
                        await this.$store.dispatch(this.uploadType, this.model);
                        this.loadData();
                        this.hide();
                    };
                    reader.readAsDataURL(file);
                    this.$emit('input', file);
                } catch (e) {
                    console.warn(e.message)
                }
            },

            loadData(){
                this.$store.dispatch(this.readType, this.readData, {root: false}).then()
            },
            hide() {
                this.$refs['image-upload-modal'].hide()
            },
            onSelect({name, iso2, dialCode}) {
                this.model.employeeCountryCode =  dialCode
                console.log(name, iso2, dialCode);
            },
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {
            this.loadData()
        },
        watch: {
            show: function () {
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.uploadData).forEach(function(key,index) {
                    self.model[key] = self.uploadData[key];
                });
                this.$refs['image-upload-modal'].show()
            }
        },
    }


</script>

<style scoped>
    .dropbox {
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: lightcyan;
        color: dimgray;
        padding: 10px 10px;
        min-height: 200px; /* minimum height */
        position: relative;
        cursor: pointer;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: lightblue; /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
    }
</style>
