<template>
    <b-modal id="employment-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="employment-create-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4 v-if="model.type === 'edit'">Edit Employment</h4>
                <h4 v-else>Add New Employment</h4>
                <b-form @submit.prevent="submit" enctype="multipart/form-data">
                  <b-form-group label="Staff ID">
                      <b-input placeholder="Staff ID" v-model="model.employeeDetailStaffId"/>
                  </b-form-group>
                  <b-form-group label="Department">
                    <b-select v-model="model.employeeDetailDepartment">
                      <b-select-option value="">Select Department</b-select-option>
                      <b-select-option v-for="dpt in department.departments" :value="dpt.employerDepartmentCode" v-bind:key="dpt.employerDepartmentCode">{{dpt.employerDepartmentName}}</b-select-option>
                    </b-select>
                  </b-form-group>
                  <b-form-group label="Grade Code">
                    <b-select v-model="model.employeeDetailGradeCode">
                      <b-select-option value="">Grade Code</b-select-option>
                      <b-select-option v-for="grd in grade.grades" :value="grd.employerGradeCode" v-bind:key="grd.employerGradeCode">{{grd.employerGradeName}}</b-select-option>
                    </b-select>
                  </b-form-group>
                  <b-form-group label="Role">
                    <b-input placeholder="Role" v-model="model.employeeDetailRole"/>
                  </b-form-group>
                  <b-form-group label="Select Employment Type">
                    <b-select v-model="model.employeeDetailType">
                      <b-select-option value="">Select Employment Type</b-select-option>
                      <b-select-option value="FULL-TIME">Full-time</b-select-option>
                      <b-select-option value="PART-TIME">Part-time</b-select-option>
                      <b-select-option value="SELF-EMPLOYED">Self-Employed</b-select-option>
                      <b-select-option value="FREELANCE">Freelance</b-select-option>
                      <b-select-option value="CONTRACT">Contract</b-select-option>
                      <b-select-option value="INTERNSHIP">Internship</b-select-option>
                      <b-select-option value="APPRENTICESHIP">Apprenticeship</b-select-option>
                      <b-select-option value="SEASONAL">Seasonal</b-select-option>
                    </b-select>
                  </b-form-group>
                  <b-form-group label="Remark">
                    <b-input placeholder="Remark" v-model="model.employeeDetailRemark"/>
                  </b-form-group>
                  <b-form-group v-if="model.type === 'edit'" label="Status">
                    <b-select required v-model="model.employeeDetailStatus">
                      <b-select-option  value="">Select action type</b-select-option>
                      <b-select-option value="DELETED">DELETE</b-select-option>
                      <b-select-option value="SUSPENDED">SUSPEND</b-select-option>
                      <b-select-option value="ACTIVE">ACTIVATE</b-select-option>
                      <b-select-option value="RESIGNED">RESIGN</b-select-option>
                      <b-select-option value="DISMISSED">DISMISS</b-select-option>
                    </b-select>
                  </b-form-group>
<!--                    <b-form-group label="Status">-->
<!--                        <b-select required v-model="model.employerGradeSalaryItemType">-->
<!--                            <b-select-option  value="ACTIVE">ACTIVE</b-select-option>-->
<!--                            <b-select-option  value="DELETED">DELETE</b-select-option>-->
<!--                        </b-select>-->
<!--                    </b-form-group>-->
                    <base-button title="Submit" :loading="employee.employeeLoading"/>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row'],
        components:{BaseButton},
        name: 'ConfirmModal',
        data(){
            return{
                password: '',
                model:{
                  employeeDetailGradeCode: '',
                  employeeDetailStaffId: '',
                  employeeDetailEmployeeId: '',
                  employeeDetailEmployerId: '',
                  employeeDetailDepartment: '',
                  employeeDetailLocation: '',
                  employeeDetailStatus: '',
                  employeeDetailRole: '',
                  employeeDetailType: '',
                  employeeDetailMaker: '',
                  employeeDetailManagerId: '',
                  employeeDetailRemark: ''
                },
            }
        },
        methods:{
            hide() {
                this.$refs['employment-create-modal'].hide()
            },
            async submit(){
                if (this.model.employeeDetailEmployerId.match(/^[0-9]+$/))
                  this.model.employeeDetailEmployerId = localStorage.orgId
                if (this.model.type === 'edit')
                  await this.$store.dispatch("employee/editEmployeeDetail", this.model, {root: false});
                else {
                  this.model.employeeDetailEmployeeId = this.row.employeeId
                  await this.$store.dispatch("employee/createEmployment", this.model, {root: false});
                }
                this.hide();
                this.$store.dispatch("employee/updateEmployees", {
                  readAll:'NO',
                  employerId: localStorage.orgId
                }, {root: false});
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            hideauth: function () {
                this.password = '';
                this.$refs['employment-create-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row readEmCategory

        },
        computed:{
            ...mapState(['grade','auth','employee','department']),
        },
        watch: {
            show: function () {
                let self = this;
              // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                this.$refs['employment-create-modal'].show()
            }
        },
    }
</script>
