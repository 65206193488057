import apiClient from "./BaseService";

export default {
    callCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("createExpense", payload);
    },
    callReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("readExpenseByEmployer", payload);
    },
    callReadCategoryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("readExpenseCategories", payload);
    },
    callUpdateApi(payload) {
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("updateExpense", payload);
    },
    callUpdateStatusApi(payload) {
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("updateExpenseStatus", payload);
    },
    callDeleteApi(payload) {
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("deleteExpense", payload);
    },
};
