<template>
    <div class="content-body mt-10">
        <div class="container pd-x-0">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <h4 class="mg-b-0 tx-spacing--1"><a href="#" class="aside-logo">
                        <img src="https://qusquare.com/Images/logo.png" height="40px" width="50px"/>
                    </a>QooSquare</h4>
                </div>
                <div class="d-none d-md-block">
                    <b-form v-if="auth.userInfo.companies.data.length > 0" inline>
                        <b-input-group label="Switch Company: ">
                            <b-input class="col-md-4" disabled value="Switch Company: "/>
                            <b-select v-model="activeCompany" class="col-md-6">
                                <b-select-option v-for="company in auth.userInfo.companies.data" :value="company.orgId" v-bind:key="company.orgId">{{company.orgName}}</b-select-option>
                            </b-select>
                            <b-button variant="secondary" @click="logout()" class="col-md-3"><b-icon-power/></b-button>
                        </b-input-group >
                    </b-form>
                    <b-button v-else variant="secondary"  @click="logout()"   ><b-icon-power/> &nbsp;Logout</b-button>
<!--                    <p style="position: absolute;margin-bottom: -20px; margin-top: 10px;">Logout</p>-->
                </div>
            </div>
            <!--TODO TOP CARDS-->
            <main-top-card></main-top-card>


        </div><!-- container -->
    </div>
</template>

<script>
    // @ is an alias to /src
    import MainTopCard from "../../components/card/MainTopCard";
    import {mapState} from "vuex";
    // import  { FileIcon } from  "vue-feather-icons"
    export default {
        name: 'MainDashboard',
        components:{
            MainTopCard,
            // FileIcon
        },
        data(){
            return{
                model:{
                    activeCompany:{}
                },
                langs: ['en', 'fr'],
            }
        },
        computed:{
            ...mapState([
                'auth'
            ]),
            activeCompany:{
                get(){
                    return localStorage.orgId
                },
                set(val){
                    if (val != null) {
                        localStorage.orgId = val
                        this.model.activeCompany = val
                        this.$router.push('/preloader')
                    }
                }
            }
        },
        methods:{
            navigate(_route){
                this.makeToast('danger')
                console.log(_route)
                //this.$router.push(_route)
            },
            makeToast(variant = null) {
                this.$bvToast.toast('Toast body content', {
                    title: `Variant ${variant || 'default'}`,
                    variant: variant,
                    solid: true
                })
            },
            logout(){
                localStorage.clear();
                this.$router.push('/');
            }
        }
    }
</script>
