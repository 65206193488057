<template>
    <div class="content ht-100v pd-0">
      <!-- display types -->
      <span v-if="auth.screenView === 'list'">
        <div class="content-body pd-10">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item">
                                <router-link to="#">Employees</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Employees</li>
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">View All Employees</h4>
                    <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
<!--                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLoginDate | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
                </div>
                <div class="d-none d-md-block">
                    <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"  v-b-modal="'employee-modal'"><plus-icon class="wd-10 mg-r-5" /> Add Employee</button>
                    <button class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5"  @click="showUploadModal"><plus-icon class="wd-10 mg-r-5" /> Upload Employees</button>
                    <a class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" href="https://veezahrepo.s3.amazonaws.com/QooSquare_Employee_Template.xlsx" target="_blank"><DownloadIcon class="mg-r-5"/> Download Template</a>
                    <download-excel
                            :data="employees"
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <PrinterIcon class="mg-r-5"/>Export Employee List
                    </download-excel>

                </div>
            </div>
            <base-top-cards :carditems="cards"/>
            <hr>

            <base-table
                    :items="employees"
                    :fields="fields"
                    :show-title="true"
                    :is-busy="employee.employeeLoading"
                    class="mt-2"
                    filter-mode="balances"
            ></base-table>
        </div>
      </span>
      <span v-else-if="auth.screenView === 'panel'">
          <div class="content-body pd-0">
        <div class="mail-wrapper mail-wrapper-two">
            <div class="mail-group" style="overflow-y:scroll; height: 100%;">
                <div class="mail-group-header justify-content-between">
                    <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">Employees</h6>
                  <!--                    <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20"><refresh-ccw-icon title="Add new employee"/></h6>-->
                    <div class="dropdown tx-13">
                        <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">
                            <refresh-ccw-icon @click="loadEmployee" style="cursor: pointer;" title="Reload employee"/>
                            <plus-square-icon style="cursor: pointer;" title="Add new employee" @click="$refs['employee-modal'].show()"/>
                        </h6>
                      <!--                        <span class="tx-color-03">Sort:</span> <a href="" class="dropdown-link link-02">Date</a>-->
                    </div><!-- dropdown -->
                </div><!-- mail-group-header -->
                <div class="mail-group-body">

                    <label class="mail-group-label">Employees</label>
                    <bar-loader v-if="employee.employeeLoading" class="custom-class" color="#6d0606" loading="loading" style="width: 100%;" sizeUnit="px"></bar-loader>
                    <ul class="list-unstyled media-list mg-b-0">
                        <span v-for="employee in employees" v-bind:key="employee.employeeId">
                            <li @click="selectEmployee(employee.employeeId)" :class="activeClass(employee.employeeId,activeEmployee.employeeId)">
                                <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial(employee.employeeFirstName+' '+employee.employeeLastName)}}</span></div>
                                <div class="media-body mg-l-15">
                                    <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
                                        <span class="tx-12">{{employee.employeeFirstName+' '+employee.employeeMiddleName+' '+employee.employeeLastName | titleCase}}</span>
                                        <span class="tx-11">{{employee.employeeCreatedAt | formatDateTime}}</span>
                                    </div>
                                    <h6 class="tx-13">{{employee.employeeDetailRole | titleCase}}</h6>
                                  <!--                                    <p class="tx-12 tx-color-03 mg-b-0">{{employee.employeeRole | formatTextWithEllipsisThirty}}</p>-->
                                </div><!-- media-body -->
                            </li>
                        </span>
                    </ul>
                </div><!-- mail-group-body -->
            </div><!-- mail-group -->

            <div class="mail-content">
                <div class="mail-content-header">
                    <a href="" id="mailContentClose" class="link-02 d-none d-lg-block d-xl-none mg-r-20"><i data-feather="arrow-left"></i></a>
                    <b-icon-arrow-left v-if="this.$vssWidth < 1200" @click="navigate()" style="height: 20px; width: 50px; margin-left: -20px; margin-right: -20px; cursor: pointer;" class=" mg-t-8"/>
                    <div v-if="Object.keys(activeEmployee).length > 0" class="media">
                        <div class="avatar avatar-sm  mg-l-10"><span class="avatar-initial rounded-circle bg-secondary">{{initial(activeEmployee.employeeFirstName+' '+activeEmployee.employeeLastName)}}</span></div>
                        <div class="media-body mg-l-10">
                            <h6 class="mg-b-2 tx-13">{{activeEmployee.employeeFirstName+' '+activeEmployee.employeeMiddleName+' '+activeEmployee.employeeLastName | titleCase}}</h6>
                            <span class="d-block tx-11 tx-color-03">{{activeEmployee.employeeCreatedAt | formatDateTime}}</span>
                        </div><!-- media-body -->
                    </div><!-- media -->
                    <nav class="nav nav-icon-only mg-l-auto">
<!--                        <a href="" data-toggle="tooltip" title="Archive" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadEmployee" style="cursor: pointer;" title="Reload employee"/></a>-->
                      <!--                        <a href="" data-toggle="tooltip" title="Report Spam" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" title="Add new employee" @click="$refs['employee-modal'].show()"/></a>-->
                        <span class="nav-divider d-none d-sm-block"></span>
                        <a href="#" data-toggle="tooltip" title="Archive" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadEmployee" style="cursor: pointer;" title="Reload employee"/></a>
                        <a href="#" data-toggle="tooltip" title="Report Spam" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" title="Add new employee" @click="$refs['employee-modal'].show()"/></a>
                    </nav>
                </div><!-- mail-content-header -->
                <div class="mail-content-body"  style="overflow-y:scroll; height: 100%;">
                    <!-- TABS -->
                    <span v-if="Object.keys(activeEmployee).length > 0">
                      <br>
                      <b-tabs content-class="mt-3" style="z-index: 10;" justified>
                        <b-tab title="Contact Information" class="pd-12" active>
                          <br>
                          <h6>Personal Details</h6>
                          <hr>
                          <b-row>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">FIRST NAME</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeFirstName | titleCase }}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">MIDDLE NAME</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeMiddleName | titleCase }}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">LAST NAME</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeLastName | titleCase}}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">GENDER</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeGender | titleCase}}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">DATE OF BIRTH</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDob | titleCase}}</p>
                            </b-col>
                          </b-row>
                          <br>
                          <h6>Contact Details</h6>
                          <hr>
                          <b-row>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">MOBILE PHONE</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeePhoneNumber | titleCase }}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">EMAIL ADDRESS</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeEmail | titleCase }}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">DEPARTMENT</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDetailDepartment | titleCase}}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">STAFF ID</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDetailStaffId | titleCase}}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">NATIONALITY</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeNationality | titleCase}}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">JOB POSITION</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDetailRole | titleCase}}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">ID TYPE</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeIdType | titleCase}}</p>
                            </b-col>
                            <b-col sm="3" style="line-height: 11px;">
                              <p style="color: #8392a5; font-size: 11px;">ID NUMBER</p>
                              <p style="font-weight: lighter;">{{ activeEmployee.employeeIdNumber | titleCase}}</p>
                            </b-col>
                          </b-row>
                          <br>
                          <h6>Skills <plus-square-icon style="cursor: pointer;" title="Add new employee" @click="openModal('skill', {}, 'create')"/></h6>
                          <hr style="margin-bottom: -8px;">
                          <b-row>
                            <b-col md="3" sm="3" class="border-left border-primary" style="line-height: 12px; margin-left: 20px; margin-top: 15px;" v-for="skill in this.employee.skills" v-bind:key="skill.skillId">
                                <p style="font-weight: lighter; margin-bottom: 8px;">{{ skill.skillName | titleCase }} &nbsp;
                                  <b-icon-pencil style="cursor: pointer;" @click="openModal('skill',skill, 'edit')"/></p>
                                <p style="color: #8392a5; font-size: 11px;  margin-bottom: 8px;">{{ skill.skillCategoryName.toUpperCase() }}</p>
                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ skill.skillSkillEffectiveDate }}</p>
                            </b-col>
                          </b-row>
                          <br>
                          <br>
                          <h6>Education <plus-square-icon style="cursor: pointer;" title="Add new employee" @click="openModal('education', {}, 'create')"/></h6>
                          <hr style="margin-bottom: -8px;">
                          <b-row>
                            <b-col md="3" class="border-left border-primary" style="line-height: 12px; margin-left: 20px; margin-top: 15px;" v-for="education in this.employee.educations" v-bind:key="education.educationId">
                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ education.educationSchoolName | titleCase }} &nbsp;
                                  <b-icon-pencil style="cursor: pointer;" @click="openModal('education',education, 'edit')"/></p>
                                <p style="font-weight: lighter; color: #8392a5; font-size: 11px;  margin-bottom: 8px;">
                                  <span>{{education.educationDegree}}</span>
                                  <span v-if="education.educationDegree">{{', '+education.educationField}}</span>
                                  <span v-else>{{education.educationField}}</span>
                                  <span v-if="education.educationGrade || education.educationField">{{', '+education.educationGrade}}</span>
                                  <span v-else>{{education.educationGrade}}</span>
                                </p>
                                <p style="font-weight: lighter; color: #8392a5;  margin-bottom: 8px;">{{ education.educationStartYear }} - {{ education.educationEndYear }} </p>
                                <p style="font-weight: lighter; color: #8392a5;  margin-bottom: 8px;">Activities/Society: {{ education.educationActivities }}</p>
                                <p style="font-weight: lighter; color: #8392a5;  margin-bottom: 8px;">{{ education.educationDescription }} </p>
                            </b-col>
                          </b-row>
                          <br>
                          <br>
                          <h6>Employment History <plus-square-icon style="cursor: pointer;" title="Add new employee" @click="openModal('employment', {}, 'create')"/></h6>
                          <hr style="margin-bottom: -8px;">
                          <b-row>
                            <b-col md="3" class="border-left border-primary" style="line-height: 12px; margin-left: 20px; margin-top: 15px;" v-for="employment in this.employee.employments" v-bind:key="employment.employmentId">
                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ employment.employeeDetailRole | titleCase }} &nbsp;
                                  <b-icon-pencil v-if="!employment.employeeDetailEmployerId.match(/^[0-9]+$/)" style="cursor: pointer;" @click="openModal('employment',employment, 'edit')"/></p>
                                <p style="color: #8392a5; font-size: 11px;  margin-bottom: 8px;">{{ employment.employeeDetailEmployerName.toUpperCase() }}
                                  {{(employment.employeeDetailDepartment?' . ':'')+employment.employeeDetailDepartment.toUpperCase() }}
                                  {{(employment.employeeDetailType?'. ':'')+employment.employeeDetailType.toUpperCase() }}
                                </p>
                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ employment.employeeDetailStartDate.toLowerCase() }} - {{ employment.employeeDetailEndDate.toLowerCase() }}</p>
                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ employment.employeeDetailLocation }}</p>
                            </b-col>
                          </b-row>
                          <br><br><br><br><br><br><br><br>
                        </b-tab>
                        <b-tab class="pd-12" title="Projects And Task">
                          <br>
                          <project-task :row="activeEmployee"/>
                        </b-tab>
                      </b-tabs>
                    </span>
                  <!-- / -->
                    <div v-else class="pd-20 pd-lg-25 pd-xl-30">
                        <span style="text-align: center;">
                            <h1 style="margin-top: 25%;"><b-icon-people style="height: 100px; width: 150px;"/></h1>
                            <p><b-button  @click="$refs['employee-modal'].show()" variant="warning">Add new employee</b-button></p>
                        </span>
                    </div>
                </div><!-- mail-content-body -->
            </div><!-- mail-content -->

        </div><!-- mail-wrapper -->
    </div>
      </span>
      <span v-else>
        <div class="content-body pd-12">
          <b-row>
          <b-col md="2" sm="4" cols="6"  v-for="employee in employees" v-bind:key="employee.employeeId">
            <div class="card card-profile">
              <img src="@/assets/img/default-image-orange.png" class="card-img-top" alt="">
              <div class="card-body tx-13">
                <div>
                  <a href="">
                    <div class="avatar avatar-lg"><img :src="employee.employeeProfileImage" class="rounded-circle" alt=""></div>
                  </a>
                  <h5><a href="">{{ employee.employeeFirstName+' '+employee.employeeMiddleName+' '+employee.employeeLastName | titleCase | formatTextWithEllipsis}}</a></h5>
                  <p>{{ employee.employeeDetailRole | titleCase | formatTextWithEllipsis}}</p>
                  <button class="btn btn-block btn-white" @click="selectEmployee(employee.employeeId)">View</button>
                </div>
              </div>
            </div><!-- card -->
          </b-col>
          </b-row>
        </div>
      </span>
      <!-- / -->

      <b-modal id="employee-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="employee-modal">
        <div class="modal-body">
          <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
             @click="hide()">
            <span aria-hidden="true">&times;</span>
          </a>

          <div>
            <h4>Add new employee</h4>
            <b-form @submit.prevent="createEmployee" enctype="multipart/form-data">
              <b-form-group label="Staff Id">
                <b-input-group>
                  <b-input required placeholder="Staff Id" v-model="model.employeeStaffId"/>
                  <the-mask placeholder="BVN" required v-model="model.employeeBvn" maxlength="11" minlength="11" :mask="['###########', '###########']" class="form-control" />
                </b-input-group>
              </b-form-group>
              <b-form-group label="Name">
                <b-input-group>
                  <b-input type="text" placeholder="First Name" required v-model="model.employeeFirstName"/>
                  <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>
                  <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Contact">
                <b-input-group>
                  <vue-country-code @onSelect="onSelect" :preferredCountries="['vn', 'us', 'gb']">
                  </vue-country-code>
                  <b-input type="number" placeholder="Phone" required v-model="model.employeePhoneNumber"/>
                  <b-input type="email" placeholder="Email" required v-model="model.employeeEmail"/>
                </b-input-group>
              </b-form-group>
              <b-form-group label="ID Card">
                <b-input-group>
                  <b-select v-model="model.employeeIdType">
                    <b-select-option value="">Select ID Type</b-select-option>
                    <b-select-option value="NATIONAL ID">NATIONAL ID</b-select-option>
                    <b-select-option value="INTERNATIONAL PASSPORT">INTERNATIONAL PASSPORT</b-select-option>
                    <b-select-option value="VOTERS CARD">VOTERS CARD</b-select-option>
                    <b-select-option value="DRIVER LICENCE">DRIVER LICENCE</b-select-option>
                    <b-select-option value="OTHERS">OTHERS</b-select-option>
                  </b-select>
                  <b-input type="text" placeholder="ID NUMBER" required v-model="model.employeeIdNumber"/>
                </b-input-group>
              </b-form-group>
              <b-form-group label="ID expiry date">
                <b-input type="date" placeholder="EXPIRY DATE" required v-model="model.employeeIdExpiryDate"/>
              </b-form-group>
              <b-form-group label="Date of birth">
                <b-input-group>
                  <b-input type="date" placeholder="Date of birth" required v-model="model.employeeDob"/>
                  <b-select v-model="model.employeeGender">
                    <b-select-option value="">Select Gender</b-select-option>
                    <b-select-option value="MALE">MALE</b-select-option>
                    <b-select-option value="FEMALE">FEMALE</b-select-option>
                  </b-select>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Nationality">
                <country-select id="orgCountry" class="form-control" :countryName="true" :regionName="true" v-model="model.employeeNationality" :country="model.employeeNationality" topCountry="NG" />
              </b-form-group>
              <b-form-group label="Employment Details">
                <b-input-group>
                  <b-select v-model="model.employeeDepartment">
                    <b-select-option value="">Select Department</b-select-option>
                    <b-select-option v-for="dpt in department.departments" :value="dpt.employerDepartmentCode" v-bind:key="dpt.employerDepartmentCode">{{dpt.employerDepartmentName}}</b-select-option>
                  </b-select>
                  <b-select v-model="model.employeeGradeCode">
                    <b-select-option value="">Select Grade</b-select-option>
                    <b-select-option v-for="grd in grade.grades" :value="grd.employerGradeCode" v-bind:key="grd.employerGradeCode">{{grd.employerGradeName}}</b-select-option>
                  </b-select>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Employee Role">
                <b-input type="text" placeholder="Role" required v-model="model.employeeRole"/>
              </b-form-group>
              <base-button title="Submit" :loading="employee.employeeLoading"/>
            </b-form>
          </div>
        </div><!-- modal-body -->
      </b-modal>
      <upload-csv-modal
          :show="showModal"
          :header="myHeaders"
          :is-loading="employee.employeeLoading"
          read-type="employee/updateEmployees"
          upload-type="employee/createEmployee"
          :read-data="readData"
          :upload-data="uploadModel"
      />
      <create-skill-modal
          :row="itemRow"
          :show="showCreateSkillModal"
      />
      <create-education-modal
          :row="itemRow"
          :show="showCreateEducationModal"
      />
      <create-employment-modal
          :row="itemRow"
          :show="showCreateEmploymentModal"
      />
    </div>
</template>

<script>
    import BaseTopCards from "../../components/card/BaseTopCards";
    import BaseTable from "../../components/table/BaseTable";
    import ProjectTask from "@/views/project/ProjectTask";
    import CreateSkillModal from "@/components/modal/CreateSkillModal";
    import CreateEducationModal from "@/components/modal/CreateEducationModal";
    import CreateEmploymentModal from "@/components/modal/CreateEmploymentModal";
    import BaseButton from "../../components/button/BaseButton";
    // import ProjectTaskTable from "@/components/table/ProjectTaskTable";
    import { PrinterIcon, PlusIcon} from 'vue-feather-icons'
    import UploadCsvModal from "../../components/modal/UploadCsvModal";
    import {mapState} from 'vuex'
    import { PlusSquareIcon, RefreshCcwIcon } from 'vue-feather-icons'

    export default {
        name: 'Loan',
        data() {
            return {
                showModal:true,
                itemRow:{},
                showCreateSkillModal:true,
                showCreateEducationModal:true,
                showCreateEmploymentModal:true,
                activeEmployee:{},
                model:{
                    employeeStaffId: '',
                    employeeBvn: '',
                    employeeDob: '',
                    employeeEmail: '',
                    employeeFirstName: '',
                    employeeMiddleName: '',
                    employeeLastName: '',
                    employeeGender: '',
                    employeeIdType: '',
                    employeeIdNumber: '',
                    employeeIdExpiryDate: '',
                    employeeNationality: '',
                    employeePhoneNumber: '',
                    employeeCountryCode: '',
                    employeeDepartment: '',
                    employeeGradeCode: '',
                    employeeManagerId: '',
                    employerId: '',
                    employeeRole: '',
                    requestType: '',
                    employeeMaker: ''
                },
                uploadModel:{
                    requestType: '',
                    employeeMaker: '',
                    employerId: '',
                    data: []
                },
                file: {
                    accountnumber: '',
                    file: '',
                    amount: '',
                    status: false,
                    bank: {},
                    narration: '',
                    uploadedFiles: [],
                    uploadError: null,
                    currentStatus: null,
                    uploadFieldName: 'photos',
                },
                fields: [
                    // { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'employeeDetailStaffId', label: 'Staff Id', sortable: true, sortDirection: 'desc' },
                    { key: 'employeeIdNumber', label: 'Employee Id', sortable: true, class: 'text-left' },
                    { key: 'employeeFirstName', label: 'First Name', sortable: true, class: 'text-left' },
                    { key: 'employeeLastName', label: 'Last Name', sortable: true, class: 'text-left' },
                    { key: 'employeePhoneNumber', label: 'Phone', sortable: true, class: 'text-left' },
                    { key: 'employeeDetailGradeCode', label: 'Grade Code', sortable: true, class: 'text-left' },
                    { key: 'employeeDetailDepartment', label: 'Department', sortable: true, class: 'text-left' },
                    { key: 'employeeDetailRole', label: 'Role', sortable: true, class: 'text-left' },
                    // { key: 'employeeDetailDate', label: 'Date of Reg.', sortable: true, sortDirection: 'desc', class: 'text-left' },
                    { key: 'employeeDetailStatus', label: 'Status', sortable: true, class: 'text-left' },
                    { key: 'actionsEmployee', label: 'Action', sortable: true, class: 'text-center' }
                ],
                myHeaders:[
                    'employeeStaffId', 'employeeBvn', 'employeeDob', 'employeeEmail', 'employeeFirstName',  'employeeMiddleName',
                    'employeeLastName', 'employeeGender', 'employeeIdType', 'employeeIdNumber', 'employeeIdIssueDate',
                    'employeeNationality','employeePhoneNumber', 'employeeCountryCode', 'employeeDepartment', 'employeeGradeCode',
                    'employeeRole\r'
                ],
                readData:{
                    readAll:'NO',
                    employerId: ''
                }
            }
        },
        components: {
          BaseButton,
          BaseTable,
          BaseTopCards,
          PrinterIcon,
          PlusSquareIcon,
          RefreshCcwIcon,
          UploadCsvModal,
          CreateSkillModal,
          CreateEducationModal,
          CreateEmploymentModal,
          ProjectTask,
          // ProjectTaskTable,
          PlusIcon,
        },
        computed: {
            cards:{
                get(){
                    return [
                        {
                            id: 0,
                            name: 'Total Employees',
                            number: this.employees.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
                            color: 'bg-primary'
                        },
                        {
                            id: 1,
                            name: 'Active Employees',
                            number: this.employees.filter(it => it.employeeDetailStatus.toUpperCase() === 'ACTIVE').length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
                            color: 'bg-orange'
                        },
                        {
                            id: 2,
                            name: 'Pending Employees',
                            number: this.employees.filter(it => it.employeeDetailStatus.toUpperCase() === 'PENDING').length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
                            color: 'bg-success'
                        },
                        {
                            id: 3,
                            name: 'Suspended Employees',
                            number: this.employees.filter(it => it.employeeDetailStatus.toUpperCase() === 'SUSPENDED').length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
                            color: 'bg-black-8'
                        }
                    ]
                },
                set(){

                }
            },
            grades(){
                return  this.grade.grades
            },
            employees(){
                return  this.employee.employees?this.employee.employees:[]
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            ...mapState([
                'employee',
                'department',
                'grade',
                'auth'
            ])
        },
        methods: {
            openModal(_module='skill',_row ={}, _type = 'create'){
              if (_type === 'edit') {
                _row.type = 'edit'
                this.itemRow = _row
              }else{
                this.itemRow = this.activeEmployee
              }
              if (_module === 'skill') {
                this.showCreateSkillModal = !this.showCreateSkillModal
              }else if (_module === 'education'){
                this.showCreateEducationModal = !this.showCreateEducationModal
              }else if (_module === 'employment'){
                this.showCreateEmploymentModal = !this.showCreateEmploymentModal
              }
            },
            initial(_title){
              let titleArray = _title.split(' ')
              let firstInitial = titleArray[0].substring(0,1)
              let secondInitial = ""
              if (titleArray[1] != null)
                secondInitial = titleArray[1].substring(0,1)
              return firstInitial+secondInitial
            },
            activeClass(_id1,_id2){
              if(_id1 === _id2) return 'media'
              else return 'media unread'
            },
            navigate(){
              this.toggleBodyClass('removeClass','mail-content-show')
            },
            showUploadModal(){
                this.readData.employerId = localStorage.orgId
                this.uploadModel.requestType = 'bulkUpload'
                this.uploadModel.employeeMaker = `${this.auth.userInfo.firstName} ${this.auth.userInfo.lastName}`
                this.uploadModel.employerId = localStorage.orgId
                this.showModal = !this.showModal
            },
            toggleBodyClass(addRemoveClass, className) {
              const el = document.body;

              if (addRemoveClass === 'addClass') {
                el.classList.add(className);
              } else {
                el.classList.remove(className);
              }
            },
            selectEmployee(_id){
              this.$store.commit('auth/updateScreenView','panel')
              this.$store.dispatch('employee/readEducations',{
                readAll:'NO',
                educationEmployeeId: _id
              })
              this.$store.dispatch('employee/readSkills',{
                readAll:'NO',
                skillEmployeeId: _id
              })
              this.$store.dispatch('employee/readEmployments',{
                readAll:'NO',
                employeeDetailEmployeeId: _id
              })
              console.log(_id)
              this.toggleBodyClass('addClass','mail-content-show')
              this.toggleBodyClass('addClass','mail-content-show')
              this.activeEmployee = this.employees.filter(it=>it.employeeId === _id)[0]
              //console.log(this.activeEmployee)
            },
            async createEmployee(){
                this.model.employeeMaker = `${this.auth.userInfo.firstName} ${this.auth.userInfo.lastName}`;
                this.model.employerDepartmentEmployerId = localStorage.orgId;
                this.model.employerId = localStorage.orgId;
                this.model.requestType = 'create'
                await this.$store.dispatch("employee/createEmployee", this.model, {root: false});
                this.loadEmployee();
                this.hide();
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });
            },

            loadEmployee(){
              this.$store.commit('employee/updateEmployeeLoading',true)
                this.$store.dispatch("employee/updateEmployees", {
                    readAll:'NO',
                    employerId: localStorage.orgId
                }, {root: false}).then()
            },
            loadGrade(){
                this.$store.dispatch("grade/updateGrades", {
                    readAll:'NO',
                    employerId: localStorage.orgId
                }, {root: false}).then()
            },
            loadDepartment(){
                this.$store.dispatch("department/updateDepartments", {
                    readAll:'NO',
                    employerId: localStorage.orgId
                }, {root: false}).then()
            },
            hide() {
                this.$refs['employee-modal'].hide()
                this.$refs['employee-upload-modal'].hide()
            },
            onSelect({name, iso2, dialCode}) {
                this.model.employeeCountryCode =  dialCode
                console.log(name, iso2, dialCode);
            },
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {
            this.$store.dispatch("employee/updateEmployees", {
                readAll:'NO',
                employerId: localStorage.orgId
            }, {root: false}).then()
            this.loadDepartment()
            this.loadGrade()
        }
    }


</script>

<style scoped>
    .dropbox {
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: lightcyan;
        color: dimgray;
        padding: 10px 10px;
        min-height: 200px; /* minimum height */
        position: relative;
        cursor: pointer;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: lightblue; /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
    }
</style>
