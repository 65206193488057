import PayrollService from "../../service/PayrollService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    calenders:[],
    employees:[],
    calenderLoading: false,
    employeeLoading: false,
    response:{}

}

export const mutations = {
    updateCalenders: (state, payload) =>{
        state.calenders = payload
    },
    updateCalenderLoading: (state, payload) =>{
        state.calenderLoading = payload
    },
    updateEmployees: (state, payload) =>{
        state.employees = payload
    },
    updateEmployeeLoading: (state, payload) =>{
        state.employeeLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
}

export const actions = {
    updateCalenders: ({ commit, state }, payload)=>{
        if(state.calenders.length < 1)
            commit("updateCalenderLoading", true)
        return PayrollService.callReadCalenderApi(payload)
            .then(response => {
                commit("updateCalenderLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateCalenderLoading", false)
                    let responseData = response.data;
                    commit("updateCalenders", responseData.data)
                }

            })
            .catch(error => {
                commit("updateCalenderLoading", false)
                alert(error)
                // throw error;
            });
    },
    createCalender: ({ commit }, payload)=>{
        commit("updateCalenderLoading", true)
        return PayrollService.callCreateCalenderApi(payload)
            .then(response => {
                commit("updateCalenderLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateCalenderLoading", false)
                alert(error)
                // throw error;
            });
    },
    updateCalenderEmployees: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return PayrollService.callReadCalenderEmployeeApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateEmployeeLoading", false)
                    let responseData = response.data;
                    commit("updateEmployees", responseData.data)
                }

            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
}