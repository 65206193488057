import Client from "@/views/client/Client";
import SLA from "@/views/sla/SLA";
import SlaSheet from "@/views/sla/SlaSheet";
import ResourceSheet from "@/views/resource/ResourceSheet";

export default [
    {
        path: '/clients',
        name: 'Client',
        meta: {layout: 'on-boarding', authRequired:true},
        component: Client,
    },
    {
        path: '/slas',
        name: 'SLA',
        meta: {layout: 'on-boarding', authRequired:true},
        component: SLA,
    },
    {
        path: '/sheet',
        name: 'SlaSheet',
        meta: {layout: 'on-boarding', authRequired:true},
        component: SlaSheet,
    },
    {
        path: '/resource-sheet',
        name: 'ResourceSheet',
        meta: {layout: 'on-boarding', authRequired:true},
        component: ResourceSheet
    },

];