<template>
    <div class="content ht-100v pd-0">
        <div class="content-body pd-10">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item">
                                <router-link to="#">Grades</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Grades</li>
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">View All Grades</h4>
                    <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
                    <!--                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLoginDate | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
                </div>
                <div class="d-none d-md-block">
                    <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"  v-b-modal="'grade-modal'"><plus-icon class="wd-10 mg-r-5" /> Add Grade</button>
                    <download-excel
                            :data="grades"
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
                    </download-excel>
                    <download-excel
                            type="csv"
                            :data="grades"
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
                    </download-excel>

                </div>
            </div>
            <base-top-cards :carditems="cards"/>
            <hr>

            <base-table
                    :items="grades"
                    :fields="fields"
                    :show-title="true"
                    :is-busy="grade.gradeLoading"
                    class="mt-2"
                    filter-mode="balances"
            ></base-table>
        </div>
        <b-modal id="grade-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="grade-modal">
            <div class="modal-body">
                <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
                   @click="hide()">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div>
                    <h4>Add new grade</h4>
                    <b-form @submit.prevent="createGrade" enctype="multipart/form-data">
                        <b-form-group label="Code">
                            <b-input required v-model="model.employerGradeCode"/>
                        </b-form-group>
                        <b-form-group label="Name">
                            <b-input required v-model="model.employerGradeName"/>
                        </b-form-group>
                        <b-form-group label="Grade Type">
                            <b-select required v-model="model.employerGradeType">
                                <b-select-option  value="JUNIOR">JUNIOR</b-select-option>
                                <b-select-option  value="MIDDLE LEVEL OFFICER">MIDDLE LEVEL OFFICER</b-select-option>
                                <b-select-option  value="SENIOR">SENIOR</b-select-option>
                                <b-select-option  value="EXECUTIVE">EXECUTIVE</b-select-option>
                            </b-select>
                        </b-form-group>
                        <b-form-group label="Salary Day">
                            <b-select required v-model="model.employerGradeSalaryDay">
                                <b-select-option v-for="day in 28" :value="day" v-bind:key="day">{{day}}</b-select-option>
                            </b-select>
                        </b-form-group>
                        <b-form-group label="Period">
                            <b-select required v-model="model.employerGradeSalaryPeriod">
                                <b-select-option  value="WEEKLY">WEEKLY</b-select-option>
                                <b-select-option  value="BIWEEKLY">BI-WEEKLY</b-select-option>
                                <b-select-option  value="MONTHLY">MONTHLY</b-select-option>
                            </b-select>
                        </b-form-group>
                        <base-button title="Submit" :loading="grade.gradeLoading"/>
                    </b-form>
                </div>
            </div><!-- modal-body -->
        </b-modal>
    </div>
</template>

<script>
    import BaseTopCards from "../../components/card/BaseTopCards";
    import BaseTable from "../../components/table/BaseTable";
    import BaseButton from "../../components/button/BaseButton";
    import { PrinterIcon, PlusIcon } from 'vue-feather-icons'
    import {mapState} from 'vuex'

    export default {
        name: 'Loan',
        data() {
            return {
                model:{
                    employerGradeCode: '',
                    employerGradeName: '',
                    employerGradeEmployerId: '',
                    employerGradeMaker: '',
                    employerGradeSalaryDay: '',
                    employerGradeSalaryPeriod: 'MONTHLY',
                    employerGradeType: 'JUNIOR'
                },
                fields: [
                    { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'employerGradeCode', label: 'Grade Code', sortable: true, sortDirection: 'desc' },
                    { key: 'employerGradeName', label: 'Grade Name', sortable: true, class: 'text-left' },
                    { key: 'employerGradeType', label: 'Grade Type', sortable: true, class: 'text-left' },
                    { key: 'employerGradeSalaryPeriod', label: 'Salary Period', sortable: true, class: 'text-left' },
                    { key: 'employerGradeSalaryDay', label: 'Salary Day', sortable: true, class: 'text-left' },
                    { key: 'employerGradeStatus', label: 'Status', sortable: true, class: 'text-left' },
                    { key: 'actionsGrade', label: 'Action', sortable: true, class: 'text-center' }
                ],
            }
        },
        components: {
            PlusIcon,
            BaseTable,
            BaseButton,
            BaseTopCards,
            PrinterIcon
        },
        computed: {
            cards:{
                get(){
                    return [
                        {
                            id: 0,
                            name: 'Total Grades',
                            number: this.grades.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
                            color: 'bg-primary'
                        },
                        {
                            id: 1,
                            name: 'Active Grades',
                            number: this.grades.filter(it => it.employerGradeStatus.toUpperCase() === 'ACTIVE').length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
                            color: 'bg-orange'
                        },
                        {
                            id: 2,
                            name: 'Pending Grades',
                            number: this.grades.filter(it => it.employerGradeStatus.toUpperCase() === 'PENDING').length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
                            color: 'bg-success'
                        },
                        {
                            id: 3,
                            name: 'Suspended Grades',
                            number: this.grades.filter(it => it.employerGradeStatus.toUpperCase() === 'SUSPENDED').length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
                            color: 'bg-black-8'
                        }
                    ]
                },
                set(){

                }
            },
            grades(){
                return  this.grade.grades
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            ...mapState([
                'grade',
                'auth'
            ])
        },
        methods: {
            async createGrade(){
                this.model.employerGradeMaker = `${this.auth.userInfo.firstName} ${this.auth.userInfo.lastName}`
                this.model.employerGradeEmployerId = localStorage.orgId
                await this.$store.dispatch("grade/createGrade", this.model, {root: false})
                this.loadGrade();
                this.hide();
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            loadGrade(){
                this.$store.dispatch("grade/updateGrades", {
                    readAll:'NO',
                    employerId: localStorage.orgId
                }, {root: false}).then()
            },
            hide() {
                this.$refs['grade-modal'].hide()
            },
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {
            this.$store.dispatch("grade/updateGrades", {
                readAll:'NO',
                employerId: localStorage.orgId
            }, {root: false}).then()
        }
    }


</script>

<style scoped>

</style>
