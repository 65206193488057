<template>
  <div class="content ht-100v pd-0">

    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">Attendance Sheets</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">All Attendance Sheets</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">View All Attendance Sheets</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{ auth.userInfo.firstName }} {{ auth.userInfo.lastName }}</p>
          <!--                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLoginDate | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
        </div>
<!--        <div class="d-none d-md-block">-->
<!--          <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"  v-b-modal="'sla-sheet-modal'"><plus-icon class="wd-10 mg-r-5" /> Add SLA Sheet</button>-->
<!--        </div>-->
      </div>
      <base-top-cards :carditems="cards"/>
      <hr>

      <base-table
          :items="slaSheet"
          :fields="fields"
          :show-title="true"
          :is-busy="sla.slaLoading"
          class="mt-2"
          filter-mode="balances"
      ></base-table>
    </div>

    <!--    <span v-else-if="auth.screenView === 'panel'">-->
    <!--          <div class="content-body pd-0">-->
    <!--        <div class="mail-wrapper mail-wrapper-two">-->
    <!--            <div class="mail-group" style="overflow-y:scroll; height: 100%;">-->
    <!--                <div class="mail-group-header justify-content-between">-->
    <!--                    <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">Clients</h6>-->
    <!--                  &lt;!&ndash;                    <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20"><refresh-ccw-icon title="Add new employee"/></h6>&ndash;&gt;-->
    <!--                    <div class="dropdown tx-13">-->
    <!--                        <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">-->
    <!--                            <refresh-ccw-icon @click="loadEmployee" style="cursor: pointer;" title="Reload employee"/>-->
    <!--                            <plus-square-icon style="cursor: pointer;" title="Add new client" @click="$refs['client-modal'].show()"/>-->
    <!--                        </h6>-->
    <!--                      &lt;!&ndash;                        <span class="tx-color-03">Sort:</span> <a href="" class="dropdown-link link-02">Date</a>&ndash;&gt;-->
    <!--                    </div>&lt;!&ndash; dropdown &ndash;&gt;-->
    <!--                </div>&lt;!&ndash; mail-group-header &ndash;&gt;-->
    <!--                <div class="mail-group-body">-->

    <!--                    <label class="mail-group-label">Clients</label>-->
    <!--                    <bar-loader v-if="client.clientLoading" class="custom-class" color="#6d0606" loading="loading" style="width: 100%;" sizeUnit="px"></bar-loader>-->
    <!--                    <ul class="list-unstyled media-list mg-b-0">-->
    <!--                        <span v-for="client in clients" v-bind:key="client.clientCode">-->
    <!--                            <li @click="selectEmployee(employee.employeeId)" :class="activeClass(employee.employeeId,activeEmployee.employeeId)">-->
    <!--                                <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial(employee.employeeFirstName+' '+employee.employeeLastName)}}</span></div>-->
    <!--                                <div class="media-body mg-l-15">-->
    <!--                                    <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">-->
    <!--                                        <span class="tx-12">{{employee.employeeFirstName+' '+employee.employeeMiddleName+' '+employee.employeeLastName | titleCase}}</span>-->
    <!--                                        <span class="tx-11">{{employee.employeeCreatedAt | formatDateTime}}</span>-->
    <!--                                    </div>-->
    <!--                                    <h6 class="tx-13">{{employee.employeeDetailRole | titleCase}}</h6>-->
    <!--                                  &lt;!&ndash;                                    <p class="tx-12 tx-color-03 mg-b-0">{{employee.employeeRole | formatTextWithEllipsisThirty}}</p>&ndash;&gt;-->
    <!--                                </div>&lt;!&ndash; media-body &ndash;&gt;-->
    <!--                            </li>-->
    <!--                        </span>-->
    <!--                    </ul>-->
    <!--                </div>&lt;!&ndash; mail-group-body &ndash;&gt;-->
    <!--            </div>&lt;!&ndash; mail-group &ndash;&gt;-->

    <!--            <div class="mail-content">-->
    <!--                <div class="mail-content-header">-->
    <!--                    <a href="" id="mailContentClose" class="link-02 d-none d-lg-block d-xl-none mg-r-20"><i data-feather="arrow-left"></i></a>-->
    <!--                    <b-icon-arrow-left v-if="this.$vssWidth < 1200" @click="navigate()" style="height: 20px; width: 50px; margin-left: -20px; margin-right: -20px; cursor: pointer;" class=" mg-t-8"/>-->
    <!--                    <div v-if="Object.keys(activeEmployee).length > 0" class="media">-->
    <!--                        <div class="avatar avatar-sm  mg-l-10"><span class="avatar-initial rounded-circle bg-secondary">{{initial(activeEmployee.employeeFirstName+' '+activeEmployee.employeeLastName)}}</span></div>-->
    <!--                        <div class="media-body mg-l-10">-->
    <!--                            <h6 class="mg-b-2 tx-13">{{activeEmployee.employeeFirstName+' '+activeEmployee.employeeMiddleName+' '+activeEmployee.employeeLastName | titleCase}}</h6>-->
    <!--                            <span class="d-block tx-11 tx-color-03">{{activeEmployee.employeeCreatedAt | formatDateTime}}</span>-->
    <!--                        </div>&lt;!&ndash; media-body &ndash;&gt;-->
    <!--                    </div>&lt;!&ndash; media &ndash;&gt;-->
    <!--                    <nav class="nav nav-icon-only mg-l-auto">-->
    <!--&lt;!&ndash;                        <a href="" data-toggle="tooltip" title="Archive" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadEmployee" style="cursor: pointer;" title="Reload employee"/></a>&ndash;&gt;-->
    <!--                      &lt;!&ndash;                        <a href="" data-toggle="tooltip" title="Report Spam" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" title="Add new employee" @click="$refs['employee-modal'].show()"/></a>&ndash;&gt;-->
    <!--                        <span class="nav-divider d-none d-sm-block"></span>-->
    <!--                        <a href="#" data-toggle="tooltip" title="Archive" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadEmployee" style="cursor: pointer;" title="Reload employee"/></a>-->
    <!--                        <a href="#" data-toggle="tooltip" title="Report Spam" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" title="Add new employee" @click="$refs['employee-modal'].show()"/></a>-->
    <!--                    </nav>-->
    <!--                </div>&lt;!&ndash; mail-content-header &ndash;&gt;-->
    <!--                <div class="mail-content-body"  style="overflow-y:scroll; height: 100%;">-->
    <!--                    &lt;!&ndash; TABS &ndash;&gt;-->
    <!--                    <span v-if="Object.keys(activeEmployee).length > 0">-->
    <!--                      <br>-->
    <!--                      <b-tabs content-class="mt-3" style="z-index: 10;" justified>-->
    <!--                        <b-tab title="Contact Information" class="pd-12" active>-->
    <!--                          <br>-->
    <!--                          <h6>Personal Details</h6>-->
    <!--                          <hr>-->
    <!--                          <b-row>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">FIRST NAME</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeFirstName | titleCase }}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">MIDDLE NAME</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeMiddleName | titleCase }}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">LAST NAME</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeLastName | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">GENDER</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeGender | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">DATE OF BIRTH</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDob | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                          </b-row>-->
    <!--                          <br>-->
    <!--                          <h6>Contact Details</h6>-->
    <!--                          <hr>-->
    <!--                          <b-row>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">MOBILE PHONE</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeePhoneNumber | titleCase }}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">EMAIL ADDRESS</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeEmail | titleCase }}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">DEPARTMENT</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDetailDepartment | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">STAFF ID</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDetailStaffId | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">NATIONALITY</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeNationality | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">JOB POSITION</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDetailRole | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">ID TYPE</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeIdType | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">ID NUMBER</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeIdNumber | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                          </b-row>-->
    <!--                          <br>-->
    <!--                          <h6>Skills <plus-square-icon style="cursor: pointer;" title="Add new employee" @click="openModal('skill', {}, 'create')"/></h6>-->
    <!--                          <hr style="margin-bottom: -8px;">-->
    <!--                          <b-row>-->
    <!--                            <b-col md="3" sm="3" class="border-left border-primary" style="line-height: 12px; margin-left: 20px; margin-top: 15px;" v-for="skill in this.employee.skills" v-bind:key="skill.skillId">-->
    <!--                                <p style="font-weight: lighter; margin-bottom: 8px;">{{ skill.skillName | titleCase }} &nbsp;-->
    <!--                                  <b-icon-pencil style="cursor: pointer;" @click="openModal('skill',skill, 'edit')"/></p>-->
    <!--                                <p style="color: #8392a5; font-size: 11px;  margin-bottom: 8px;">{{ skill.skillCategoryName.toUpperCase() }}</p>-->
    <!--                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ skill.skillSkillEffectiveDate }}</p>-->
    <!--                            </b-col>-->
    <!--                          </b-row>-->
    <!--                          <br>-->
    <!--                          <br>-->
    <!--                          <h6>Education <plus-square-icon style="cursor: pointer;" title="Add new employee" @click="openModal('education', {}, 'create')"/></h6>-->
    <!--                          <hr style="margin-bottom: -8px;">-->
    <!--                          <b-row>-->
    <!--                            <b-col md="3" class="border-left border-primary" style="line-height: 12px; margin-left: 20px; margin-top: 15px;" v-for="education in this.employee.educations" v-bind:key="education.educationId">-->
    <!--                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ education.educationSchoolName | titleCase }} &nbsp;-->
    <!--                                  <b-icon-pencil style="cursor: pointer;" @click="openModal('education',education, 'edit')"/></p>-->
    <!--                                <p style="font-weight: lighter; color: #8392a5; font-size: 11px;  margin-bottom: 8px;">-->
    <!--                                  <span>{{education.educationDegree}}</span>-->
    <!--                                  <span v-if="education.educationDegree">{{', '+education.educationField}}</span>-->
    <!--                                  <span v-else>{{education.educationField}}</span>-->
    <!--                                  <span v-if="education.educationGrade || education.educationField">{{', '+education.educationGrade}}</span>-->
    <!--                                  <span v-else>{{education.educationGrade}}</span>-->
    <!--                                </p>-->
    <!--                                <p style="font-weight: lighter; color: #8392a5;  margin-bottom: 8px;">{{ education.educationStartYear }} - {{ education.educationEndYear }} </p>-->
    <!--                                <p style="font-weight: lighter; color: #8392a5;  margin-bottom: 8px;">Activities/Society: {{ education.educationActivities }}</p>-->
    <!--                                <p style="font-weight: lighter; color: #8392a5;  margin-bottom: 8px;">{{ education.educationDescription }} </p>-->
    <!--                            </b-col>-->
    <!--                          </b-row>-->
    <!--                          <br>-->
    <!--                          <br>-->
    <!--                          <h6>Employment History <plus-square-icon style="cursor: pointer;" title="Add new employee" @click="openModal('employment', {}, 'create')"/></h6>-->
    <!--                          <hr style="margin-bottom: -8px;">-->
    <!--                          <b-row>-->
    <!--                            <b-col md="3" class="border-left border-primary" style="line-height: 12px; margin-left: 20px; margin-top: 15px;" v-for="employment in this.employee.employments" v-bind:key="employment.employmentId">-->
    <!--                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ employment.employeeDetailRole | titleCase }} &nbsp;-->
    <!--                                  <b-icon-pencil v-if="!employment.employeeDetailEmployerId.match(/^[0-9]+$/)" style="cursor: pointer;" @click="openModal('employment',employment, 'edit')"/></p>-->
    <!--                                <p style="color: #8392a5; font-size: 11px;  margin-bottom: 8px;">{{ employment.employeeDetailEmployerName.toUpperCase() }}-->
    <!--                                  {{(employment.employeeDetailDepartment?' . ':'')+employment.employeeDetailDepartment.toUpperCase() }}-->
    <!--                                  {{(employment.employeeDetailType?'. ':'')+employment.employeeDetailType.toUpperCase() }}-->
    <!--                                </p>-->
    <!--                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ employment.employeeDetailStartDate.toLowerCase() }} - {{ employment.employeeDetailEndDate.toLowerCase() }}</p>-->
    <!--                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ employment.employeeDetailLocation }}</p>-->
    <!--                            </b-col>-->
    <!--                          </b-row>-->
    <!--                          <br><br><br><br><br><br><br><br>-->
    <!--                        </b-tab>-->
    <!--                        <b-tab class="pd-12" title="Projects And Task">-->
    <!--                          <br>-->
    <!--                          <project-task :row="activeEmployee"/>-->
    <!--                        </b-tab>-->
    <!--                      </b-tabs>-->
    <!--                    </span>-->
    <!--                  &lt;!&ndash; / &ndash;&gt;-->
    <!--                    <div v-else class="pd-20 pd-lg-25 pd-xl-30">-->
    <!--                        <span style="text-align: center;">-->
    <!--                            <h1 style="margin-top: 25%;"><b-icon-people style="height: 100px; width: 150px;"/></h1>-->
    <!--                            <p><b-button  @click="$refs['employee-modal'].show()" variant="warning">Add new employee</b-button></p>-->
    <!--                        </span>-->
    <!--                    </div>-->
    <!--                </div>&lt;!&ndash; mail-content-body &ndash;&gt;-->
    <!--            </div>&lt;!&ndash; mail-content &ndash;&gt;-->

    <!--        </div>&lt;!&ndash; mail-wrapper &ndash;&gt;-->
    <!--    </div>-->
    <!--      </span>-->
    <!--    <span v-else>-->
    <!--        <div class="content-body pd-12">-->
    <!--          <b-row>-->
    <!--          <b-col md="2" sm="4" cols="6"  v-for="employee in employees" v-bind:key="employee.employeeId">-->
    <!--            <div class="card card-profile">-->
    <!--              <img src="@/assets/img/default-image-orange.png" class="card-img-top" alt="">-->
    <!--              <div class="card-body tx-13">-->
    <!--                <div>-->
    <!--                  <a href="">-->
    <!--                    <div class="avatar avatar-lg"><img :src="employee.employeeProfileImage" class="rounded-circle" alt=""></div>-->
    <!--                  </a>-->
    <!--                  <h5><a href="">{{ employee.employeeFirstName+' '+employee.employeeMiddleName+' '+employee.employeeLastName | titleCase | formatTextWithEllipsis}}</a></h5>-->
    <!--                  <p>{{ employee.employeeDetailRole | titleCase | formatTextWithEllipsis}}</p>-->
    <!--                  <button class="btn btn-block btn-white" @click="selectEmployee(employee.employeeId)">View</button>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>&lt;!&ndash; card &ndash;&gt;-->
    <!--          </b-col>-->
    <!--          </b-row>-->
    <!--        </div>-->
    <!--      </span>-->

  </div>
</template>

<script>
import {mapState} from "vuex";
import BaseTopCards from "@/components/card/BaseTopCards";
import BaseTable from "@/components/table/BaseTable";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import SLARequest from "@/model/request/SLARequest";

export default {
  name: "SlaSheet",
  components: { BaseTopCards, BaseTable },
  data(){
    return{
      fields: [
        // { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'sheetId', label: 'Sheet Id', sortable: true, sortDirection: 'desc' },
        { key: 'sheetCode', label: 'Sheet Code', sortable: true, sortDirection: 'desc' },
        { key: 'sheetClientCode', label: 'Client Code', sortable: true, class: 'text-left' },
        { key: 'sheetResourceCode', label: 'Resource Code', sortable: true, class: 'text-left' },

        { key: 'sheetCreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
        { key: 'sheetUpdatedAt', label: 'Updated At', sortable: true, class: 'text-left' },
        { key: 'sheetStatus', label: 'Day', sortable: true, class: 'text-left' },
        { key: 'sheetSlaCode', label: 'Start Date', sortable: true, class: 'text-left' },
        // { key: 'employeeDetailDate', label: 'Date of Reg.', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'actionsSheet', label: 'Action', sortable: true, class: 'text-center' }
      ],
      readModel: SLARequest.readSheetBySla
    }
  },
  computed:{
    cards:{
      get(){
        return [
          {
            id: 0,
            name: 'Total',
            number: this.slaSheet.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
            color: 'bg-primary'
          },
          {
            id: 1,
            name: 'Pending',
            number: this.slaSheet.filter(it => it.sheetStatus.toUpperCase() === 'PENDING').length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
            color: 'bg-orange'
          },
          {
            id: 2,
            name: 'Present',
            // number: this.employees.filter(it => it.employeeDetailStatus.toUpperCase() === 'PENDING').length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
            number: this.slaSheet.filter(it => it.sheetStatus.toUpperCase() === 'PRESENT').length,
            color: 'bg-success'
          },
          {
            id: 3,
            name: 'Absent',
            // number: this.employees.filter(it => it.employeeDetailStatus.toUpperCase() === 'SUSPENDED').length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
            number: this.slaSheet.filter(it => it.sheetStatus.toUpperCase() === 'ABSENT').length,
            color: 'bg-black-8'
          }
        ]
      },
      set(){

      }
    },
    slaSheet(){
      return  this.sla.slaSheet?this.sla.slaSheet:[]
    },
    ...mapState([
      'employee','sla', 'auth', 'client']),
    slaCode(){
      return StoreUtils.rootGetters("sla/getSlaCode")
    }
  },
  methods:{
    createSheet(){}
  },
  mounted(){
    this.readModel.sheetSlaCode = this.slaCode;
    console.log(this.slaCode);
    StoreUtils.dispatch("sla/readSheetBySlaCode", this.readModel )
    // console.log(this.slaCode);
  }

}
</script>

<style scoped>

</style>