<template>
    <b-container fluid>
        <!-- User Interface controls variablePayments-->
        <b-row>
            <b-col md="4" class="my-1 ml-0">
              <button v-if="filterMode === 'variablePayments'" class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5"  @click="runVariablePayment"><play-icon class="wd-10 mg-r-5" /> Run Variable Payment</button>
            </b-col>
            <b-col v-if="filterMode !== 'mini'" sm="4">
            </b-col>
            <b-col :md="filterMode === 'mini'?'8':'4'" class="my-1">
                <b-form-group
                        :label="showTitle?'Filter':''"
                        label-cols-sm="2"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type to Search"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <br>

        <!-- Main table element -->
        <b-table
            small
            show-empty
            responsive
            style="font-size: 12px;"
            striped
            :items="items"
            :busy="isBusy"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :tbody-tr-class="rowClass"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered">

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <!-- TODO AMOUNT FORMATTING -->
              <template v-slot:cell(bookBalance)="row">
                  {{ row.item.bookBalance | formatAmount }}
              </template>
              <template v-slot:cell(expenseCost)="row">
                  {{ row.item.expenseCost | formatAmount }}
              </template>
              <template v-slot:cell(earnings)="row">
                  {{ row.item.earnings | formatAmount }}
              </template>
              <template v-slot:cell(deductions)="row">
                  {{ row.item.deductions | formatAmount }}
              </template>
              <template v-slot:cell(payslipDetailAmount)="row">
                  {{ row.item.payslipDetailAmount | formatAmount }}
              </template>
              <template v-slot:cell(employeePayslipAmount)="row">
                  {{ row.item.employeePayslipAmount | formatAmount }}
              </template>
              <template v-slot:cell(sheetDailyWage)="row">
                  {{ getSalary(row.item.sheetClockIn, row.item.sheetClockOut, 100) }}
              </template>
            <!-- / -->

            <!-- TODO DATE FORMATTING -->
              <template v-slot:cell(createdat)="row">
                  {{ row.item.createdat | moment("DD-MMM-YYYY") }}
              </template>
              <template v-slot:cell(sheetClockIn)="row">
                  {{ row.item.sheetClockIn == null?"--":row.item.sheetClockIn }}
              </template>
              <template v-slot:cell(sheetClockOut)="row">
                  {{ row.item.sheetClockOut == null?"--":row.item.sheetClockOut }}
              </template>
            <!-- / -->

            <!-- TODO TEXT ELLIPSIS FORMATTING -->
              <template v-slot:cell(employeeName)="row">
                  {{ row.item.employeeName | formatTextWithEllipsis }}
              </template>
              <template v-slot:cell(employeePayslipEmployeeName)="row">
                  {{ row.item.employeePayslipEmployeeName | formatTextWithEllipsis }}
              </template>
              <template v-slot:cell(businessName)="row">
                  {{ row.item.businessName | formatTextWithEllipsis }}
              </template>
              <template v-slot:cell(proprietorAddress)="row">
                  {{ row.item.proprietorAddress | formatTextWithEllipsis }}
              </template>
              <template v-slot:cell(businessType)="row">
                  {{ row.item.businessType | formatTextWithEllipsis }}
              </template>
              <template v-slot:cell(proprietorEmail)="row">
                  {{ row.item.proprietorEmail.toUpperCase() | formatTextWithEllipsis }}
              </template>
            <!-- / -->

            <!-- formatting bankcode-->

            <template v-slot:cell(destinationbankcode)="row" :title="row.item.authorizers">
                {{ getBankName(row.item.destinationbankcode, ft.originalBankList) }}
            </template>
            <!-- / -->

            <template v-slot:cell(serial)="row">
                {{ row.index + 1 }}
            </template>

            <template v-slot:cell(actions)="row">
                <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
                <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;" @click="row.toggleDetails" v-else></b-icon-eye>
            </template>

            <template v-slot:cell(actionsGrade)="row">
                    <b-icon-justify @click="navigate(row.item.employerGradeCode,'salary')" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
            </template>

            <template v-slot:cell(actionsProject)="row">
              <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
              <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;" @click="row.toggleDetails" v-else></b-icon-eye>
              <b-icon-justify @click="navigate(row.item.projectId,'project', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
            </template>

            <template v-slot:cell(actionsExpense)="row">
              <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
              <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;" @click="row.toggleDetails" v-else></b-icon-eye>
              <b-icon-pencil @click="showModal(row.item.expenseReference, 'expenseEdit', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
<!--              <b-icon-three-dots-vertical @click="showModal(row.item.expenseReference, 'expenseStatus', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>-->
            </template>

            <template v-slot:cell(actionsTask)="row">
              <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
              <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;" @click="row.toggleDetails" v-else></b-icon-eye>
              <b-icon-chat-dots @click="showModal(row.item.projectTaskId, 'taskComment', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
            </template>

            <template v-slot:cell(actionsCalender)="row">
                <b-icon-file-richtext @click="showModal(row.item.calenderDate, 'employeeSalary', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
            </template>

            <template v-slot:cell(actionsGradeSalary)="row">
                <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
                <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;" @click="row.toggleDetails" v-else></b-icon-eye>
                <b-icon-file-richtext @click="showModal(row.item.employerGradeSalaryId, 'gradeSalary', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
                <b-icon-three-dots @click="showModal(row.item.employeeVariablePayId, 'actions', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
            </template>

            <template v-slot:cell(actionsAttendance)="row">
                <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
                <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;" @click="row.toggleDetails" v-else></b-icon-eye>
                <b-icon-pencil @click="showModal(row.item.attendanceId, 'attendance', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
            </template>


          <template v-slot:cell(actionsClient)="row">
            <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
            <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;  padding: 0;" @click="row.toggleDetails" v-else></b-icon-eye>
            <b-icon-pencil @click="showModal(row.item.clientId, 'editClient', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="edit client"/>
            <b-icon-three-dots @click="showModal(row.item.clientId, 'actions', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view actions"/>
          </template>
          <template v-slot:cell(actionsSla)="row">
            <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
            <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;  padding: 0;" @click="row.toggleDetails" v-else></b-icon-eye>
            <b-icon-pencil @click="showModal(row.item.slaId, 'editSla', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="edit sla"/>
<!--            <router-link to="/sla-sheet"><b-icon-three-dots  style="cursor: pointer;  width: 30px; height: 20px;" title="view actions"/></router-link>-->
            <b-icon-three-dots  @click="toSlaSheet(row.item.slaId)" style="cursor: pointer;  width: 30px; height: 20px;" title="view actions"/>
          </template>


          <template v-slot:cell(actionsSheet)="row">
            <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
            <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;  padding: 0;" @click="row.toggleDetails" v-else></b-icon-eye>
<!--            <b-icon-pencil @click="showModal(row.item.slaId, 'editSla', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="edit sla"/>-->
            <!--            <router-link to="/sla-sheet"><b-icon-three-dots  style="cursor: pointer;  width: 30px; height: 20px;" title="view actions"/></router-link>-->
<!--/*            <b-icon-three-dots  @click="toSlaSheet(row.item.slaId)" style="cursor: pointer;  width: 30px; height: 20px;" title="view actions"/>*/-->
          </template>
<!--          @click="showModal(row.item.slaId, 'actions', row.item)"-->

            <template v-slot:cell(actionsStatus)="row">
                <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
                <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;  padding: 0;" @click="row.toggleDetails" v-else></b-icon-eye>
                <b-icon-file-richtext @click="showModal(row.item.employeeVariablePayId, 'editVariablePayment', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
                <b-icon-three-dots @click="showModal(row.item.employeeVariablePayId, 'actions', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
            </template>

            <template v-slot:cell(actionsEmployee)="row">
                <b-icon-eye-slash title="view less" style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye-slash>
                <b-icon-eye title="view more" style="cursor: pointer;  width: 30px; height: 20px;  padding: 0;" @click="row.toggleDetails" v-else></b-icon-eye>
                <b-icon-file-richtext @click="showModal(row.item.employeeDetailId, 'editEmployeeDetail', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
                <b-icon-three-dots @click="showModal(row.item.employeeDetailId, 'actions', row.item)" style="cursor: pointer;  width: 30px; height: 20px;" title="view grade salaries"/>
            </template>

            <template v-slot:cell(actionsLoan)="row">
                <b-icon-eye title="view less"  style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-if="row.detailsShowing"></b-icon-eye>
                <b-icon-eye-slash title="view more"  style="cursor: pointer; width: 30px; height: 20px;"  @click="row.toggleDetails" v-else></b-icon-eye-slash>
                &nbsp;
                <b-button title="Loan Schedule" variant="success" size="xs" @click="navigate(row.item.loanId, 'schedules')">
                    <b-icon-justify/>
                </b-button>
            </template>

            <template v-slot:row-details="row">
                <b-card>
                    <ul>
                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                    </ul>
<!--                    <sub-base-table-->
<!--                            :items="[]"-->
<!--                            :fields="subfields"-->
<!--                            :show-title="true"-->
<!--                            :is-busy="false"-->
<!--                            class="mt-2"-->
<!--                    ></sub-base-table>-->
                </b-card>
            </template>
        </b-table>

        <b-row>
            <b-col :md="filterMode === 'mini'?'4':'3'" class="my-1 ml-0">
                <b-form-group
                    :label="showTitle?'Per page':'Page'"
                    label-cols-sm="6"
                    label-cols-md="5"
                    label-cols-lg="4"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0">
                    <b-form-select
                        v-model="perPage"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col v-if="filterMode !== 'mini'" sm="4"></b-col>
            <b-col sm="4" :md="filterMode === 'mini'?'8':'4'" class="my-1 mr-0 mx-3">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="items==null?0:items.length"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <grade-salary-modal :show="showGradeSalary" :row="row"/>
        <employee-salary-modal :show="showEmployeeSalary" :row="row"/>
        <actions-modal :show="showAction" :row="row"/>
        <edit-variable-payment-modal :show="showEditVariablePayment" :row="row"/>
        <view-task-comment-modal :show="showTaskComment" :row="row"/>
        <update-employee-detail-modal :show="showEditDetailPayment" :row="row"/>
        <create-attendance-modal :show="showAttendance" :row="row"/>
        <create-expense-modal :show="showExpenseEdit" :row="row"/>
<!--        <edit-client-modal "/>-->
        <EditClientModal :show="showClientEdit" :row="row"/>
        <EditSLAModal :show="showSlaEdit" :row="row"></EditSLAModal>
    </b-container>
</template>

<script>
    import {mapState} from "vuex";
    import format from 'date-fns/format'
    import Swal from 'sweetalert2';
    import GradeSalaryModal from "../modal/GradeSalaryModal";
    import EmployeeSalaryModal from "../modal/EmployeeSalaryModal";
    import ActionsModal from "../modal/ActionsModal";
    import EditVariablePaymentModal from "../modal/EditVariablePaymentModal";
    import UpdateEmployeeDetailModal from "@/components/modal/UpdateEmployeeDetailModal";
    import ViewTaskCommentModal from "@/components/modal/ViewTaskCommentModal";
    import CreateExpenseModal from "@/components/modal/CreateExpenseModal";
    import CreateAttendanceModal from "@/components/modal/CreateAttendanceModal";
    import  EditClientModal from "@/components/modal/EditClientModal";
    import EditSLAModal from "@/components/modal/EditSLAModal"
    //actionsEmployee editEmployeeDetail
    // import {fbnCoreBaseUrlV1} from '../../app.config'
    // import SubBaseTable from "./SubBaseTable";
    import {PlayIcon}  from 'vue-feather-icons'
    import StoreUtils from "@/utils/BaseUtils/StoreUtils";
    // import Vue from "vue";
    export default {
        props:['items', 'fields', 'subfields', 'isBusy','showTitle', 'type','showMetrics','filterMode','accounts'],
        components:{
            PlayIcon,
            GradeSalaryModal,
            ActionsModal,
            EditVariablePaymentModal,
            UpdateEmployeeDetailModal,
            ViewTaskCommentModal,
            CreateExpenseModal,
            CreateAttendanceModal,
            EmployeeSalaryModal,
            EditClientModal,
            EditSLAModal
        },
        data() {
            return {
                show:false,
                dateFormat: 'D MMM',
                metricData:{
                    type:'custom',
                    startDate:'',
                    endDate:''
                },
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                pageOptions: [5, 10, 15, 20],
                sortBy: '',
                showGradeSalary: true,
                showEmployeeSalary: true,
                showAction: true,
                showEditVariablePayment: true,
                showTaskComment: true,
                showEditDetailPayment: true,
                showExpenseEdit: true,
                showExpenseStatus: true,
                showAttendance: true,
                showClientEdit: true,
                showSlaEdit: true,
                row: {},
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: ''
                },
                showInvoice: true,
                showDD: true,
                showBill: true,
                model:{
                    countryId: '',
                }

            }
        },
        computed: {
            ...mapState([
                'auth'
            ]),
        },
        mounted() {

            // Set the initial number of items
            // this.totalRows = this.items.length
        },
        methods: {
            differenceBtwDates(date1, date2){
              let currentDate = `${new Date().getFullYear()}-${new Date().getMonth()+1}-0${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
              let m1 = this.$moment(date1, 'DD-MM-YYYY HH:mm:ss');
              let m2 = date2==null?this.$moment(currentDate, 'DD-MM-YYYY HH:mm:ss'):date2===""?this.$moment(currentDate, 'DD-MM-YYYY HH:mm:ss'):this.$moment(date2, 'DD-MM-YYYY HH:mm:ss');
              return  m2.diff(m1,'minutes')
            },
            getSalary(date1, date2, houlyWage){
              let timeDiff = this.differenceBtwDates(date1, date2)
              return isNaN(((houlyWage/60)*timeDiff)) ? "0.00" : ((houlyWage/60)*timeDiff).toFixed(2)
            },
            async runVariablePayment(){
              await this.$store.dispatch("variablePayment/processVariablePayment", {
                employerId: localStorage.orgId,
                readAll: 'NO'
              }, {root: false})
              this.$store.dispatch("variablePayment/updateVariablePayments", {
                readAll:'NO',
                employerId: localStorage.orgId
              }, {root: false}).then()
            },
            navigate( _id, _type = 'schedules', _row={}){
                if (_type === 'schedules'){
                    localStorage.repaymentLoanId = _id;
                    this.$router.push('/loans/schedules');
                }else if(_type === 'salary'){
                    localStorage._gradeCode = _id;
                    this.$router.push('/grade-salaries');
                }else if(_type === 'project'){
                    localStorage._projectId = _id;
                    localStorage._projectTitle = _row.projectTitle;
                    this.$router.push('/single-project-tasks');
                }
            },
            disabled(_status){
                let rtn = false
                if (_status == null) return rtn
                return _status.toLowerCase() === 'deleted' ||
                    _status.toLowerCase() === 'approved' ||
                    //_status.toLowerCase() === 'orgapproved' ||
                    _status.toLowerCase() === 'orgdeclined' ||
                    _status.toLowerCase() === 'declined';

            },
            /**
             * @return {boolean}
             */
            OrgDisabled(_status){
                let rtn = false
                if (_status == null) return rtn
                return _status.toLowerCase() === 'deleted' || _status.toLowerCase() === 'orgapproved' || _status.toLowerCase() === 'approved' || _status.toLowerCase() === 'declined';

            },

            onFiltered(data){
                console.log(data)
            },

            showModal(_id, _type, _row = {}){
                //localStorage._counterparty = _id;
                if (_type === 'gradeSalary'){
                    this.row = _row
                    console.log(this.row)
                    this.showGradeSalary = !this.showGradeSalary
                }
                if (_type === 'employeeSalary'){
                    this.row = _row
                    this.showEmployeeSalary = !this.showEmployeeSalary
                }
                if (_type === 'actions'){
                    this.row = _row
                    this.showAction = !this.showAction
                }
                if (_type === 'editVariablePayment'){
                    this.row = _row
                    this.showEditVariablePayment = !this.showEditVariablePayment
                }
                if (_type === 'editEmployeeDetail'){
                    this.row = _row
                    this.row.type = 'edit'
                    this.showEditDetailPayment = !this.showEditDetailPayment
                }
                if (_type === 'editClient'){
                   console.log(this.showClientEdit)
                    this.row = _row
                    // this.row.type = 'edit'
                    this.showClientEdit = !this.showClientEdit
                }
                if (_type === 'editSla'){
                    this.row = _row
                    // this.row.type = 'edit'
                    this.showSlaEdit = !this.showSlaEdit
                }
                if (_type === 'taskComment'){
                    this.row = _row
                    //this.row.type = 'edit'
                    this.showTaskComment = !this.showTaskComment
                }
                if (_type === 'expenseEdit'){
                    this.row = _row
                    this.row.type = 'edit'
                    this.showExpenseEdit = !this.showExpenseEdit
                }
                if (_type === 'expenseStatus'){
                    this.row = _row
                    //this.row.type = 'edit'
                    this.showExpenseStatus = !this.showExpenseStatus
                }
                if (_type === 'attendance'){
                    this.row = _row
                    this.row.type = 'edit'
                    this.showAttendance = !this.showAttendance
                }
            },

            async updateStatus(_id, _status, _mode, _row = {}){
                let status = ''
                if (_status.toLowerCase().includes('approve')) status = 'approve';
                if (_status.toLowerCase().includes('decline')) status = 'decline';
                if (_status.toLowerCase().includes('delete')) status = 'delete';
                // if (_status.includes('delete')) status = 'delete';
                let errorMessage = ''
                this.$swal({
                    text: `Are you sure you want to perform this action(${status})?`,
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        let payload = {
                            approverid: this.system.userInfo.id,
                            contractChecker: this.system.userInfo.id,
                            beneficiaryid: _id,
                            orgId: localStorage.orgId,
                            status: _status,
                            contractType: '',
                            contractId: _id,
                            contractStatus: _status,
                            requestType: 'updateStatus',
                            contractCreditAccountNumber: _row.contractCreditAccountNumber,
                            contractDebitAccountNumber: _row.contractDebitAccountNumber,
                        }
                        let endpoint = 'beneficiary/approveBeneficiary'
                        if(_mode === 'db'){
                            payload.contractType = 'DD'//{approverid:this.system.userInfo.id, directdebitid:_id, status:_status}
                            endpoint = 'directDebit/approveContract'
                        }else if (_mode === 'si'){
                            payload.contractType = 'SI' //{approverid:this.system.userInfo.id, standinginstructionid:_id, status:_status}
                            endpoint = 'standingInstruction/approveContract'
                        }
                        console.log(endpoint,payload)
                        return this.$store.dispatch(endpoint, payload)
                        .then(response => {
                            console.log(response.data)
                            if (response.data.responseCode !== '00') {
                                errorMessage = response.data.responseMessage
                                throw new Error(response.data.responseMessage)
                            }
                            return response
                        }).catch(error => {
                                console.log(error)
                                Swal.showValidationMessage(
                                    `Request failed: ${errorMessage}`
                                )
                            })
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    console.log(result.value)
                    if (result.value.data.responseCode === '00') {
                        let payload = {
                            orgId: localStorage.orgId,
                            contractOrgId:localStorage.orgId,
                            module:'',
                            requestType:'read',contractType:''}
                        let endpoint = 'beneficiary/updateBeneficiaries'
                        if(_mode === 'db'){
                            // payload = {approverid:this.system.userInfo.id, directdebitid:_id, status:_status}
                            endpoint = 'directDebit/updateDirectDebits'
                            payload.module = 'payment';
                            payload.contractType = 'DD';
                            this.$store.dispatch('directDebit/updateDebitDirectDebits', payload , {root: false})
                            payload.module = 'collection';
                            payload.contractType = 'DD';
                        }else if (_mode === 'si'){
                            payload.module = 'payment';
                            payload.contractType = 'SI';
                            // payload = {approverid:this.system.userInfo.id, standinginstructionid:_id, status:_status}
                            endpoint = 'standingInstruction/updateStandingInstructions'
                        }

                        this.$store.dispatch(endpoint, payload , {root: false})
                        this.$swal(
                            'Success',
                            result.value.data.responseMessage,
                            'success'
                        )
                    }
                })
            },

            getMonday(d) {
                d = new Date(d);
                let day = d.getDay(),
                    diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
                return new Date(d.setDate(diff));
            },

            formatDates(dateOne, dateTwo) {
                let formattedDates = '';
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },

            deleteTran: async function(row){
                // console.log(row)
                this.$swal({
                    text: 'Are you sure you want to delete this transaction?',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                        console.log(row)
                        if (row.id != null)
                            await this.$store.dispatch('ft/deleteTransaction',{transactionid: row.id})
                        if (this.filterMode === 'interbankSingle')
                            this.$store.commit('ft/removeInterbankSingle', row);
                        else if (this.filterMode === 'interbankMultiple')
                            this.$store.commit('ft/removeInterbankMultiple', row);
                        else if (this.filterMode === 'intrabankSingle')
                            this.$store.commit('ft/removeIntrabankSingle', row);
                        else if (this.filterMode === 'intrabankMultiple')
                            this.$store.commit('ft/removeIntrabankMultiple', row);
                        else if (this.filterMode === 'ownAccountFt')
                            this.$store.commit('ft/removeOwnAccountFt', row);
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                })
            },

            rowClass(item, type) {
                if (!item || type !== 'row') return '';
                if (item.status != null)
                if (item.status.toLowerCase() === 'invalid account' && item.status != null) return 'table-danger';
                else if(item.status.toLowerCase() === 'account validated' && (item.beneficiaryname.toLowerCase() === item.nename.toLowerCase())) return 'table-success';
                else if (item.status.toLowerCase() === 'account validated') return 'table-primary';
                else if (item.status.toLowerCase().trim() === 'no response from server') return 'table-warning';
                else return '';
            },

            toSlaSheet(id){
              this.$router.push('/sheet');
              StoreUtils.commit("sla/updateSlaCode", id)
              console.log(id);
            }
        }
    }
</script>
