<template>
    <div class="body">
        <div class="preloader">
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__ball"></div>
        </div>
        <br><br><br><br><br>
<!--      <iframe class="d-none" @load="loadIframe()" id="f" :src="domain"></iframe>-->
<!--        <img src="https://assets-base.s3.amazonaws.com/img/firstbank/logo.png" class="img-fluid"  height="40px" width="100px" alt="FBNBank Logo">-->
    </div>
</template>

<script>
    // import store from "../../store/store";
    import {app, stage} from '@/app.config.json'
    import {mapState} from "vuex";
    export default {
        name: "Preloader",
        data(){
            return{
                finger:''
            }
        },
        beforeCreate() {
            this.$fingerprint.get((components) => {
                this.finger = this.$fingerprint.x64hash128(components.map((pair) => { return pair.value }).join(), 31)
                console.log(this.finger)
                this.$store.commit('auth/updateDeviceId', this.finger)
            });
        },
        mounted() {
          this.updateUserInfo()
        },
        methods:{
            updateUserInfo(_token){
              this.$store.commit('auth/updateAuthToken', _token)
              this.$store.dispatch('auth/validate', {})
              this.loadApp()
            },
            loadApp(){
              this.$store.dispatch("auth/validate", {}, {root: true}).then(validUser => {
                console.log('validUser>>>>>>>>',validUser)
                if (validUser){
                  this.$store.dispatch('auth/readCompany', {
                    userId: this.auth.userInfo.id,
                    requestType: 'company'
                  }).then(()=> {
                    console.log(this.auth.userInfo)
                    if (this.auth.userInfo.companies.data.length < 1)
                      // console.log(">>>>>>>>>>>redirect>>>>>>>>>>")
                      window.location = this.baseDomain + '/create-company/' + app
                    else {
                      if (localStorage.orgId == null && this.auth.userInfo.companies.data[0] != null) {
                        localStorage.orgId = this.auth.userInfo.companies.data[0].orgId
                      }
                      // eslint-disable-next-line no-unused-vars
                      this.initDashboard()
                    }
                  })
                }else
                  // 'xxx'
                  // console.log('failed validation')
                  window.location = this.domain//+'/login/'+app
              })
            },
            async initDashboard() {
                // await this.$store.dispatch("loan/updateLoans", {}, {root: true}).then()
              this.$store.dispatch("auth/initiateEmployer", this.auth.userInfo, {root: false}).then()
              this.$store.dispatch("employee/updateEmployees", {
                readAll:'NO',
                employerId: localStorage.orgId
              }, {root: false}).then()
                this.$router.push('/employees').catch(err => {
                    console.log(err)
                });
            }
        },
        computed: {
            ...mapState([
                'auth'
            ]),
            baseDomain(){
              if (stage === 'dev')
                return 'https://dev-profile.qoosquare.com'
              else
                return 'https://profile.qoosquare.com'
            },
            domain(){
              if (stage === 'dev')
                return 'https://dev-profile.qoosquare.com/login/'+app
              else
                return 'https://profile.qoosquare.com/login/'+app
            }
        },
    }
</script>

<style lang="scss">
    $bar-color: #3a82ff;
    $ball-color: #3a82ff;
    $bg-color: #ffffff;

    /*.body {*/
    /*    height: 100%;*/
    /*}*/
    .logo{
        position: relative;
    }
    .body {
        height: 780px;
        background: $bg-color;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .preloader {

        position: relative;
        width: 75px;
        height: 100px;

        &__bar {
            position: absolute;
            bottom: 0;
            width: 10px;
            height: 50%;
            background: $bar-color;
            transform-origin: center bottom;
            box-shadow: 1px 1px 0 rgba(0,0,0,.2);

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    left: ($i - 1) * 15px;
                    transform: scale(1,$i*.2);
                    animation: barUp#{$i} 4s infinite;
                }
            }

        }

        &__ball {
            position: absolute;
            bottom: 10px;
            left: 0;
            width: 10px;
            height: 10px;
            background: $ball-color;
            border-radius: 50%;
            animation: ball 4s infinite;
        }
    }

    @keyframes ball {
        0% {
            transform: translate(0, 0);
        }
        5% {
            transform: translate(8px, -14px);
        }
        10% {
            transform: translate(15px, -10px)
        }
        17% {
            transform: translate(23px, -24px)
        }
        20% {
            transform: translate(30px, -20px)
        }
        27% {
            transform: translate(38px, -34px)
        }
        30% {
            transform: translate(45px, -30px)
        }
        37% {
            transform: translate(53px, -44px)
        }
        40% {
            transform: translate(60px, -40px)
        }
        50% {
            transform: translate(60px, 0)
        }
        57% {
            transform: translate(53px, -14px)
        }
        60% {
            transform: translate(45px, -10px)
        }
        67% {
            transform: translate(37px, -24px)
        }
        70% {
            transform: translate(30px, -20px)
        }
        77% {
            transform: translate(22px, -34px)
        }
        80% {
            transform: translate(15px, -30px)
        }
        87% {
            transform: translate(7px, -44px)
        }
        90% {
            transform: translate(0, -40px)
        }
        100% {
            transform: translate(0, 0);
        }
    }

    @keyframes barUp1 {
        0% {
            transform: scale(1, .2);
        }
        40%{
            transform: scale(1, .2);
        }
        50% {
            transform: scale(1, 1);
        }
        90% {
            transform: scale(1,1);
        }
        100% {
            transform: scale(1,.2);
        }
    }
    @keyframes barUp2 {
        0% {
            transform: scale(1, .4);
        }
        40% {
            transform: scale(1, .4);
        }
        50% {
            transform: scale(1, .8);
        }
        90% {
            transform: scale(1, .8);
        }
        100% {
            transform: scale(1, .4);
        }
    }
    @keyframes barUp3 {
        0% {
            transform: scale(1, .6);
        }
        100% {
            transform: scale(1, .6);
        }
    }
    @keyframes barUp4 {
        0% {
            transform: scale(1, .8);
        }
        40% {
            transform: scale(1, .8);
        }
        50% {
            transform: scale(1, .4);
        }
        90% {
            transform: scale(1, .4);
        }
        100% {
            transform: scale(1, .8);
        }
    }
    @keyframes barUp5 {
        0% {
            transform: scale(1, 1);
        }
        40% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1, .2);
        }
        90% {
            transform: scale(1, .2);
        }
        100% {
            transform: scale(1, 1);
        }
    }
</style>
