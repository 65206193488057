import Employee from "../../views/employee/Employee";
import Department from "../../views/department/Department";

export default [
    {
        path: '/employees',
        name: 'Employees',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Employee,
    },
    {
        path: '/departments',
        name: 'Department',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Department,
    }
];
