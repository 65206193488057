<template>
    <div class="content-body pd-0">
        <div class="mail-wrapper mail-wrapper-two">
            <div class="mail-group" style="overflow-y:scroll; height: 100%;">
                <div class="mail-group-header justify-content-between">
                    <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">Calenders</h6>
                    <div class="dropdown tx-13">
                        <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">
                            <span title="Run Payroll"><play-icon @click="$refs['payroll-modal'].show()" style="cursor: pointer;"/></span>&nbsp;&nbsp;
                            <span title="Repeat Failed Payroll"><repeat-icon @click="$refs['payroll-retry-modal'].show()" style="cursor: pointer;"/></span>&nbsp;&nbsp;
                            <span title="Reload calender"><refresh-ccw-icon @click="loadCalender" style="cursor: pointer;"/></span>&nbsp;&nbsp;
                            <span title="Add new calender"><plus-square-icon style="cursor: pointer;" @click="$refs['calender-modal'].show()"/></span>
                        </h6>
                    </div><!-- dropdown -->
                </div><!-- mail-group-header -->
                <div class="mail-group-body">

                    <label class="mail-group-label">Recent Calenders</label>
                    <bar-loader v-if="calender.calenderLoading" class="custom-class" color="#6d0606" loading="loading" style="width: 100%;" sizeUnit="px"></bar-loader>
                    <ul class="list-unstyled media-list mg-b-0">
                        <span v-for="calender in calenders" v-bind:key="calender.calenderDate">
                            <li @click="selectCalender(calender.calenderDate)" :class="activeClass(calender.calenderDate,activeCalender.calenderDate)">
                                <div class="avatar"><span :class="`avatar-initial rounded-circle ${randomColor(getFormattedDate(calender.calenderDate))}`">{{ initial(getFormattedDate(calender.calenderDate)) }}</span></div>
                                <div class="media-body mg-l-15">
                                    <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
<!--                                        <span class="tx-12">{{calender.calenderHeadMaker}}</span>-->
                                        <span class="tx-12"><h6>{{getFormattedDate(calender.calenderDate)}}</h6></span>
                                        <span class="tx-11">{{calender.calenderDate}}</span>
                                    </div>
<!--                                    <h6 class="tx-13">{{calender.calenderDate}}</h6>-->
                                    <p class="tx-12 tx-color-03 mg-b-0">Employees: {{calender.numberOfEmployee}}</p>
                                    <p class="tx-12 tx-color-03 mg-b-0">Paid Stats: {{calender.numberOfPaidEmployee}}/{{calender.numberOfEligibleEmployee}}</p>
                                </div><!-- media-body -->
                            </li>
<!--                            <hr>-->
                        </span>
                    </ul>
                </div><!-- mail-group-body -->
            </div><!-- mail-group -->

            <div class="mail-content">
                <div class="mail-content-header">
                    <a href="" id="mailContentClose" class="link-02 d-none d-lg-block d-xl-none mg-r-20"><i data-feather="arrow-left"></i></a>
                    <div v-if="Object.keys(activeCalender).length > 0" class="media">
                        <b-icon-arrow-left v-if="this.$vssWidth < 1200" @click="navigate()" style="height: 20px; width: 50px; margin-left: -20px; margin-right: -20px; cursor: pointer;" class=" mg-t-8"/>
                        <div class="avatar avatar-sm  mg-l-10"><span class="avatar-initial rounded-circle bg-secondary">{{initial(getFormattedDate(calender.activeCalender))}}</span></div>
                        <div class="media-body mg-l-10">
                            <h6 class="mg-b-2 tx-13">{{activeCalender.calenderDate}}</h6>
                            <span class="d-block tx-11 tx-color-03">{{activeCalender.numberOfPaidEmployee}} of {{activeCalender.numberOfEligibleEmployee}}</span>
                        </div><!-- media-body -->
                    </div><!-- media -->
                    <nav class="nav nav-icon-only mg-l-auto">
                        <span class="nav-divider d-none d-sm-block"></span>
                        <a href="#" data-toggle="tooltip" title="Run Payroll" class="nav-link d-none d-sm-block"><play-icon @click="$refs['payroll-modal'].show()" style="cursor: pointer;"/></a>
                        <a href="#" data-toggle="tooltip" title="Repeat Failed Payroll" class="nav-link d-none d-sm-block"><repeat-icon @click="$refs['payroll-retry-modal'].show()" style="cursor: pointer;"/></a>
                        <a href="#" data-toggle="tooltip" title="Reload calender" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadCalender" style="cursor: pointer;"/></a>
                        <a href="#" data-toggle="tooltip" title="Add new calender" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" @click="$refs['calender-modal'].show()"/></a>
                    </nav>
                </div><!-- mail-content-header -->
                <div class="mail-content-body" style="overflow-y:scroll; height: 100%;">
                    <div v-if="Object.keys(activeCalender).length > 0" class="pd-20 pd-lg-25 pd-xl-30">
                        <!-- BASE TABLE FOR EMPLOYEES -->
                        <base-top-cards :carditems="cards"/>
                        <hr>
                        <base-table
                                :items="calenderEmployees"
                                :fields="fields"
                                :show-title="true"
                                :is-busy="calender.employeeLoading"
                                class="mt-2"
                                filter-mode="balances"
                        ></base-table>
                        <!-- /BASE TABLE FOR EMPLOYEES -->
                    </div>
                    <div v-else class="pd-20 pd-lg-25 pd-xl-30">
                        <span style="text-align: center;">
                            <h1 style="margin-top: 25%;"><b-icon-calendar style="height: 100px; width: 150px;"/></h1>
                            <p><b-button @click="$refs['calender-modal'].show()" variant="warning">Create a new calender</b-button></p>
                        </span>
                    </div>
                </div><!-- mail-content-body -->

            </div><!-- mail-content -->
        </div><!-- mail-wrapper -->
        <b-modal id="calender-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="calender-modal">
            <div class="modal-body">
                <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
                   @click="hide()">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div>
                    <h4>Start new calender</h4>
                    <b-form @submit.prevent="createCalender" enctype="multipart/form-data">
                        <b-form-group label="Calender Date">
                            <b-input type="date" required v-model="calenderModel.calenderDate"/>
                        </b-form-group>
                        <b-form-group label="Employee">
                            <b-select required v-model="calenderModel.calenderEmployeeId">
                                <b-select-option value="">Select employee</b-select-option>
                                <b-select-option v-for="emp in employees" :value="emp.employeeId" v-bind:key="emp.employeeId">{{emp.employeeFirstName}} {{emp.employeeMiddleName}} {{emp.employeeLastName}}</b-select-option>
                            </b-select>
                        </b-form-group>
                        <base-button title="start" :loading="calender.calenderLoading"/>
                    </b-form>
                </div>
            </div><!-- modal-body -->
        </b-modal>
        <b-modal id="payroll-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="payroll-modal">
            <div class="modal-body">
                <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
                   @click="hide()">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div>
                    <h4>Run Payroll</h4>
                    <b-form @submit.prevent="runPayroll" enctype="multipart/form-data">
                        <b-form-group label="Calender Date">
                            <b-select required v-model="calenderModel.calenderDate">
                                <b-select-option value="">Select calender date</b-select-option>
                                <b-select-option v-for="cal in calenders" :value="cal.calenderDate" v-bind:key="cal.calenderDate">{{cal.calenderDate}}</b-select-option>
                            </b-select>
                        </b-form-group>
                        <base-button title="start" :loading="calender.calenderLoading"/>
                    </b-form>
                </div>
            </div><!-- modal-body -->
        </b-modal>
        <b-modal id="payroll-retry-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="payroll-retry-modal">
            <div class="modal-body">
                <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
                   @click="hide()">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div>
                    <h4>Retry Failed Payslip</h4>
                    <b-form @submit.prevent="repeatPayroll" enctype="multipart/form-data">
                        <b-form-group label="Calender Date">
                            <b-select required v-model="calenderModel.calenderDate">
                                <b-select-option value="">Select calender date</b-select-option>
                                <b-select-option v-for="cal in calenders" :value="cal.calenderDate" v-bind:key="cal.calenderDate">{{cal.calenderDate}}</b-select-option>
                            </b-select>
                        </b-form-group>
                        <base-button title="Retry" :loading="calender.calenderLoading"/>
                    </b-form>
                </div>
            </div><!-- modal-body -->
        </b-modal>
    </div>
</template>

<script>
    import BaseTable from "../../components/table/BaseTable";
    import BaseButton from "../../components/button/BaseButton";
    import BaseTopCards from "../../components/card/BaseTopCards";
    import { PlusSquareIcon, RefreshCcwIcon, PlayIcon, RepeatIcon } from 'vue-feather-icons'
    import {mapState} from 'vuex'

    export default {
        name: 'Proprietor',
        data() {
            return {
                calenderModel:{
                    calenderDate: '',
                    employerId: '',
                    requestType: '',
                    payrollType: '',
                    calenderEmployeeId: '',
                    calenderEmployerId: ''
                },
                screen:'',
                activeCalender:{},
                fields: [
                    { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'calenderEmployeeName', label: 'Employee Name', sortable: true, sortDirection: 'desc' },
                    //{ key: 'calenderMiddleName', label: 'Middle Name', sortable: true, class: 'text-left' },
                    { key: 'earnings', label: 'Earnings', sortable: true, class: 'text-right' },
                    { key: 'deductions', label: 'Deductions', sortable: true, class: 'text-right'},
                    { key: 'actionsCalender', label: 'Action', sortable: true, class: 'text-center' }
                ],
            }
        },
        components: {
            BaseButton,
            RefreshCcwIcon,
            PlusSquareIcon,
            RepeatIcon,
            BaseTopCards,
            PlayIcon,
            BaseTable,
        },
        computed: {
            cards:{
                get(){
                    return [
                        {
                            id: 2,
                            name: 'Total Employee',
                            number: this.calender.employees.length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
                            color: 'bg-success'
                        },
                        {
                            id: 0,
                            name: 'Total Earnings',
                            number: this.calender.employees.reduce((init, curr) => (
                                init+=parseFloat(curr.earnings==null?'0.00':curr.earnings.toString().replace(/,/g,''))
                            ),0).toFixed(2),// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
                            color: 'bg-primary'
                        },
                        {
                            id: 1,
                            name: 'Total Deductions',
                            number: this.calender.employees.reduce((init, curr) => (
                                init+=parseFloat(curr.deductions==null?'0.00':curr.deductions.toString().replace(/,/g,''))
                            ),0).toFixed(2),
                            color: 'bg-orange'
                        },
                        // {
                        //     id: 3,
                        //     name: 'Suspended Calenders',
                        //     number: this.departments.filter(it => it.employerCalenderStatus.toUpperCase() === 'SUSPENDED').length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
                        //     color: 'bg-black-8'
                        // }
                    ]
                },
                set(){

                }
            },
            departments(){
                return  this.department.departments
            },
            employees(){
                return  this.employee.employees?this.employee.employees:[]
            },
            calenderEmployees(){
                return  this.calender.employees
            },
            calenders(){
                return  this.calender.calenders
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            ...mapState([
                'department',
                'calender',
                'employee',
                'auth'
            ])
        },
        methods: {
            getFormattedDate(_date){
                return `${this.$moment(new Date(_date)).format("MMMM")} ${this.$moment(new Date(_date)).format("dddd")}, ${this.$moment(new Date(_date)).format("DD")}`
            },
            loadEmployee(){
                this.$store.dispatch("employee/updateEmployees", {
                    readAll:'NO',
                    employerId: localStorage.orgId
                }, {root: false}).then()
            },
            async createCalender(){
                this.calenderModel.calenderEmployerId = localStorage.orgId
                await this.$store.dispatch("calender/createCalender", this.calenderModel, {root: false})
                this.loadCalender();
                this.hide()
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.calenderModel).forEach(function(key,index) {
                    self.calenderModel[key] = '';
                });

            },
            async runPayroll(){
                this.calenderModel.calenderEmployerId = localStorage.orgId
                this.calenderModel.employerId = localStorage.orgId
                this.calenderModel.payrollType = "MANUAL"
                this.calenderModel.requestType = "create"
                await this.$store.dispatch("calender/runPayroll", this.calenderModel, {root: false})
                this.loadCalender();
                this.hide()
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.calenderModel).forEach(function(key,index) {
                    self.calenderModel[key] = '';
                });

            },
            async repeatPayroll(){
                this.calenderModel.calenderEmployerId = localStorage.orgId
                this.calenderModel.employerId = localStorage.orgId
                await this.$store.dispatch("calender/repeatPayroll", this.calenderModel, {root: false})
                this.loadCalender();
                this.hide()
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.calenderModel).forEach(function(key,index) {
                    self.calenderModel[key] = '';
                });

            },
            hide() {
                this.$refs['calender-modal'].hide()
                this.$refs['payroll-modal'].hide()
                this.$refs['payroll-retry-modal'].hide()
            },
            loadCalender(){
                this.$store.commit('calender/updateCalenderLoading',true)
                this.$store.dispatch("calender/updateCalenders", {
                    employerId: localStorage.orgId,
                    readAll:'YES'
                }, {root: false}).then()
            },
            createTranscript(){
                let transcript ={
                    calenderTranscriptId:0,
                    calenderTranscriptBody:'',
                    calenderTranscriptCreatedAt:'',
                    employeeFirstName:'',
                    employeeLastName:'',
                    calenderTranscriptEmployeeId: '',
                    calenderTranscriptHeadId: ''
                }
                transcript.calenderTranscriptCreatedAt = new Date()
                transcript.calenderTranscriptEmployeeId = this.auth.userInfo.firstName+' '+this.auth.userInfo.lastName+' (Admin)'
                transcript.calenderTranscriptHeadId = this.activeCalender.calenderHeadId
                transcript.employeeFirstName = this.auth.userInfo.firstName
                transcript.employeeLastName =  `${this.auth.userInfo.lastName} (Admin)`
                transcript.calenderTranscriptBody = this.transcriptModel.calenderTranscriptBody

                this.activeCalender.calenderTranscripts.unshift(transcript)
                console.log(this.transcriptModel)
                this.$store.dispatch("calender/createTranscript", transcript, {root: false}).then()
                this.transcriptModel.calenderTranscriptBody = ''
            },
            selectCalender(_id){
                this.toggleBodyClass('addClass','mail-content-show')
                this.toggleBodyClass('addClass','mail-content-show')
                this.activeCalender = this.calenders.filter(it=>it.calenderDate === _id)[0]
                this.$store.dispatch('calender/updateCalenderEmployees', {
                    calenderDate: this.activeCalender.calenderDate,
                    employerId: localStorage.orgId
                })
            },
            toggleBodyClass(addRemoveClass, className) {
                const el = document.body;

                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
            navigate(){
                this.toggleBodyClass('removeClass','mail-content-show')
            },
            initial(_title){
                let titleArray = _title.split(' ')
                let firstInitial = titleArray[0].substring(0,1)
                let secondInitial = ""
                if (titleArray[1] != null)
                    secondInitial = titleArray[1].substring(0,1)
                return firstInitial+secondInitial
            },
            randomColor(_name){
                let initial = _name.substring(0,1)
                let color = ""
                if (initial.toUpperCase() === "A") color = "bg-indigo"
                else if (initial.toUpperCase() === "B") color = "bg-dark"
                else if (initial.toUpperCase() === "C") color = "bg-success"
                else if (initial.toUpperCase() === "D") color = "bg-warning"
                else if (initial.toUpperCase() === "E") color = "bg-primary"
                else if (initial.toUpperCase() === "F") color = "bg-info"
                else if (initial.toUpperCase() === "G") color = "bg-danger"
                else if (initial.toUpperCase() === "H") color = "bg-dark"
                else if (initial.toUpperCase() === "I") color = "bg-muted"
                else if (initial.toUpperCase() === "J") color = "bg-indigo"
                else if (initial.toUpperCase() === "K") color = "bg-dark"
                else if (initial.toUpperCase() === "L") color = "bg-warning"
                else if (initial.toUpperCase() === "M") color = "bg-info"
                else if (initial.toUpperCase() === "N") color = "bg-dark"
                else if (initial.toUpperCase() === "O") color = "bg-danger"
                else if (initial.toUpperCase() === "P") color = "bg-warning"
                else if (initial.toUpperCase() === "Q") color = "bg-info"
                else if (initial.toUpperCase() === "R") color = "bg-muted"
                else if (initial.toUpperCase() === "S") color = "bg-primary"
                else if (initial.toUpperCase() === "T") color = "bg-dark"
                else if (initial.toUpperCase() === "U") color = "bg-dark"
                else if (initial.toUpperCase() === "V") color = "bg-primary"
                else if (initial.toUpperCase() === "W") color = "bg-indigo"
                else if (initial.toUpperCase() === "X") color = "bg-muted"
                else if (initial.toUpperCase() === "Y") color = "bg-primary"
                else if (initial.toUpperCase() === "Z") color = "bg-warning"
                else color = "bg-primary"
                return color
            },
            activeClass(_id1,_id2){
                if(_id1 === _id2) return 'media'
                else return 'media unread'
            },
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {
            this.$store.dispatch("calender/updateCalenders", {
                employerId: localStorage.orgId,
                readAll:'YES'
            }, {root: false}).then()
            this.loadEmployee()
        }
    }


</script>

<style scoped>
    @media (min-width: 1200px){
        .mail-group {
            width: 360px;
            border-right: 1px solid rgba(72, 94, 144, 0.16);
        }
    }

    /*@media (min-width: 992px){*/
    /*    .mail-content {*/
    /*        left: 360px;*/
    /*        width: auto;*/
    /*        right: 0;*/
    /*        display: block;*/
    /*    }*/
    /*     .mail-content {*/
    /*         position: absolute;*/
    /*         top: 0;*/
    /*         left: 200vw;*/
    /*         bottom: 0;*/
    /*         width: 100vw;*/
    /*         display: block;*/
    /*         background-color: #f5f6fa;*/
    /*     }*/
    /*    div {*/
    /*        display: block;*/
    /*    }*/
    /*}*/

</style>
