import Attendance from "@/views/attendance/Attendance";

export default [
    {
        path: '/attendances',
        name: 'Attendance',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Attendance,
    },
];
