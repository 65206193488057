import store from "../../store/store";

class StoreUtils {
  static actions = {
    loader: {
      showBlockingLoader: "loader/showBlockingLoader",
      showNonBlockingLoader: "loader/showNonBlockingLoader",
      showTableLoader: "loader/showTableLoader",
      showComponentLoader: "loader/showComponentLoader"
    },
    notification: {
      addNotificationSlide: "notification/addNotificationSlide",
      removeNotificationSlide: "notification/removeNotificationSlide",
      showNotificationModal: "notification/showNotificationModal",
      closeNotificationModal: "notification/closeNotificationModal"
    },
    router: {
      setActiveRoute: "router/setActiveRoute"
    },
    auth: {
      LOGIN: "auth/login",
      TIMESLOT_LOGIN: "auth/timeslotLogin",
      START_ENROLLMENT: "auth/startEnrollment",
      END_ENROLLMENT: "auth/endEnrollment",
      RESEND_OTP: "auth/resendOtp",
      INITIATE_RESET: "auth/initiatePasswordReset",
      COMPLETE_RESET: "auth/completePasswordReset",
      CHANGE_PASSWORD: "auth/changePassword",
      REGISTER: "auth/createProfile",
      FORGOT_PASSWORD: "auth/forgotPassword",
      CREATE_APPLICANT: "auth/createApplicant",
      INIT_APP: "auth/initApp",
      LOGOUT: "auth/logout",
      ADD_EDUCATION: "auth/addEducation",
      ADD_PROJECTS: "auth/addProjects",
      ADD_EMPLOYMENT: "auth/addEmployment",
      ADD_APPLICANT_DETAILS: "auth/addApplicantDetail",
      CREATE_RECRUITER: "auth/createRecruiter",
      CONFIRM_RECRUITER_OTP: "auth/confirmRecruiterOTP",
      ADD_ORGANIZATION: "auth/addOrganization",
      RECRUITER_ADD_PROJECT: "auth/recruiterAddProject",
      POST_JOB: "auth/postJob",
      SEE_TALENTS: "auth/seeTalents",
      PROFILE_PORTFOLIO: "auth/profilePortfolio",
      PROFILE_PROJECTS: "auth/profileProjects",
      PROFILE_EMPLOYMENT: "auth/profileEmployment",
      PROFILE_EDUCATION: "auth/profileEducation",
      APPLICANT_REFRESH: "auth/applicantRefreshApi",
      APPLICANT_REFRESH_NON_BLOCKING: "auth/applicantRefreshNonBlocking",
      USER_PROFILE: "auth/editUserProfile",
      FORGOT_PASSWORD_OTP: "auth/confirmForgotPasswordOtp",
      INTERVIEW_REFRESH_NON_BLOCKING: "auth/interviewRefreshNonBlocking",
      FETCH_DEGREES: "auth/fetchDegrees"
    },
    user: {
      SET_USER_INFO: "user/setUserInfo"
    },
    company: {
      SET_NEW_COMPANY: "company/setNewCompany"
    },
    client:{
      CREATE_CLIENT: "client/createClient",
      READ_BY_COMPANY_CODE: "client/readClientByCompanyCode",
      UPDATE_CLIENT: "client/editClient"
    },
    sla:{
      CREATE_SLA: "sla/createSla",
      READ_BY_ID: "sla/readSlaById",
      READ_BY_COMPANY_CODE: "sla/readSlaByCompanyCode",
      UPDATE_SLA: "sla/editSla"
    }

  };

  static getters = {
    form: {
      GET_FORM_BODY: "form/getFormBody",
      GET_FORM_STAGE: "form/getFormStage"
    },
    router: {
      GET_ACTIVE_ROUTE: "router/getActiveRoute"
    },
    utils: {
      GET_CSV_JSON: "utils/getCsvJson"
    },
    auth: {
      GET_USER_REG_TOKEN: "auth/getUserRegToken",
      GET_APPLICANT_ID: "auth/getApplicantId",
      GET_RECRUITER_ID: "auth/getRecruiterId",
      GET_SELECTED_SKILL: "auth/getSelectedSkill",
      GET_ACTION_MODAL_OPEN: "auth/getActionModalOpen",
      GET_EMAIL: "auth/getEmail",
      GET_PASSWORD: "auth/getPassword",
      GET_USER_TYPE: "auth/getUserType",
      GET_CHANGE_PASSWORD_SUCCESS: "auth/getChangePasswordSuccess",
      GET_DEGREES: "auth/getDegrees",
      GET_USER_INFO: "auth/getUserInfo"
    },
    professional: {
      GET_CATEGORIES: "professional/getCategories",
      GET_EXPERIENCE_LEVELS: "professional/getExperienceLevels",
      GET_PROFESSIONAL_INFO: "professional/getProfessionalInfo",
      GET_PROFESSIONAL_ID: "professional/getProfessionalId",
      GET_PROFESSIONALS: "professional/getProfessionals",
      GET_PROFESSIONAL: "professional/getProfessional"
    },
    customer: {
      GET_CUSTOMER_INFO: "customer/getCustomerInfo"
    },
    availability: {
      GET_AVAILABLE_TIME: "availability/getAvailableTime",
      GET_CLICKED_TIME: "availability/getClickedTime",
      GET_AVAILABLE_GROUPS: "availability/getAvailableGroups"
    },
    user: {
      GET_USER_EMAIL: "userAccount/getUserEmail",
      GET_USER_MOBILE_NUMBER: "user/getMobileNumber",
      GET_APPLICANT_ID: "auth/getApplicantId",
      GET_USER_INFO: "user/getUserInfo",
      GET_UPLOADED_IMAGE: "user/getUploadedImage"
    },
    applicant: {
      GET_APPLICANT_ID: "applicant/getApplicantId",
      GET_APPLICANT_PROFILE: "applicant/getApplicantProfileDetail",
      GET_ROLES: "applicant/getRoles",
      GET_DEGREES: "applicant/getDegrees",
      GET_SKILLS: "applicant/getSkills",
      GET_INDUSTRIES: "applicant/getIndustries",
      GET_APPLICANT_EDUCATION: "applicant/getEducationList",
      GET_APPLICANT_PROJECT: "applicant/getProjectHistory",
      GET_APPLICANT_EMPLOYMENT: "applicant/getEmploymentList",
      GET_APPLICANT_AUTH_TOKEN: "applicant/getApplicantAuthToken",
      GET_APPLICANT_NEW_ID: "applicant/getApplicantId",
      GET_EMPLOYMENT_HISTORY: "applicant/getEmploymentHistory",
      GET_ADD_EMPLOYMENT_SUCCESS: "applicant/getAddEmploymentSuccess",
      GET_EDIT_EMPLOYMENT_SUCCESS: "applicant/getEditEmploymentSuccess",
      GET_DELETE_EMPLOYMENT_SUCCESS: "applicant/getDeleteEmploymentSuccess",
      GET_EDUCATION_ID: "applicant/getEducationId",
      GET_PROJECT_HISTORY: "applicant/getProjectHistory",
      GET_ADD_PROJECT_SUCCESS: "applicant/getAddProjectSuccess",
      GET_EDIT_PROJECT_SUCCESS: "applicant/getEditProjectSuccess",
      GET_DELETE_PROJECT_SUCCESS: "applicant/getDeleteProjectSuccess",
      GET_ADD_EDUCATION_SUCCESS: "applicant/getAddEducationSuccess",
      GET_PORTFOLIO_LINKS: "applicant/getPortfolioLinks",
      GET_EDIT_PORTFOLIO_SUCCESS: "applicant/getEditPortfolioSuccess",
      GET_ALL_JOBS: "applicant/getAllJobs",
      GET_APPLIED_JOBS: "applicant/getAppliedJobs",
      GET_CURRENT_APPLIED: "applicant/getCurrentApplied",
      GET_APPLICANT_INTERVIEWS: "applicant/getApplicantInterviews",
      GET_ACCEPT_INTERVIEW_SUCCESS: "applicant/getAcceptInterviewSuccess",
      GET_DECLINE_INTERVIEW_SUCCESS: "applicant/getDeclineInterviewSuccess",
      GET_USER_ID: "applicant/getUserId",
      GET_RESCHEDULE_INTERVIEW_SUCCESS:
        "applicant/getRescheduleInterviewSuccess",
      GET_INTERVIEW_ID: "applicant/getInterviewId",
      GET_INTERVIEW_LOCATION: "applicant/getInterviewLocation",
      GET_INTERVIEW_DATE: "applicant/getInterviewDate",
      GET_APPLICANT_INTERVIEW_ID: "applicant/getApplicantInterviewId",
      GET_JOB_ID: "applicant/getJobId",
      GET_SINGLE_INTERVIEW: "applicant/getSingleInterview",
      GET_ALL_APPLICANTS: "applicant/getAllApplicants",
      GET_SINGLE_JOB: "applicant/getSingleJob",
      GET_APPLICATION_SUCCESS: "applicant/getApplicationSuccess"
    },
    recruiter: {
      GET_ADD_COMPANY_SUCCESS: "recruiter/getAddCompanySuccess",
      GET_EDIT_COMPANY_SUCCESS: "recruiter/getEditCompanySuccess",
      GET_RECRUITER_DETAILS: "recruiter/getRecruiterDetails",
      GET_RECRUITER_JOBS: "recruiter/getRecruiterJobs",
      GET_CURRENT_JOB_ID: "recruiter/getCurrentJobId",
      GET_CURRENT_JOB: "recruiter/getCurrentJob",
      GET_JOB_APPLICANTS: "recruiter/getJobApplicants",
      GET_JOB_APPLICANT: "recruiter/getJobApplicant",
      GET_JOB_INTERVIEWS: "recruiter/getJobInterviews",
      GET_OFFERS: "recruiter/getOffers",
      GET_MAKE_OFFER_SUCCESS: "recruiter/getMakeOfferSuccess",
      GET_CHANGE_PASSWORD_SUCCESS: "recruiter/getChangePasswordSuccess",
      GET_UPDATE_JOB_STATUS_SUCCESS: "recruiter/getUpdateJobStatusSuccess"
    }
  };

  static mutations = {
    form: {
      BUILD_FORM_BODY: "form/BUILD_FORM_BODY"
    },
    auth: {
      SET_USER_REG_TOKEN: "auth/SET_USER_REG_TOKEN",
      SET_APPLICANT_ID: "auth/SET_APPLICANT_ID",
      SET_SELECTED_SKILL: "auth/SET_SELECTED_SKILL",
      SET_RECRUITER_ID: "auth/SET_RECRUITER_ID",
      SET_ACTION_MODAL_OPEN: "auth/SET_ACTION_MODAL_OPEN",
      SET_EMAIL: "auth/SET_EMAIL",
      SET_PASSWORD: "auth/SET_PASSWORD",
      SET_USER_TYPE: "auth/SET_USER_TYPE",
      SET_CHANGE_PASSWORD_SUCCESS: "auth/SET_CHANGE_PASSWORD_SUCCESS",
      SET_DEGREES: "auth/SET_DEGREES"
    },
    user: { SET_UPLOADED_IMAGE: "user/SET_UPLOADED_IMAGE" },

  };

  static localStorageStore = {
    form: {},
    auth: {
      APPLICANT_ID: "auth.applicantId",
      RECRUITER_ID: "auth.recruiterId"
    },
    user: {
      USER_ID: "userId"
    }
  };

  static dispatch(actionToDispatch, payload) {
    return store.dispatch(actionToDispatch, payload, { root: true });
  }

  static commit(mutationToCommit, payload) {
    return store.commit(mutationToCommit, payload, { root: true });
  }

  static rootGetters(getterToGet, payload) {
    if (payload) {
      return store.getters[getterToGet](payload);
    } else {
      return store.getters[getterToGet];
    }
  }
}

export default StoreUtils;
