<template>
    <div>
        <hr class="mg-t-10 mg-b-30">
        <div class="row">
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-b-3">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-gear/></div>
                        <a style="text-decoration: none; color: black;" target="_blank" href="https://businessaccount.rubies.ng">
                            <h5 style="cursor: pointer;" >Settings and Profile</h5>
                        </a>
                        <p class="tx-color-03 mg-b-0">{{ $t('erp.textOne')}}</p>
                        <p class="tx-color-03 mg-b-0">{{ $t('erp.textTwo')}}</p>
                        <p class="tx-color-03 mg-b-0">{{ $t('erp.textThree')}}</p>
                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <a style="text-decoration: none; color: black;" target="_blank" href="https://businessaccount.rubies.ng">
                            <span>Open Module</span>
                        </a>
                        <a style="text-decoration: none;  color: black;" target="_blank"  href="https://businessaccount.rubies.ng">
                            <span style="cursor: pointer;" class="tx-18 lh-0"><b-icon-arrow-right/></span>
                        </a>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-b-3">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-book/>
                        </div>
                        <h5 style="cursor: pointer;" @click="navigate('/employees')">
                            QooBook
                            </h5>
                        <p class="tx-color-03 mg-b-0">Dashboard and Analytics</p>
                        <p class="tx-color-03 mg-b-0">Portfolio at Risk</p>
                        <p class="tx-color-03 mg-b-0">Loan Categories. All Loans</p>


                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span @click = "navigate('/employees')" style="cursor: pointer;">Open Module</span>
                        <span @click = "navigate('/employees')" style="cursor: pointer;" class="tx-18 lh-0"><b-icon-arrow-right/></span>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-b-3">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-credit-card/></div>
                        <a style="text-decoration: none; color: black;" target="_blank" href="https://businessbanking.rubies.ng">
                            <h5 style="cursor: pointer;" >Banking</h5>
                        </a>
                        <p class="tx-color-03 mg-b-0">Business, SMEs, MSMEs </p>
                        <p class="tx-color-03 mg-b-0">Business Loan History</p>
                        <p class="tx-color-03 mg-b-0">Proprietors Loan Charts</p>

                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <a style="text-decoration: none; color: black;" target="_blank" href="https://businessbanking.rubies.ng">
                            <span>Open Module</span>
                        </a>
                        <a style="text-decoration: none;  color: black;" target="_blank"  href="https://businessbanking.rubies.ng">
                            <span style="cursor: pointer;" class="tx-18 lh-0"><b-icon-arrow-right/></span>
                        </a></div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-b-3">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-inboxes/></div>
                        <h5><a href="#" class="link-01">Merchant Services</a>
                        </h5>
                        <p class="tx-color-03 mg-b-0">Dashboard and Analytics</p>
                        <p class="tx-color-03 mg-b-0">Dynamic Reporting</p>
                        <p class="tx-color-03 mg-b-0">Parameterized and Custom Reporting</p>
                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span class="tx-center"><a href="#" class=" badge block badge-danger">{{ $t('miscellaneous.comingSoon')}}</a></span>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->

            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-b-3">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-graph-up/></div>
                        <h5 style="cursor: pointer;">Funding and Capital
                        </h5>
                        <p class="tx-color-03 mg-b-0">{{ $t('erp.textOne')}}</p>
                        <p class="tx-color-03 mg-b-0">{{ $t('erp.textTwo')}}</p>
                        <p class="tx-color-03 mg-b-0">{{ $t('erp.textThree')}}</p>
                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span class="tx-center"><a href="#" class=" badge block badge-danger">{{ $t('miscellaneous.comingSoon')}}</a></span>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-b-3">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-chat/>
                        </div>
                        <h5 style="cursor: pointer;">Yharnn</h5>
                        <p class="tx-color-03 mg-b-0">Dashboard and Analytics</p>
                        <p class="tx-color-03 mg-b-0">Portfolio at Risk</p>
                        <p class="tx-color-03 mg-b-0">Loan Categories. All Loans</p>


                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span class="tx-center"><a href="#" class=" badge block badge-danger">{{ $t('miscellaneous.comingSoon')}}</a></span>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-b-3">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-grid/></div>
                        <h5 style="cursor: pointer;">Liquidity and Investment</h5>
                        <p class="tx-color-03 mg-b-0">Business, SMEs, MSMEs </p>
                        <p class="tx-color-03 mg-b-0">Business Loan History</p>
                        <p class="tx-color-03 mg-b-0">Proprietors Loan Charts</p>

                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span class="tx-center"><a href="#" class=" badge block badge-danger">{{ $t('miscellaneous.comingSoon')}}</a></span>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-b-3">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-terminal/></div>
                        <h5><a href="#" class="link-01">Report Engine</a>
                        </h5>
                        <p class="tx-color-03 mg-b-0">Dashboard and Analytics</p>
                        <p class="tx-color-03 mg-b-0">Dynamic Reporting</p>
                        <p class="tx-color-03 mg-b-0">Parameterized and Custom Reporting</p>
                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span class="tx-center"><a href="#" class=" badge block badge-danger">{{ $t('miscellaneous.comingSoon')}}</a></span>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
        </div><!-- row -->
<!--        <hr class="mg-t-30 mg-b-30">-->
    </div>
</template>

<script>
    // import FlotChart from "../FlotChart";
    // import {} from "vue-feather-icons"
    import "jquery"
    import {mapState} from "vuex"
    export default {
        name: "TopCards",
        components: {},
        methods:{
            navigate(_route){
                this.$router.push(_route)
                console.log(_route)
            },
            popToast() {
                this.$notify({
                    group: 'foo',
                    title: 'Important message',
                    text: 'Hello user! This is a notification!'
                });
            }

        },
        computed:{
            ...mapState([
                'company'
            ])
        }
    };
</script>

<style scoped>
    p {
        font-size: smaller !important;
    }
    @media only screen and (min-width: 900px) {
        .top-card {
            width: 103%;
        }
    }
    @media only screen and (min-width: 707px) and (max-width: 900px) {
        .top-card {
            width: 104%;
        }
    }

    @media only screen and (min-width: 517px) and (max-width: 707px) {
        .top-card {
            width: 105%;
        }
    }

    @media only screen and (min-width: 451px) and (max-width: 517px) {
        .top-card {
            width: 106%;
        }
    }

    @media only screen and (max-width: 451px) {
        .top-card {
            width: 107%;
        }
    }
</style>
