class SLARequest{
    static create = {
        slaCode : null,
        slaClientCode :  null,
        slaHourlyRate :  null,
        slaDay :  null,
        slaType :  null,
        slaStartDate :  null,
        slaEndDate :  null,
        slaHourStart :  null,
        slaHourEnd :  null,
        slaResourceCode :  null,
        slaCompanyCode: null,
        slaStatus: null
    }

    static readBy = {
        slaId: null
    }

    static readByCompany = {
        slaCompanyCode: null
    }

    static edit = {
        slaCode : null,
        slaClientCode :  null,
        slaHourlyRate :  null,
        slaDay :  null,
        slaType :  null,
        slaStartDate :  null,
        slaEndDate :  null,
        slaHourStart :  null,
        slaHourEnd :  null,
        slaResourceCode :  null,
        slaCompanyCode: null,
        slaStatus: null
    }


    static readSheetBySla = {
        sheetSlaCode: null
    }
}

export default SLARequest;