import EmployeeService from "../../service/EmployeeService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    employees:[],
    skillCategories:[],
    educations:[],
    employments:[],
    skills:[],
    employeeLoading: false,
    metrics: {
        type:'last30',
        startDate:null,
        endDate:null,
    },
    response:{}

}

export const mutations = {
    updateEmployees: (state, payload) =>{
        state.employees = payload
    },
    updateSkillCategories: (state, payload) =>{
        state.skillCategories = payload
    },
    updateSkills: (state, payload) =>{
        state.skills = payload
    },
    updateEducations: (state, payload) =>{
        state.educations = payload
    },
    updateEmployments: (state, payload) =>{
        state.employments = payload
    },
    updateMetrics: (state, payload) =>{
        state.metrics = payload
    },
    updateEmployeeLoading: (state, payload) =>{
        state.employeeLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    }
}

export const actions = {
    updateEmployees: ({ commit, state }, payload)=>{
        if(state.employees.length < 1)
            commit("updateEmployeeLoading", true)
        return EmployeeService.callReadApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateEmployeeLoading", false)
                    let responseData = response.data;
                    commit("updateEmployees", responseData.data)
                }

            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    createEmployee: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callCreateApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    createSkill: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callSkillCreateApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    editSkill: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callSkillEditApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    readSkillCategory: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callSkillCategoryReadApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateEmployeeLoading", false)
                    let responseData = response.data;
                    commit("updateSkillCategories", responseData.data)
                }

            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    readSkills: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callSkillReadApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateEmployeeLoading", false)
                    let responseData = response.data;
                    commit("updateSkills", responseData.data)
                }

            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    createEducation: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callEducationCreateApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    editEducation: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callEducationEditApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    readEducations: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callEducationReadApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateEmployeeLoading", false)
                    let responseData = response.data;
                    commit("updateEducations", responseData.data)
                }

            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    createEmployment: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callEmploymentCreateApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    editEmployment: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callEmploymentEditApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    updateEmployeeDetailStatus: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callEmployeeDetailStatusApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    editEmployeeDetail: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callUpdateDetailApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    },
    readEmployments: ({ commit }, payload)=>{
        commit("updateEmployeeLoading", true)
        return EmployeeService.callEmploymentReadApi(payload)
            .then(response => {
                commit("updateEmployeeLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateEmployeeLoading", false)
                    let responseData = response.data;
                    commit("updateEmployments", responseData.data)
                }

            })
            .catch(error => {
                commit("updateEmployeeLoading", false)
                alert(error)
                // throw error;
            });
    }
}