<template>
    <b-modal id="skill-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="skill-create-modal">
        <div id="printMe" class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
              <p v-if="attendance.qrLoading" style="text-align: center;"><b-spinner  variant="primary"/></p>
              <p v-else style="text-align: center;"><vue-qrcode :value="JSON.stringify(attendance.qr)" /></p>
              <b-button @click="print" variant="primary"><b-icon-printer/> Print</b-button>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    // import BaseButton from "../button/BaseButton";
    import VueQrcode from 'vue-qrcode'
    export default {
        props:['show','row'],
        components:{VueQrcode},
        name: 'ConfirmModal',
        data(){
            return{
                password: '',
                model:{
                  expenseId: '',
                  expenseEmployeeId: '',
                  expenseCost: '',
                  expenseDate: '',
                  educationDegree: '',
                  expenseDescription: '',
                  expenseCategory: '',
                  expenseStatus: ''
                },
            }
        },
        methods:{
            hide() {
                this.$refs['skill-create-modal'].hide()
            },
            print () {
              // Pass the element id here
              this.$htmlToPaper('printMe');
            },
            async submit(){
                this.model.expenseEmployerId = localStorage.orgId
                if (this.model.type === 'edit')
                  await this.$store.dispatch("expense/editExpense", this.model, {root: false});
                else {
                  //this.model.expenseEmployeeId = this.row.employeeId
                  await this.$store.dispatch("expense/createExpense", this.model, {root: false});
                }
                this.hide();
                this.$store.dispatch('expense/updateExpenses', {
                  readAll: 'NO',
                  employerId: localStorage.orgId,
                  employeeId: localStorage.orgId
                })
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            loadGrade(){
                this.$store.dispatch("grade/updateGradeSalaries", {
                    readAll:'NO',
                    employerGradeSalaryEmployerId: localStorage.orgId,
                    employerGradeSalaryGradeCode: localStorage._gradeCode
                }, {root: false}).then()
            },
            hideauth: function () {
                this.password = '';
                this.$refs['skill-create-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row readSkillCategory
          this.$store.dispatch('employee/readSkillCategory',{readAll:"YES"})
        },
        computed:{
            ...mapState(['grade','auth','employee','attendance']),
          employees(){
            return  this.employee.employees?this.employee.employees:[]
          },
        },
        watch: {
            show: function () {
                let self = this;
              // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                this.$store.dispatch('attendance/generateQR',{employerId:localStorage.orgId})
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                this.$refs['skill-create-modal'].show()
            }
        },
    }
</script>
