import PayrollService from "../../service/PayrollService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    payslips:[],
    payslipSingle:{},
    payslipLoading: false,
    payslipSingleLoading: false,
    response:{}

}

export const mutations = {
    updatePayslips: (state, payload) =>{
        state.payslips = payload
    },
    updatePayslipSingle: (state, payload) =>{
        state.payslipSingle = payload
    },
    updatePayslipLoading: (state, payload) =>{
        state.payslipLoading = payload
    },
    updatePayslipSingleLoading: (state, payload) =>{
        state.payslipSingleLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
}

export const actions = {
    updatePayslips: ({ commit, state }, payload)=>{
        if(state.payslips.length < 1)
            commit("updatePayslipLoading", true)
        return PayrollService.callReadPayslipApi(payload)
            .then(response => {
                commit("updatePayslipLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updatePayslipLoading", false)
                    let responseData = response.data;
                    commit("updatePayslips", responseData.data)
                }

            })
            .catch(error => {
                commit("updatePayslipLoading", false)
                alert(error)
                // throw error;
            });
    },
    updatePayslipSingle: ({ commit }, payload)=>{
        commit("updatePayslipSingleLoading", true)
        return PayrollService.callReadPayslipSingleApi(payload)
            .then(response => {
                commit("updatePayslipSingleLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updatePayslipSingleLoading", false)
                    let responseData = response.data;
                    commit("updatePayslipSingle", responseData)
                }

            })
            .catch(error => {
                commit("updatePayslipSingleLoading", false)
                alert(error)
                // throw error;
            });
    },
    createPayslip: ({ commit }, payload)=>{
        commit("updatePayslipLoading", true)
        return PayrollService.callCreatePayslipApi(payload)
            .then(response => {
                commit("updatePayslipLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updatePayslipLoading", false)
                alert(error)
                // throw error;
            });
    },
}