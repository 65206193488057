<template>
  <div class="content ht-100v pd-0">
    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">Expenses</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">All Expenses</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">View All Expenses</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
          <!--                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLoginDate | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
        </div>
        <div class="d-none d-md-block">
          <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5" @click="showModal = !showModal"  v-b-modal="'expense-modal'"><plus-icon class="wd-10 mg-r-5" /> Add Expense</button>
          <download-excel
              :data="expenses"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
          </download-excel>
          <download-excel
              type="csv"
              :data="expenses"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
          </download-excel>

        </div>
      </div>
      <base-top-cards :carditems="cards"/>
      <hr>

      <base-table
          :items="expenses"
          :fields="fields"
          :show-title="true"
          :is-busy="expense.expenseLoading"
          class="mt-2"
          filter-mode="balances"
      ></base-table>
      <create-expense-modal :show="showModal" :row="{}"/>
    </div>

  </div>
</template>

<script>
import BaseTopCards from "../../components/card/BaseTopCards";
import BaseTable from "../../components/table/BaseTable";
import { PrinterIcon, PlusIcon } from 'vue-feather-icons';
import CreateExpenseModal from "@/components/modal/CreateExpenseModal";
import {mapState} from 'vuex'

export default {
  name: 'Loan',
  data() {
    return {
      showModal: true,
      model:{
        expenseCode: '',
        expenseEmployerId: '',
        expenseGroup: 'DEFAULT',
        expenseHeadId: "",
        expenseName: ''
      }
      ,
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'expenseReference', label: 'Reference', sortable: true, sortDirection: 'desc' },
        { key: 'expenseDescription', label: 'Description', sortable: true, class: 'text-left' },
        { key: 'expenseCost', label: 'Amount', sortable: true, class: 'text-right' },
        { key: 'expenseStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'expenseCreatedAt', label: 'Created Date', sortable: true, class: 'text-left' },
        { key: 'actionsExpense', label: 'Action', sortable: true, class: 'text-center' }
      ],
    }
  },
  components: {
    PlusIcon,
    BaseTable,
    BaseTopCards,
    PrinterIcon,
    CreateExpenseModal,
  },
  computed: {
    cards:{
      get(){
        return [
          {
            id: 0,
            name: 'Total Expenses',
            number: this.expenses.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
            color: 'bg-primary'
          },
          {
            id: 1,
            name: 'Approved Expenses',
            number: this.expenses.filter(it => it.expenseStatus.toUpperCase() === 'APPROVED').length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
            color: 'bg-orange'
          },
          {
            id: 2,
            name: 'Pending Expenses',
            number: this.expenses.filter(it => it.expenseStatus.toUpperCase() === 'PENDING').length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
            color: 'bg-success'
          },
          {
            id: 3,
            name: 'Paid Expenses',
            number: this.expenses.filter(it => it.expenseStatus.toUpperCase() === 'PAID').length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
            color: 'bg-black-8'
          }
        ]
      },
      set(){

      }
    },
    expenses(){
      return  this.expense.expenses
    },
    authobj: function () {
      return this.$store.state.auth.response
    },
    employees(){
      return  this.employee.employees?this.employee.employees:[]
    },
    ...mapState([
      'employee',
      'expense',
      'auth'
    ])
  },
  methods: {
    async createExpense(){
      this.model.expenseEmployerId = localStorage.orgId
      await this.$store.dispatch("expense/createExpense", this.model, {root: false})
      this.loadExpense();
      this.hide();
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });

    },
    loadExpense(){
      this.$store.dispatch("expense/updateExpenses", {
        readAll:'NO',
        employerId: localStorage.orgId,
        employeeId: localStorage.orgId
      }, {root: false}).then()
    },
    hide() {
      this.$refs['expense-modal'].hide()
    },
    numberWithCommas(x) {
      let parts = x.toString().split(".");
      if (parts[1] == null)  parts[1] = '00'
      else if (parts[1].length === 0) parts[1] = parts[1]+'00'
      else if (parts[1].length === 1) parts[1] = parts[1]+'0'
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
  created() {
    this.$store.dispatch("expense/updateExpenses", {
      readAll:'NO',
      employerId: localStorage.orgId,
      employeeId: localStorage.orgId
    }, {root: false}).then()
  }
}


</script>

<style scoped>

</style>
