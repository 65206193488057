<template>
    <b-modal id="grade-salary-edit-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="grade-salary-edit-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4>Edit grade salary</h4>
                <b-form @submit.prevent="createGradeSalary" enctype="multipart/form-data">
                    <b-form-group label="Salary Item">
                        <b-input required v-model="model.employerGradeSalaryItem"/>
                    </b-form-group>
                    <b-form-group label="Amount">
                        <b-input type="number" step="0.01" required v-model="model.employerGradeSalaryItemAmount"/>
                    </b-form-group>
                    <b-form-group label="Serial">
                        <b-input type="number"  required v-model="model.employerGradeSalaryItemSerial"/>
                    </b-form-group>
                    <b-form-group label="Exempt Tax">
                        <b-select required v-model="model.employerGradeSalaryItemTaxExempt">
                            <b-select-option  value="YES">YES</b-select-option>
                            <b-select-option  value="NO">NO</b-select-option>
                        </b-select>
                    </b-form-group>
                    <b-form-group label="Debit Account">
                      <b-select v-model="model.employerGradeSalaryDebitAccount">
                        <b-select-option value="">Choose salary account</b-select-option>
                        <b-select-option v-for="account in modelAccounts" :value="account.accountNumber" v-bind:key="account.accountNumber">{{account.accountNumber}} - {{account.accountBankName}}</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Type">
                        <b-select required v-model="model.employerGradeSalaryItemType">
                            <b-select-option  value="EARNINGS">EARNINGS</b-select-option>
                            <b-select-option  value="DEDUCTIONS">DEDUCTIONS</b-select-option>
                        </b-select>
                    </b-form-group>
<!--                    <b-form-group label="Status">-->
<!--                        <b-select required v-model="model.employerGradeSalaryItemType">-->
<!--                            <b-select-option  value="ACTIVE">ACTIVE</b-select-option>-->
<!--                            <b-select-option  value="DELETED">DELETE</b-select-option>-->
<!--                        </b-select>-->
<!--                    </b-form-group>-->
                    <base-button title="Submit" :loading="grade.gradeLoading"/>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row'],
        components:{BaseButton},
        name: 'ConfirmModal',
        data(){
            return{
                password: '',
                model:{
                    employerGradeSalaryEmployerId: '',
                    employerGradeSalaryGradeCode: '',
                    employerGradeSalaryItem: '',
                    employerGradeSalaryDebitAccount: '',
                    employerGradeSalaryItemAmount: '',
                    employerGradeSalaryItemSerial: '',
                    employerGradeSalaryItemTaxExempt: 'YES',
                    employerGradeSalaryItemType: 'EARNINGS'
                },
            }
        },
        methods:{
            hide() {
                this.$refs['grade-salary-edit-modal'].hide()
            },
            async createGradeSalary(){
                this.model.employerGradeSalaryGradeCode = this.gradeCode();
                //console.log(this.model.employerGradeSalaryGradeCode)
                this.model.employerGradeSalaryMaker = `${this.auth.userInfo.firstName} ${this.auth.userInfo.lastName}`;
                this.model.employerGradeSalaryEmployerId = localStorage.orgId;
                await this.$store.dispatch("grade/editGradeSalary", this.model, {root: false});
                this.loadGrade();
                this.hide();
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            loadGrade(){
                this.$store.dispatch("grade/updateGradeSalaries", {
                    readAll:'NO',
                    employerGradeSalaryEmployerId: localStorage.orgId,
                    employerGradeSalaryGradeCode: localStorage._gradeCode
                }, {root: false}).then()
            },
            hideauth: function () {
                this.password = '';
                this.$refs['grade-salary-edit-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row
        },
        computed:{
            ...mapState(['grade','auth']),
            modelAccounts:{
              get(){
                console.log(this.$store.getters['auth/activeCompany'].accounts);
                return this.$store.getters['auth/activeCompany'].accounts
              },
              // set(val){
              //     this.model.accounts = val uploadModel
              // }
            },
        },
        watch: {
            show: function () {
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                this.$refs['grade-salary-edit-modal'].show()
            }
        },
    }
</script>
