<template>
    <b-modal id="edit-sla-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="edit-sla-modal">
      <div class="modal-body">
        <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
           @click="hide()">
          <span aria-hidden="true">&times;</span>
        </a>

        <div>

          <h4>Edit SLA</h4>
          <b-form @submit.prevent="editSla" enctype="multipart/form-data">
            <!--            <b-form-group label="Staff Id">-->
            <!--              <b-input-group>-->
            <!--                <b-input required placeholder="Staff Id" v-model="model.employeeStaffId"/>-->
            <!--                <the-mask placeholder="BVN" required v-model="model.employeeBvn" maxlength="11" minlength="11" :mask="['###########', '###########']" class="form-control" />-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->
            <b-form-group label="SLA Code">
              <b-input-group>
                <b-input type="text" placeholder="SLA Code" required v-model="model.slaCode"/>
                <!--                <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>-->
                <!--                <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>-->
              </b-input-group>
            </b-form-group>


            <!--            <b-form-group label="SLA Resource Code">-->
            <!--              <b-input-group>-->
            <!--                <b-input type="text" placeholder="SLA Resource Code" required v-model="model.slaResourceCode"/>-->
            <!--                &lt;!&ndash;                <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>&ndash;&gt;-->
            <!--                &lt;!&ndash;                <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>&ndash;&gt;-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->

            <b-form-group label="SLA Resource Code">
              <b-select v-model="model.slaResourceCode" required placeholder="SLA Resource Code">
                <b-select-option :value="employee.employeeDetailStaffId"  v-for="(employee, index) in employee.employees" :key="index">{{employee.employeeFirstName}} {{employee.employeeLastName}}: {{ employee.employeeDetailStaffId }}</b-select-option>
                <!--                <b-select-option value="Type 2">Type 2</b-select-option>-->
              </b-select>
            </b-form-group>

            <b-form-group label="SLA Client Code">
              <b-select v-model="model.slaClientCode" required placeholder="SLA Client Code">
                <b-select-option :value="client.clientCode"  v-for="(client, index) in client.clients" :key="index">{{client.clientName}}:{{ client.clientCode }}</b-select-option>
                <!--                <b-select-option value="Type 2">Type 2</b-select-option>-->
              </b-select>
            </b-form-group>




            <!--            <b-form-group label="SLA Client Code">-->
            <!--              <b-input-group>-->
            <!--                <b-input type="text" placeholder="SLA Client Code" required v-model="model.slaClientCode"/>-->
            <!--                &lt;!&ndash;                <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>&ndash;&gt;-->
            <!--                &lt;!&ndash;                <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>&ndash;&gt;-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->

            <b-form-group label="SLA Day">
              <b-input type="number" placeholder="Enter SLA Day" required v-model="model.slaDay"/>
            </b-form-group>

            <!--            <b-form-group label="SLA Type">-->
            <!--              <b-input type="number" placeholder="Enter SLA Type" required v-model="model.slaType"/>-->
            <!--            </b-form-group>-->


            <b-form-group label="SLA Type">
              <b-select v-model="model.slaType">
                <b-select-option value="">Select Type</b-select-option>
                <b-select-option value="Type 1">Type 1</b-select-option>
                <b-select-option value="Type 2">Type 2</b-select-option>
              </b-select>
            </b-form-group>



            <b-form-group label="SLA Hourly Rate">
              <b-input-group>
                <b-input type="number" step="0.01" placeholder="SLA Hourly Rate" required v-model="model.slaHourlyRate"/>
                <!--                <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>-->
                <!--                <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>-->
              </b-input-group>
            </b-form-group>

            <div class="form-flex">
              <div class="form-flex-c">
                <b-form-group label="SLA Start Date">

                  <!--                <vue-country-code @onSelect="onSelect" :preferredCountries="['vn', 'us', 'gb']">-->
                  <!--                </vue-country-code>-->
                  <b-input type="date"  placeholder="SLA Start Date" required v-model="model.slaStartDate"/>
                </b-form-group>
              </div>
              <div class="form-flex-c">
                <b-form-group label="SLA End Date">
                  <b-input type="date" placeholder="SLA End Date" required v-model="model.slaEndDate"/>
                </b-form-group>
              </div>
            </div>

            <div class="form-flex">
              <div class="form-flex-c">
                <b-form-group label="SLA Hour Start">

                  <!--                <vue-country-code @onSelect="onSelect" :preferredCountries="['vn', 'us', 'gb']">-->
                  <!--                </vue-country-code>-->
                  <b-input type="number"  placeholder="SLA Hour Start" required v-model="model.slaHourStart" />
                </b-form-group>
              </div>
              <div class="form-flex-c">
                <b-form-group label="SLA Hour End">
                  <b-input type="number" placeholder="SLA Hour End" required v-model="model.slaHourEnd" />
                </b-form-group>
              </div>


            </div>

            <!--            <b-form-group label="Client Phone Number">-->
            <!--              <b-input-group>-->
            <!--&lt;!&ndash;                <vue-country-code @onSelect="onSelect" :preferredCountries="['vn', 'us', 'gb']">&ndash;&gt;-->
            <!--&lt;!&ndash;                </vue-country-code>&ndash;&gt;-->
            <!--                <b-input type="number" placeholder="SLA Day" required v-model="model.slaDay"/>-->
            <!--                <b-input type="text" placeholder="SLA Type" required v-model="model.slaType"/>-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->
            <!--            <b-form-group label="ID Card">-->
            <!--              <b-input-group>-->
            <!--                <b-select v-model="model.employeeIdType">-->
            <!--                  <b-select-option value="">Select ID Type</b-select-option>-->
            <!--                  <b-select-option value="NATIONAL ID">NATIONAL ID</b-select-option>-->
            <!--                  <b-select-option value="INTERNATIONAL PASSPORT">INTERNATIONAL PASSPORT</b-select-option>-->
            <!--                  <b-select-option value="VOTERS CARD">VOTERS CARD</b-select-option>-->
            <!--                  <b-select-option value="DRIVER LICENCE">DRIVER LICENCE</b-select-option>-->
            <!--                  <b-select-option value="OTHERS">OTHERS</b-select-option>-->
            <!--                </b-select>-->
            <!--                <b-input type="text" placeholder="ID NUMBER" required v-model="model.employeeIdNumber"/>-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->
            <!--            <b-form-group label="Country">-->
            <!--              <country-select id="orgCountry" class="form-control" :countryName="true" :regionName="true" v-model="model.clientCountry" :country="model.clientCountry" topCountry="NG" />-->
            <!--            </b-form-group>-->



            <!--            <b-form-group label="Date of birth">-->
            <!--              <b-input-group>-->
            <!--                <b-input type="date" placeholder="Date of birth" required v-model="model.employeeDob"/>-->
            <!--                <b-select v-model="model.employeeGender">-->
            <!--                  <b-select-option value="">Select Gender</b-select-option>-->
            <!--                  <b-select-option value="MALE">MALE</b-select-option>-->
            <!--                  <b-select-option value="FEMALE">FEMALE</b-select-option>-->
            <!--                </b-select>-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->

            <!--            <b-form-group label="Employment Details">-->
            <!--              <b-input-group>-->
            <!--                <b-select v-model="model.employeeDepartment">-->
            <!--                  <b-select-option value="">Select Department</b-select-option>-->
            <!--                  <b-select-option v-for="dpt in department.departments" :value="dpt.employerDepartmentCode" v-bind:key="dpt.employerDepartmentCode">{{dpt.employerDepartmentName}}</b-select-option>-->
            <!--                </b-select>-->
            <!--                <b-select v-model="model.employeeGradeCode">-->
            <!--                  <b-select-option value="">Select Grade</b-select-option>-->
            <!--                  <b-select-option v-for="grd in grade.grades" :value="grd.employerGradeCode" v-bind:key="grd.employerGradeCode">{{grd.employerGradeName}}</b-select-option>-->
            <!--                </b-select>-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->

            <!--            <b-form-group label="Contact Person">-->
            <!--              <b-input type="text" placeholder="Contact Person" required v-model="model.clientContactPerson"/>-->
            <!--            </b-form-group>-->
            <b-form-group label="SLA Status">
              <b-select v-model="model.slaStatus">
                <b-select-option value="">Select Type</b-select-option>
                <b-select-option value="ACTIVE">Active</b-select-option>
                <b-select-option value="PENDING">Pending</b-select-option>
                <b-select-option value="SUSPENDED">Suspended</b-select-option>
              </b-select>
            </b-form-group>
            <base-button title="Submit" :loading="sla.slaLoading"/>
          </b-form>
        </div>
      </div><!-- modal-body -->
    </b-modal>
  </template>

  <script>



    import ClientRequest from "@/model/request/ClientRequest";
    import StoreUtils from "@/utils/BaseUtils/StoreUtils";
    import { mapState } from "vuex";
    import BaseButton from "@/components/button/BaseButton";

    export default {
      name: "EditSLAModal",
      props:['show','row'],
      components: {BaseButton},
      data(){
        return{
          model: ClientRequest.create,
          editModel: ClientRequest.edit
        }
      },
      computed:{
        ...mapState(['sla', 'employee', 'client'])
      },
      methods:{
        hide() {
          this.$refs['edit-sla-modal'].hide()
        },
        async editSla(){
          this.model.clientCompanyCode = localStorage.orgId;
          await StoreUtils.dispatch(StoreUtils.actions.client.UPDATE_SLA, this.model)
          this.hide();
        }
      },
      watch: {
        show: function () {
          let self = this;
          // eslint-disable-next-line no-unused-vars
          Object.keys(this.model).forEach(function(key,index) {
            self.model[key] = '';
          });
          // this.$store.dispatch('expense/updateExpenseCategory',{readAll:"YES"})
          // eslint-disable-next-line no-unused-vars
          Object.keys(this.row).forEach(function(key,index) {
            self.model[key] = self.row[key];
          });
          this.$refs['edit-sla-modal'].show()
        }
      },

    }
  </script>

<style scoped>

</style>