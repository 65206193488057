import apiClient from "./BaseService";

export default {
    callCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("departmentCreate", payload);
    },
    callReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("departmentRead", payload);
    },
};
