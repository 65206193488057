<template>
    <b-modal id="skill-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="skill-create-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4 v-if="model.type === 'edit'">Edit Skill</h4>
                <h4 v-else>Add New Skill</h4>
                <b-form @submit.prevent="submit" enctype="multipart/form-data">
                    <b-form-group label="Skill Name">
                        <b-input required v-model="model.skillName"/>
                    </b-form-group>
                    <b-form-group label="Effective Date">
                        <b-input type="date" required v-model="model.skillSkillEffectiveDate"/>
                    </b-form-group>
                    <b-form-group v-if="model.type === 'edit'" label="Status">
                      <b-select v-model="model.skillStatus">
                        <b-select-option value="ACTIVE">Active</b-select-option>
                        <b-select-option value="DELETED">Delete</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Category">
                        <b-select required v-model="model.skillSkillCategoryId">
                            <b-select-option v-for="category in employee.skillCategories" v-bind:key="category.skillCategoryId"
                                             :value="category.skillCategoryId">
                              {{ category.skillCategoryName }}</b-select-option>
                        </b-select>
                    </b-form-group>
<!--                    <b-form-group label="Status">-->
<!--                        <b-select required v-model="model.employerGradeSalaryItemType">-->
<!--                            <b-select-option  value="ACTIVE">ACTIVE</b-select-option>-->
<!--                            <b-select-option  value="DELETED">DELETE</b-select-option>-->
<!--                        </b-select>-->
<!--                    </b-form-group>-->
                    <base-button title="Submit" :loading="employee.employeeLoading"/>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row'],
        components:{BaseButton},
        name: 'ConfirmModal',
        data(){
            return{
                password: '',
                model:{
                  skillName: '',
                  skillStatus: '',
                  skillEmployeeId: '',
                  skillSkillEffectiveDate: '',
                  skillSkillCategoryId: '',
                },
            }
        },
        methods:{
            hide() {
                this.$refs['skill-create-modal'].hide()
            },
            async submit(){
                if (this.model.type === 'edit')
                  await this.$store.dispatch("employee/editSkill", this.model, {root: false});
                else {
                  this.model.skillEmployeeId = this.row.employeeId
                  await this.$store.dispatch("employee/createSkill", this.model, {root: false});
                }
                this.hide();
                this.$store.dispatch('employee/readSkills', {
                  readAll: 'NO',
                  skillEmployeeId: this.row.employeeId?this.row.employeeId:this.model.skillEmployeeId
                })
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            loadGrade(){
                this.$store.dispatch("grade/updateGradeSalaries", {
                    readAll:'NO',
                    employerGradeSalaryEmployerId: localStorage.orgId,
                    employerGradeSalaryGradeCode: localStorage._gradeCode
                }, {root: false}).then()
            },
            hideauth: function () {
                this.password = '';
                this.$refs['skill-create-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row readSkillCategory
          this.$store.dispatch('employee/readSkillCategory',{readAll:"YES"})
        },
        computed:{
            ...mapState(['grade','auth','employee']),
        },
        watch: {
            show: function () {
                let self = this;
              // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                this.$refs['skill-create-modal'].show()
            }
        },
    }
</script>
