<template>
    <div class="row">
        <div class="col-md-6 mg-t-10">
            <div class="card ht-100p">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <h6 class="mg-b-0">Logo</h6>
                    <div class="d-flex tx-18">
                        <edit-icon style="cursor: pointer;" @click="showModal"/>
                    </div>
                </div>
                <b-img :src="model.orgLogo" alt="logo" style="width: 150px; height: 150px;"/>
<!--                <edit-icon style="position: absolute;" />-->
<!--                <div class="card-footer text-center tx-13">-->
<!--                    <a href="/customer/sendmoney" class="link-03">Go Back <i class="icon ion-md-arrow-down mg-l-5"></i></a>-->
<!--                </div>&lt;!&ndash; card-footer &ndash;&gt;-->
            </div><!-- card -->
        </div>

        <!--  TODO OTHER SETTINGS -->

        <div class="col-md-6 mg-t-10">
            <div class="card ht-100p">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <h6 class="mg-b-0">Salary Account</h6>
                    <div class="d-flex tx-18">
                    <b-icon-plus-square @click="createAccount" style="cursor: pointer;"/>
                    </div>
                </div>
                <p v-if="this.auth.userInfo.companies.data == null" align="center"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>
                <form v-else class="p-xl-2" style="padding: 10px;"  @submit.prevent="saveSettings()">
                    <div class="position-relative form-group">
                        <span v-if="accountEdit">
                            <label>Choose salary account:</label>
                            <b-select v-model="model.employerSalaryAccount">
                                <b-select-option value="">Choose salary account</b-select-option>
                                <b-select-option v-for="account in modelAccounts" :value="account.accountNumber" v-bind:key="account.accountNumber">{{account.accountNumber}} - {{account.accountBankName}}</b-select-option>
                            </b-select>
                            <br>
                            <br>
                            <label>Run Payroll Automatically:</label><br>
                            <b-select v-model="model.employerPayrollAuto">
                                <b-select-option value="YES">YES</b-select-option>
                                <b-select-option value="NO">NO</b-select-option>
                            </b-select>
                        </span>
                        <span v-else>
                            <label>Salary account:</label><br>
                            <span v-if="model.employerSalaryAccount == null || model.employerSalaryAccount === ''" style="color: #4e4e4e; font-style: italic;">Click on edit to add salary account</span>
                            <span v-else>
                                <span>{{model.employerSalaryAccount}} - {{modelAccounts.filter(it => it.accountNumber === model.employerSalaryAccount )[0].accountBankName}}; </span>
                            </span><br><br>
                            <label>Run Payroll Automatically:</label><strong> {{model.employerPayrollAuto}}</strong>
                        </span>
                    </div>
                    <base-button v-if="accountEdit" title="Submit" :loading="settings.settingLoading"/>
                    <button v-else @click="accountEdit = !accountEdit" class="btn btn-brand-02 btn-block">Edit</button>
                </form>
            </div><!-- card -->
        </div>

        <!--  TODO CHANGE PASSWORD SETTINGS -->
        <upload-image-modal
                :upload-data="uploadModel"
                :read-data="auth.userInfo"
                :show="showUpload"
                :is-loading="settings.settingLoading"
                read-type="auth/initiateEmployer"
                upload-type="settings/uploadLogo"
        />

    </div><!-- row -->
</template>

<script>
    // import FlotChart from "../FlotChart";
    import UploadImageModal from "../../components/modal/UploadImageModal";
    import {mapState} from 'vuex'
    import BaseButton from "../button/BaseButton";
    import {EditIcon} from "vue-feather-icons"
    import Swal from "sweetalert2";
    export default {
        name: "TopCards",
        components:{
            BaseButton,
            UploadImageModal,
            EditIcon
        },
        data(){
            return{
                model:{
                    orgLogo: '',
                    employerId: '',
                    employerName: '',
                    employerType: '',
                    employerIndustry: '',
                    employerWebsite: '',
                    employerEmail: '',
                    employerPhoneNumber: '',
                    employerRegDate: '',
                    employerRegId: '',
                    employerSalaryAccount: '',
                    employerSalaryBank: '',
                    employerMaker: '',
                    employerStatus: '',
                    employerEnrollmentDate: '',
                    employerCreatedAt: '',
                    employerUpdatedAt: '',
                    employerLogo: '',
                    employerPayrollAuto: '',
                    responseCode: '',
                    responseMessage: ''
                },
                uploadModel:{
                    employerId:'',
                    employerName:''
                },
                readModel:{
                    readAll:'NO',
                    employerId: ''
                },
                showUpload:true,
                limitEdit: false,
                passwordEdit: false,
                accountEdit: false,
            }
        },
        watch:{
          model: function () {
              console.log("hery")
              this.filterOutBadChar()
          }
        },
        methods:{
            filterOutBadChar(){
                if (this.model.orgAuthorizationLevel.substr(0,1) === '0')
                    this.model.orgAuthorizationLevel =this.model.orgAuthorizationLevel.substr(1,this.model.orgAuthorizationLevel.length)
                this.model.orgAuthorizationLevel = this.model.orgAuthorizationLevel.replace('.', '')
                if (this.model.orgAuthorizationLevel === '') this.model.orgAuthorizationLevel = 0;
                console.log(this.model.orgAuthorizationLevel)
            },
            async saveLimit(){
                this.model.orgId = localStorage.orgId;
                await this.$store.dispatch("company/setCompanyLimit", this.model, {root: true})
                this.loadSettings()
                this.limitEdit = !this.limitEdit
            },
            async savePassword(){
                if(this.minLength !== 'green'||
                    this.maxLength  !== 'green'||
                    this. hasLettersNumbers !== 'green' ||
                    this.hasSpecialXters !== 'green' ||
                    this.hasLowerCase !== 'green' ||
                    this.hasUpperCase !== 'green' ||
                    this.hasSpace !== 'green'
                ) return;
                this.model.orgId = localStorage.orgId;
                this.model.userId = this.auth.userInfo.id;
                await  this.$store.dispatch("auth/changePassword", this.model, {root: true})
                this.model.oldPassword =  '';
                this.model.password =  '';
                this.model.repeatPassword =  '';
                this.passwordEdit = !this.passwordEdit
            },
            async saveSettings(){
                this.model.orgId = localStorage.orgId;
                this.model.employerId = localStorage.orgId;
                await this.$store.dispatch("settings/updateEmployerAccount", this.model, {root: true})
                this.$store.dispatch("auth/initiateEmployer", this.auth.userInfo, {root: true})
                this.loadSettings()
                this.accountEdit = !this.accountEdit
            },
            showModal(){
                //localStorage._counterparty = _id;
                this.readModel.employerId = localStorage.orgId;
                this.uploadModel.employerId = localStorage.orgId;
                this.uploadModel.employerName = this.$store.getters['auth/activeCompany'].orgName+this.uuidv4();
                this.showUpload = !this.showUpload
            },
            uuidv4() {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            },
            async loadSettings(){
                // await this.$store.dispatch('settings/updateSettings', {
                //     employerId: localStorage.orgId,
                //     readAll: 'NO'
                // });
                let self = this;
                // console.log(this.auth.employer)
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.auth.employer).forEach(function(key,index) {
                    self.model[key] = self.auth.employer[key];
                    console.log(self.model[key])
                });
            },

            async createAccount(){
            // if (_status.includes('delete')) status = 'delete';
            let errorMessage = ''
            this.$swal({
              text: `Are you sure you want to create a new account?`,
              showCancelButton: true,
              confirmButtonText: 'Confirm',
              showLoaderOnConfirm: true,
              preConfirm: () => {
                let payload = {
                  userId: this.auth.userInfo.id,
                  orgId: localStorage.orgId
                }
                let endpoint = 'settings/createNewBusinessAccount'
                console.log(endpoint,payload)
                return this.$store.dispatch(endpoint, payload)
                    .then(response => {
                      console.log(response.data)
                      if (response.data.responseCode !== '00') {
                        errorMessage = response.data.responseMessage
                        throw new Error(response.data.responseMessage)
                      }
                      return response
                    }).catch(error => {
                      console.log(error)
                      Swal.showValidationMessage(
                          `Request failed: ${errorMessage}`
                      )
                    })
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              console.log(result.value)
              if (result.value.data.responseCode === '00') {
                let payload = {userId: this.auth.userInfo.id, requestType: 'company'}
                let endpoint = 'auth/readCompany'
                this.$store.dispatch(endpoint, payload , {root: false})
                this.$store.dispatch("auth/initiateEmployer", this.auth.userInfo, {root: true})
                this.loadSettings()
                this.$swal(
                    'Success',
                    result.value.data.responseMessage,
                    'success'
                ).then(()=>{this.$router.push('/preloader')})
              }
            })
          },
        },
        computed:{
            ...mapState([
                'settings',
                'auth'
            ]),
            minLength:function(){
                return this.model.password.length >= 8?'green':'red'
            },
            maxLength:function(){
                return this.model.password.length <= 13?'green':'red'
            },
            hasLettersNumbers:function(){
                return ( /[a-zA-Z]/g.test(this.model.password) && /[0-9]/g.test(this.model.password))?'green':'red'
            },
            hasSpecialXters:function(){
                return /[^a-zA-Z0-9]/g.test(this.model.password)?'green':'red'
            },
            hasLowerCase:function(){
                return /[a-z]/g.test(this.model.password)?'green':'red'
            },
            hasUpperCase:function(){
                return /[A-Z]/g.test(this.model.password)?'green':'red'
            },
            hasSpace:function(){
                return !(/\s/g.test(this.model.password))?'green':'red'
            },
            // accounts: function () {
            //     console.log(this.$store.getters['auth/activeCompany']);
            //     let acc = [];
            //     if (this.$store.getters['auth/activeCompany'] != null)
            //     for (let i = 0; i < this.$store.getters['auth/activeCompany'].accounts.length; i++){
            //         acc.push(this.$store.getters['auth/activeCompany'].accounts[i].accNumber)
            //     }
            //     return acc
            // },
            modelAccounts:{
                get(){
                    console.log(this.$store.getters['auth/activeCompany'].accounts);
                    return this.$store.getters['auth/activeCompany'].accounts
                },
                // set(val){
                //     this.model.accounts = val uploadModel
                // }
            }
        },
        async mounted() {
            // await this.$store.dispatch('settings/updateSettings', {
            //     employerId: localStorage.orgId,
            //     readAll: 'NO'
            // });
            let self = this;
            // console.log(this.auth.employer)
            // eslint-disable-next-line no-unused-vars
            Object.keys(this.auth.employer).forEach(function(key,index) {
                self.model[key] = self.auth.employer[key];
                console.log(self.model[key])
            });

        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 900px) {
        .top-card {
            width: 103%;
        }
    }
    @media only screen and (min-width: 707px) and (max-width: 900px) {
        .top-card {
            width: 104%;
        }
    }

    @media only screen and (min-width: 517px)  and (max-width: 707px) {
        .top-card {
            width: 105%;
        }
    }

    @media only screen and (min-width: 451px)  and (max-width: 517px) {
        .top-card {
            width: 106%;
        }
    }

    @media only screen  and (max-width: 451px) {
        .top-card {
            width: 107%;
        }
    }
</style>