<template>
  <b-modal id="project-task-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="project-task-create-modal">
    <div class="modal-body">
      <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
         @click="hide()">
        <span aria-hidden="true">&times;</span>
      </a>
      <div>
        <h4 v-if="model.type === 'edit'">Edit task</h4>
        <h4 v-else>Add new task</h4>
        <b-form @submit.prevent="submit" enctype="multipart/form-data">
          <b-form-group label="Title">
            <b-input required v-model="model.projectTaskTitle" placeholder="Task Title"/>
          </b-form-group>
          <b-form-group label="Project">
            <b-select required v-model="model.projectTaskProjectId">
              <b-select-option  value="">Select Project</b-select-option>
              <b-select-option v-for="proj in projects()" v-bind:key="proj.projectId" :value="proj.projectId">
                {{ proj.projectTitle }}</b-select-option>
            </b-select>
          </b-form-group>
          <b-form-group label="Resource">
            <b-select required v-model="model.projectTaskResource">
              <b-select-option  value="">Select Project Resource</b-select-option>
              <b-select-option v-for="employee in employees" v-bind:key="employee.employeeId" :value="employee.employeeId">{{employee.employeeFirstName}} {{employee.employeeLastName}}</b-select-option>
            </b-select>
          </b-form-group>
          <b-form-group label="Description">
            <b-textarea placeholder="Project Description"  required v-model="model.projectTaskDescription" ></b-textarea>
          </b-form-group>
          <base-button title="Submit" :loading="project.projectLoading"/>
        </b-form>
      </div>
    </div><!-- modal-body -->
  </b-modal>
</template>
<script>
import { mapState } from "vuex"
import BaseButton from "../button/BaseButton";
export default {
  props:['show','row'],
  components:{BaseButton},
  name: 'ConfirmModal',
  data(){
    return{
      password: '',
      model:{
        projectTaskTitle: '',
        projectTaskProjectId: '',
        projectTaskDescription: '',
        projectTaskEmployerId: '',
        projectTaskResource: ''
      },
    }
  },
  methods:{
    hide() {
      this.$refs['project-task-create-modal'].hide()
    },
    async submit(){
      this.model.projectTaskEmployerId = localStorage.orgId
      if (this.model.type === 'edit')
        await this.$store.dispatch("project/editProjectTask", this.model, {root: false});
      else {
        this.model.taskEmployeeId = this.row.employeeId
        await this.$store.dispatch("project/createProjectTask", this.model, {root: false});
      }
      this.hide();
      this.loadTasks()
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });

    },
    gradeCode(){
      return  localStorage._gradeCode
    },
    projects(){
      return  this.project.projects
    },
    loadProject(){
      this.$store.dispatch('project/updateProjects', {
        readAll: 'YES',
        employer: localStorage.orgId,
        employee: ''
      })
    },
    loadTasks(){
      this.$store.dispatch("project/updateTasks", {
        readAll:'YES',
        employer: localStorage.orgId,
        employee: '',
        projectId: localStorage._projectId
      }, {root: false}).then()
      this.$store.dispatch("project/updateProjectTasks", {
        readAll:'YES',
        employer: localStorage.orgId,
        employee: '',
        projectId: localStorage._projectId
      }, {root: false}).then()
    },
    hideauth: function () {
      this.password = '';
      this.$refs['project-task-create-modal'].hide();
    },
    currentDate(){
      let date = new Date()
      date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
      console.log(date)
      return date
    },
  },
  mounted() {
    //this.model = this.row readSkillCategory
    this.$store.dispatch('employee/readSkillCategory',{readAll:"YES"})
  },
  computed:{
    ...mapState(['grade','auth','employee','project']),
    employees(){
      return  this.employee.employees?this.employee.employees:[]
    },
  },
  watch: {
    show: function () {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });
      console.log(':::::::clicked::::::::')
      this.loadProject()
      this.model.projectTaskProjectId = this.row.projectTaskProjectId
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.row).forEach(function(key,index) {
        self.model[key] = self.row[key];
      });
      this.$refs['project-task-create-modal'].show()
    }
  },
}
</script>
