<template>
    <b-modal id="skill-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="skill-create-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4 v-if="model.type === 'edit'">Edit Expense</h4>
                <h4 v-else>Add New Expense</h4>
                <b-form @submit.prevent="submit" enctype="multipart/form-data">
                    <b-form-group label="Expense Amount">
                        <b-input type="number" step="0.01"  placeholder="Expense Amount" required v-model="model.expenseCost"/>
                    </b-form-group>
                    <b-form-group v-if="model.type !== 'edit'" label="Employee">
                      <b-select required v-model="model.expenseEmployeeId">
                        <b-select-option value="">Select Employee</b-select-option>
                        <b-select-option v-for="employee in employees" v-bind:key="employee.employeeId" :value="employee.employeeId">{{ employee.employeeFirstName }} {{ employee.employeeLastName }}</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Category">
                      <b-select required v-model="model.expenseCategory">
                        <b-select-option value="">Select Expense Category</b-select-option>
                        <b-select-option v-for="category in expense.categories" v-bind:key="category.label" :value="category.value">{{ category.label }}</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Expense Date">
                        <b-input type="date" v-model="model.expenseDate"/>
                    </b-form-group>
                    <b-form-group label="Description">
                      <b-textarea v-model="model.expenseDescription"/>
                    </b-form-group>
                    <b-form-group v-if="model.type === 'edit'" label="Status">
                      <b-select required v-model="model.expenseStatus">
                        <b-select-option value="PENDING">Pending</b-select-option>
                        <b-select-option value="APPROVED">Approve</b-select-option>
                        <b-select-option value="DELETED">Delete</b-select-option>
                      </b-select>
                    </b-form-group>
<!--                    <b-form-group label="Status">-->
<!--                        <b-select required v-model="model.employerGradeSalaryItemType">-->
<!--                            <b-select-option  value="ACTIVE">ACTIVE</b-select-option>-->
<!--                            <b-select-option  value="DELETED">DELETE</b-select-option>-->
<!--                        </b-select>-->
<!--                    </b-form-group>-->
                    <base-button title="Submit" :loading="expense.expenseLoading"/>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row'],
        components:{BaseButton},
        name: 'ConfirmModal',
        data(){
            return{
                password: '',
                model:{
                  expenseId: '',
                  expenseEmployeeId: '',
                  expenseCost: '',
                  expenseDate: '',
                  educationDegree: '',
                  expenseDescription: '',
                  expenseCategory: '',
                  expenseStatus: ''
                },
            }
        },
        methods:{
            hide() {
                this.$refs['skill-create-modal'].hide()
            },
            async submit(){
                this.model.expenseEmployerId = localStorage.orgId
                if (this.model.type === 'edit')
                  await this.$store.dispatch("expense/editExpense", this.model, {root: false});
                else {
                  //this.model.expenseEmployeeId = this.row.employeeId
                  await this.$store.dispatch("expense/createExpense", this.model, {root: false});
                }
                this.hide();
                this.$store.dispatch('expense/updateExpenses', {
                  readAll: 'NO',
                  employerId: localStorage.orgId,
                  employeeId: localStorage.orgId
                })
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            loadGrade(){
                this.$store.dispatch("grade/updateGradeSalaries", {
                    readAll:'NO',
                    employerGradeSalaryEmployerId: localStorage.orgId,
                    employerGradeSalaryGradeCode: localStorage._gradeCode
                }, {root: false}).then()
            },
            hideauth: function () {
                this.password = '';
                this.$refs['skill-create-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row readSkillCategory
          this.$store.dispatch('employee/readSkillCategory',{readAll:"YES"})
        },
        computed:{
            ...mapState(['grade','auth','employee','expense']),
          employees(){
            return  this.employee.employees?this.employee.employees:[]
          },
        },
        watch: {
            show: function () {
                let self = this;
              // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                this.$store.dispatch('expense/updateExpenseCategory',{readAll:"YES"})
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                this.$refs['skill-create-modal'].show()
            }
        },
    }
</script>
