import Calender from "../../views/payroll/Calender";
import VariablePayment from "../../views/payroll/VariablePayment";
import Payslips from "../../views/payroll/Payslips";

export default [
    {
        path: '/calenders',
        name: 'Calender',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Calender,
    },
    {
        path: '/variable-payments',
        name: 'VariablePayment',
        meta: {layout: 'on-boarding',authRequired:true},
        component: VariablePayment,
    },
    {
        path: '/payslips',
        name: 'Payslips',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Payslips,
    },
];
