


import ClientRequest from "@/model/request/ClientRequest";
import ClientService from "@/service/ClientService";
import swal from "sweetalert";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
// import StoreUtils from "@/utils/BaseUtils/StoreUtils";
// import StoreUtils from "@/utils/BaseUtils/StoreUtils";

export const state = {
    clients: [],
    clientLoading: false
};

export const getters = {

};

export const mutations = {
    updateClients: (state, payload) =>{
        state.clients = payload
    },
    updateClientLoading: (state, payload) =>{
        state.clientLoading = payload
    }
};

export const actions = {
    createClient({commit}, payload = ClientRequest.create){
       commit("updateClientLoading", true)
        return ClientService.callCreateApi(payload).then(response => {
            commit("updateClientLoading", false)
            let responseData = response.data
            if (responseData.responseCode === "00") {
                swal('Success!', responseData.responseMessage, 'success')
                StoreUtils.dispatch(StoreUtils.actions.client.READ_BY_COMPANY_CODE).then()
            }else{
                swal('Error!', responseData.responseMessage, 'error')
            }
        })
            .catch(error => {
                commit("updateClientLoading", false)
                alert(error)
                // throw error;
            });
        },
    editClient({commit},payload = ClientRequest.edit){
        commit("updateClientLoading", true)
        return ClientService.callUpdateApi(payload).then(response => {
            commit("updateClientLoading", false)
            let responseData = response.data
            if(responseData.responseCode === "00"){
                swal('Success!', responseData.responseMessage, 'success')
                StoreUtils.dispatch(StoreUtils.actions.client.READ_BY_COMPANY_CODE).then()
            }
            else{
                swal('Error!', responseData.responseMessage, 'error')
            }
        })
            .catch(error => {
                commit("updateClientLoading", false)
                alert(error)
                // throw error;
            });
    },



    readClientByCompanyCode({commit}, payload = ClientRequest.readByCompanyCode){
        commit("updateClientLoading", true)
        return ClientService.callReadByCompanyCode(payload).then(response => {
            commit("updateClientLoading", false)
            let responseData = response.data
            if (responseData.responseCode === "00") {
                commit("updateClients", responseData.data)
                // swal('Success!', responseData.responseMessage, 'success')
            }else{
                // swal('Error!', responseData.responseMessage, 'error')
            }
        })
            .catch(error => {
                commit("updateClientLoading", false)
                alert(error)
                // throw error;
            });
    },
};