<template>
  <div class="content ht-100v pd-0">
    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">Tasks</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">All Tasks</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">View All Tasks For {{projectName}}</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
          <!--                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLoginDate | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
        </div>
        <div class="d-none d-md-block">
          <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"  @click="showModal = !showModal"><plus-icon class="wd-10 mg-r-5" /> Add Task</button>
          <download-excel
              :data="tasks"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
          </download-excel>
          <download-excel
              type="csv"
              :data="tasks"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
          </download-excel>

        </div>
      </div>
      <base-top-cards :carditems="cards"/>
      <hr>

      <base-table
          :items="tasks"
          :fields="fields"
          :show-title="true"
          :is-busy="project.projectLoading"
          class="mt-2"
          filter-mode="balances"
      ></base-table>
    </div>
    <create-project-task-modal :show="showModal" :row="{projectTaskProjectId: projectId}"/>
  </div>
</template>

<script>
import BaseTopCards from "../../components/card/BaseTopCards";
import BaseTable from "../../components/table/BaseTable";
// import BaseButton from "../../components/button/BaseButton";
import CreateProjectTaskModal from "@/components/modal/CreateProjectTaskModal";
import { PrinterIcon, PlusIcon } from 'vue-feather-icons'
import {mapState} from 'vuex'

export default {
  name: 'Loan',
  data() {
    return {
      showModal: true,
      model:{
        taskCode: '',
        taskName: '',
        taskEmployerId: '',
        taskMaker: '',
        taskSalaryDay: '',
        taskSalaryPeriod: 'MONTHLY',
        taskType: 'JUNIOR'
      },
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'projectTaskTitle', label: 'Task Title', sortable: true, sortDirection: 'desc' },
        { key: 'projectTaskProjectTitle', label: 'Project Title', sortable: true, class: 'text-left' },
        { key: 'projectTaskResourceName', label: 'Resource', sortable: true, class: 'text-left' },
        { key: 'projectTaskDescription', label: 'Description', sortable: true, class: 'text-left' },
        { key: 'projectTaskStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'projectTaskCreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
        { key: 'actionsTask', label: 'Action', sortable: true, class: 'text-center' }
      ],
    }
  },
  components: {
    PlusIcon,
    BaseTable,
    CreateProjectTaskModal,
    BaseTopCards,
    PrinterIcon
  },
  computed: {
    projectId(){
      return localStorage._projectId
    },
    projectName(){
      return localStorage._projectName
    },
    cards:{
      get(){
        return [
          {
            id: 0,
            name: 'Total Tasks',
            number: this.tasks.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
            color: 'bg-primary'
          },
          {
            id: 1,
            name: 'Request',
            number: this.tasks.filter(it => it.projectTaskStatus.toUpperCase() === 'REQUEST').length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
            color: 'bg-orange'
          },
          {
            id: 2,
            name: 'Assurance',
            number: this.tasks.filter(it => it.projectTaskStatus.toUpperCase() === 'ASSURANCE').length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
            color: 'bg-success'
          },
          {
            id: 3,
            name: 'Completed',
            number: this.tasks.filter(it => it.projectTaskStatus.toUpperCase() === 'COMPLETED').length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
            color: 'bg-black-8'
          }
        ]
      },
      set(){

      }
    },
    tasks(){
      return  this.project.projectTasks
    },
    authobj: function () {
      return this.$store.state.auth.response
    },
    ...mapState([
      'project',
      'auth'
    ])
  },
  methods: {
    async createTask(){
      this.model.taskMaker = `${this.auth.userInfo.firstName} ${this.auth.userInfo.lastName}`
      this.model.taskEmployerId = localStorage.orgId
      await this.$store.dispatch("task/createTask", this.model, {root: false})
      this.loadTask();
      this.hide();
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });

    },
    loadTask(){
      this.$store.dispatch("task/updateTasks", {
        readAll:'NO',
        employerId: localStorage.orgId
      }, {root: false}).then()
    },
    hide() {
      this.$refs['task-modal'].hide()
    },
    numberWithCommas(x) {
      let parts = x.toString().split(".");
      if (parts[1] == null)  parts[1] = '00'
      else if (parts[1].length === 0) parts[1] = parts[1]+'00'
      else if (parts[1].length === 1) parts[1] = parts[1]+'0'
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
  created() {
    this.$store.dispatch("project/updateProjectTasks", {
      readAll:'YES',
      employer: localStorage.orgId,
      employee: '',
      projectId: localStorage._projectId
    }, {root: false}).then()
  }
}
</script>

<style scoped>

</style>
