import apiClient from "./BaseService";

export default {
  callLogonApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquare.post("loginEmployer", payload);
  },
  callInitiateEmployerApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquare.post("initiateEmployer", payload);
  },
  callLogoutApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("logout", payload);
  },
  callInitiateEnrollmentApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("enrollmentInitiate", payload);
  },
  callCompleteEnrollmentApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("enrollmentComplete", payload);
  },
  callCreateCompanyApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("createcompany", payload);
  },
  callReadCompanyApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("readCompanies", payload);
  },
  callInitiateResetPasswordApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.rubies.post("resetpassword", payload);
  },
  callCompleteResetPasswordApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.rubies.post("resetpasswordcomplete", payload);
  },
  callResendOtpApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("resendOtp", payload);
  },
  callVerifyTokenApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("validateUserToken", payload);
  },
  callChangePasswordApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("changePassword", payload);
  },
  callGetUsersApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("userReadAll", payload);
  },
  callRetrieveAuthorisationApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("retrieveAuthorization", payload);
  },
};
