import Grade from "../../views/grade/Grade";
import GradeSalary from "../../views/grade/GradeSalary";

export default [
    {
        path: '/grades',
        name: 'Grade',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Grade,
    },
    {
        path: '/grade-salaries',
        name: 'GradeSalary',
        meta: {layout: 'on-boarding',authRequired:true},
        component: GradeSalary,
    }
];
