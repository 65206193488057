import Conversation from "../../views/conversation/Conversation";

export default [
    {
        path: '/conversations',
        name: 'Conversation',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Conversation,
    }
];
