// import Logon from '../../views/auth/Logon'
// import Register from '../../views/auth/Register'
// import Forgot from '../../views/auth/Forgot'
import MainDashboard from "../../views/dashboard/MainDashboard";
import Preloader from "../../views/auth/Preloader";
import BeforeLoader from "../../views/auth/BeforeLoader";
import PageNotFound from "../../views/auth/PageNotFound";
import Users from "../../views/user/Users";
import PersonalProfile from "../../views/user/PersonalProfile";
import Settings from "../../views/settings/Settings";
// import CreateCompany from "../../views/auth/CreateCompany";

export default [
    {
        path: '/dashboard',
        name: 'MainDashboard',
        meta: {layout: 'auth',authRequired:true},
        component: MainDashboard,
    },
    {
        path: '/',
        name: 'Logon',
        meta: {layout: 'loader',authRequired:false},
        component: Preloader,
    },
    {
        path: '/personal-profile',
        name: 'PersonalProfile',
        meta: {layout: 'on-boarding',authRequired:true},
        component: PersonalProfile,
    },
    {
        path: '/users',
        name: 'Users',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Users,
    },
    {
        path: '/preloader',
        name: 'Preloader',
        meta: {layout: 'loader',authRequired:false},
        component: Preloader,
    },
    {
        path: '/beforeloader',
        name: 'BeforeLoader',
        meta: {layout: 'loader',authRequired:false},
        component: BeforeLoader,
    },
    // {
    //     path: '/register',
    //     name: 'Register',
    //     meta: {layout: 'auth',authRequired:false},
    //     component: Register,
    // },
    // {
    //     path: '/forgot-password',
    //     name: 'Forgot',
    //     meta: {layout: 'auth',authRequired:false},
    //     component: Forgot,
    // },
    // {
    //     path: '/new-company',
    //     name: 'CreateCompany',
    //     meta: {layout: 'auth',authRequired:true},
    //     component: CreateCompany,
    // },
    {
        path: '/settings',
        name: 'Settings',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Settings,
    },
    {
        path: '*',
        name: 'PageNotFound',
        meta: {layout: '404',authRequired:false},
        component: PageNotFound,
    }
];
