<template>
    <b-modal id="salary-details-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="salary-details-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4>Salary details</h4>
                <sub-base-table
                        :items="salaries()"
                        :fields="fields"
                        :show-title="true"
                        :is-busy="row.employeePayslipId != null?payslip.payslipSingleLoading:calender.employeeSingleLoading"
                        class="mt-2"
                        filter-mode="mini"
                ></sub-base-table>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import SubBaseTable from "../table/SubBaseTable";
    export default {
        props:['show','row'],
        components:{
            SubBaseTable
        },
        name: 'ConfirmModal',
        data(){
            return{
                password: '',
                model:{
                    employerGradeSalaryEmployerId: '',
                    employerGradeSalaryGradeCode: '',
                    employerGradeSalaryItem: '',
                    employerGradeSalaryItemAmount: '',
                    employerGradeSalaryItemSerial: '',
                    employerGradeSalaryItemTaxExempt: 'YES',
                    employerGradeSalaryItemType: 'EARNINGS'
                },
                fields: [
                    // { key: 'employerGradeSalaryItemSerial', label: 'Serial', sortable: true, sortDirection: 'desc' },
                    { key: 'employerGradeSalaryItem', label: 'Salary Item', sortable: true, sortDirection: 'desc' },
                    { key: 'employerGradeSalaryItemType', label: 'Type', sortable: true, class: 'text-left' },
                    { key: 'employerGradeSalaryItemAmount', label: 'Amount', sortable: true, class: 'text-right' },
                    { key: 'employerGradeSalaryItemTaxExempt', label: 'Exempt Tax', sortable: true, class: 'text-left'},
                    // { key: 'actionsCalender', label: 'Action', sortable: true, class: 'text-center' }
                ],
            }
        },
        methods:{
            hide() {
                this.$refs['salary-details-modal'].hide()
            },
            salaries(){
                if (this.row.employeePayslipId != null){
                    return this.payslip.payslipSingle?this.payslip.payslipSingle.employeePayslipDetails:[]
                }else{
                    return this.calender.employeeSingle
                }

            },
            async createGradeSalary(){
                this.model.employerGradeSalaryGradeCode = this.gradeCode();
                //console.log(this.model.employerGradeSalaryGradeCode)
                this.model.employerGradeSalaryMaker = `${this.auth.userInfo.firstName} ${this.auth.userInfo.lastName}`;
                this.model.employerGradeSalaryEmployerId = localStorage.orgId;
                await this.$store.dispatch("grade/editGradeSalary", this.model, {root: false});
                this.loadGrade();
                this.hide();
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            loadGrade(){
                this.$store.dispatch("grade/updateGradeSalaries", {
                    readAll:'NO',
                    employerGradeSalaryEmployerId: localStorage.orgId,
                    employerGradeSalaryGradeCode: localStorage._gradeCode
                }, {root: false}).then()
            },
            hideauth: function () {
                this.password = '';
                this.$refs['salary-details-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row
        },
        computed:{
            ...mapState(['grade','calender','auth','payslip']),
        },
        watch: {
            show: function () {
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                let type = 'calender/updateCalenderEmployeeSingle'
                //console.log('this.row.employeePayslipId>>>>>#',this.row.employeePayslipId)
                if (this.row.employeePayslipId != null){
                    console.log('this.row.employeePayslipId>>',this.row.employeePayslipId)
                    type = 'payslip/updatePayslipSingle'
                    this.fields = [
                            // { key: 'employerGradeSalaryItemSerial', label: 'Serial', sortable: true, sortDirection: 'desc' },
                            { key: 'payslipDetailRemark', label: 'Salary Item', sortable: true, sortDirection: 'desc' },
                            { key: 'payslipDetailType', label: 'Type', sortable: true, class: 'text-left' },
                            { key: 'payslipDetailAmount', label: 'Amount', sortable: true, class: 'text-right' }
                        ]
                }
                this.$store.dispatch(type,{
                    employerId: localStorage.orgId,
                    employeeId: this.row.employeeId,
                    employeePayslipId: this.row.employeePayslipId,
                    calenderDate: this.row.calenderDate
                })
                this.$refs['salary-details-modal'].show()
            }
        },
    }
</script>
