<template>
    <b-modal id="project-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="project-create-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4 v-if="model.type === 'edit'">Edit project</h4>
                <h4 v-else>Add new project</h4>
              <b-form @submit.prevent="submit" enctype="multipart/form-data">
                <b-form-group label="Title">
                  <b-input required v-model="model.projectTitle" placeholder="Project Title"/>
                </b-form-group>
                <b-form-group label="Manager">
                  <b-select required v-model="model.projectManager">
                    <b-select-option  value="">Select Project Manager</b-select-option>
                    <b-select-option v-for="employee in employees" v-bind:key="employee.employeeId" :value="employee.employeeId">{{employee.employeeFirstName}} {{employee.employeeLastName}}</b-select-option>
                  </b-select>
                </b-form-group>
                <b-form-group label="Description">
                  <b-textarea placeholder="Project Description"  required v-model="model.projectDescription" ></b-textarea>
                </b-form-group>
                <base-button title="Submit" :loading="project.projectLoading"/>
              </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row'],
        components:{BaseButton},
        name: 'ConfirmModal',
        data(){
            return{
                password: '',
                model:{
                  projectTitle: '',
                  projectDescription: '',
                  projectEmployerId: '',
                  projectManager: ''
                },
            }
        },
        methods:{
            hide() {
                this.$refs['project-create-modal'].hide()
            },
            async submit(){
                this.model.projectEmployerId = localStorage.orgId
                if (this.model.type === 'edit')
                  await this.$store.dispatch("project/editProject", this.model, {root: false});
                else {
                  this.model.projectEmployeeId = this.row.employeeId
                  await this.$store.dispatch("project/createProject", this.model, {root: false});
                }
                this.hide();
                this.loadProject()
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            loadProject(){
              this.$store.dispatch('project/updateProjects', {
                readAll: 'YES',
                employer: localStorage.orgId,
                employee: ''
              })
            },
            hideauth: function () {
                this.password = '';
                this.$refs['project-create-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row readSkillCategory
          this.$store.dispatch('employee/readSkillCategory',{readAll:"YES"})
        },
        computed:{
            ...mapState(['grade','auth','employee','project']),
          employees(){
            return  this.employee.employees?this.employee.employees:[]
          },
        },
        watch: {
            show: function () {
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                console.log(':::::::clicked::::::::')
                // this.$store.dispatch("employee/updateEmployees", {
                //   readAll:'NO',
                //   employerId: localStorage.orgId
                // }, {root: false}).then()
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                this.$refs['project-create-modal'].show()
            }
        },
    }
</script>
