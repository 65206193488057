<template>
    <b-modal id="csv-upload-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="csv-upload-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4>Upload File For Processing</h4>
                <p class="tx-color-03">Upload file for processing</p>
                <b-form @submit.prevent="" enctype="multipart/form-data">
                    <b-form-group>
                        <div class="dropbox">
                            <input multiple type="file" class="input-file" id="files" :name="file.uploadFieldName"
                                   :disabled="isLoading"
                                   @change="uploadFile" ref="file">
                            <p v-if="!isLoading">
                                Drag your file(s) here to begin<br> or click to browse
                            </p>
                            <p v-if="isLoading">
                                Uploading files...
                            </p>

                        </div>
                    </b-form-group>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>

<script>
    import {mapState} from 'vuex'
    import xlsx from "xlsx";

    export default {
        name: 'Loan',
        props:['uploadType','readType','isLoading','uploadData','readData','header','show'],
        data() {
            return {
                model:{
                    data: ''
                },
                file: {
                    accountnumber: '',
                    file: '',
                    amount: '',
                    status: false,
                    bank: {},
                    narration: '',
                    uploadedFiles: [],
                    uploadError: null,
                    currentStatus: null,
                    uploadFieldName: 'photos',
                },
            }
        },
        components: {
            // BaseButton,
            // BaseTable,
            // BaseTopCards,
            // PrinterIcon,
            // PlusIcon,
        },
        computed: {
            grades(){
                return  this.grade.grades
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            ...mapState([
                'grade',
                'auth'
            ])
        },
        methods: {
            previewFiles(e) {
                let files = e.target.files, f = files[0];
                let reader = new FileReader();
                reader.onload = function(e) {
                    let data = new Uint8Array(e.target.result);
                    let workbook = xlsx.read(data, {type: 'array'});
                    let sheetName = workbook.SheetNames[0]
                    /* DO SOMETHING WITH workbook HERE */
                    console.log(workbook);
                    let worksheet = workbook.Sheets[sheetName];
                    console.log(xlsx.utils.sheet_to_json(worksheet));
                    return xlsx.utils.sheet_to_json(worksheet)
                };
                reader.readAsArrayBuffer(f);
            },
            uploadFile(event) {
                console.log(event)
                this.handleUpload(event)
            },
            async handleUpload(event) {
                const file = event.target.files[0];
                const reader = new FileReader();
                try {
                    reader.onload = async e => {
                        if (file.name.split('.')[1] === 'csv'){
                            this.model.data =  this.csv2json(e.target.result, ',');
                        }else if (file.name.split('.')[1] === 'xls' || file.name.split('.')[1] === 'xlsx'){
                            let data = new Uint8Array(e.target.result);
                            let workbook = xlsx.read(data, {type: 'array'});
                            console.log(workbook)
                            let sheetName = workbook.SheetNames[0]
                            /* DO SOMETHING WITH workbook HERE */
                            console.log(workbook);
                            let worksheet = workbook.Sheets[sheetName];
                            this.model.data = xlsx.utils.sheet_to_json(worksheet)
                        }else {
                            await this.$swal({
                                title: 'Error',
                                text: 'Invalid file type',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            }).then(rtn=>{
                                console.log(rtn)
                                this.hide()
                            })
                        }
                        console.log(this.model)
                        await this.$store.dispatch(this.uploadType, this.model);
                        this.loadData();
                        this.hide();

                    };
                    if (file.name.split('.')[1] === 'csv') {
                        reader.readAsText(file);
                    }else {
                        reader.readAsArrayBuffer(file);
                    }
                } catch (e) {
                    console.warn(e.message)
                }
            },
            csv2json(str, delimiter = ','){
                let titles = str.slice(0, str.indexOf('\n')).split(delimiter);
                for (let i = 0; i < titles.length; i++) titles[i] = titles[i].replace('\r','');
                const rows = str.trim().slice(str.indexOf('\n') + 1).split('\n');
                return rows.map(row => {
                    const values = row.split(delimiter);
                    return titles.reduce((object, curr, i) => (object[curr] = values[i].replace('\r',''), object), {})
                });
            },
            loadData(){
                this.$store.dispatch(this.readType, this.readData, {root: false}).then()
            },
            hide() {
                this.$refs['csv-upload-modal'].hide()
            },
            onSelect({name, iso2, dialCode}) {
                this.model.employeeCountryCode =  dialCode
                console.log(name, iso2, dialCode);
            },
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {
            this.loadData()
        },
        watch: {
            show: function () {
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.uploadData).forEach(function(key,index) {
                    self.model[key] = self.uploadData[key];
                });
                this.$refs['csv-upload-modal'].show()
            }
        },
    }


</script>

<style scoped>
    .dropbox {
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: lightcyan;
        color: dimgray;
        padding: 10px 10px;
        min-height: 200px; /* minimum height */
        position: relative;
        cursor: pointer;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: lightblue; /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
    }
</style>
