import apiClient from "./BaseService";

export default {
    callCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("conversationHeadCreate", payload);
    },
    callReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("conversationHeadReadAll", payload);
    },
    callCreateTranscriptApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("conversationTranscriptCreate", payload);
    },
};
