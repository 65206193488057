<template>
    <div class="content-body pd-0">
        <div class="mail-wrapper mail-wrapper-two">
            <div class="mail-group" style="overflow-y:scroll; height: 100%;">
                <div class="mail-group-header justify-content-between">
                    <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">Conversations</h6>
<!--                    <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20"><refresh-ccw-icon title="Add new conversation"/></h6>-->
                    <div class="dropdown tx-13">
                        <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">
                            <refresh-ccw-icon @click="loadConversation" style="cursor: pointer;" title="Reload conversation"/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <plus-square-icon style="cursor: pointer;" title="Add new conversation" @click="$refs['conversation-modal'].show()"/>
                        </h6>
<!--                        <span class="tx-color-03">Sort:</span> <a href="" class="dropdown-link link-02">Date</a>-->
                    </div><!-- dropdown -->
                </div><!-- mail-group-header -->
                <div class="mail-group-body">

                    <label class="mail-group-label">Recent Conversations</label>
                    <bar-loader v-if="conversation.conversationLoading" class="custom-class" color="#6d0606" loading="loading" style="width: 100%;" sizeUnit="px"></bar-loader>
                    <ul class="list-unstyled media-list mg-b-0">
                        <span v-for="conversation in conversations" v-bind:key="conversation.conversationHeadId">
                            <li @click="selectConversation(conversation.conversationHeadId)" :class="activeClass(conversation.conversationHeadId,activeConversation.conversationHeadId)">
                                <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial(conversation.conversationHeadMaker)}}</span></div>
                                <div class="media-body mg-l-15">
                                    <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
                                        <span class="tx-12">{{conversation.conversationHeadMaker | titleCase}}</span>
                                        <span class="tx-11">{{conversation.conversationHeadCreatedAt | formatDateTime}}</span>
                                    </div>
                                    <h6 class="tx-13">{{conversation.conversationHeadSubject}}</h6>
                                    <p class="tx-12 tx-color-03 mg-b-0">{{conversation.conversationHeadBody | formatTextWithEllipsisThirty}}</p>
                                </div><!-- media-body -->
                            </li>
                        </span>
                    </ul>
                </div><!-- mail-group-body -->
            </div><!-- mail-group -->

            <div class="mail-content">
                <div class="mail-content-header">
                    <a href="" id="mailContentClose" class="link-02 d-none d-lg-block d-xl-none mg-r-20"><i data-feather="arrow-left"></i></a>
                    <div v-if="Object.keys(activeConversation).length > 0" class="media">
                        <b-icon-arrow-left v-if="this.$vssWidth < 1200" @click="navigate()" style="height: 20px; width: 50px; margin-left: -20px; margin-right: -20px; cursor: pointer;" class=" mg-t-8"/>
                        <div class="avatar avatar-sm  mg-l-10"><span class="avatar-initial rounded-circle bg-secondary">{{initial(activeConversation.conversationHeadMaker)}}</span></div>
                        <div class="media-body mg-l-10">
                            <h6 class="mg-b-2 tx-13">{{activeConversation.conversationHeadMaker | titleCase}}</h6>
                            <span class="d-block tx-11 tx-color-03">{{activeConversation.conversationHeadCreatedAt | formatDateTime}}</span>
                        </div><!-- media-body -->
                    </div><!-- media -->
                    <nav class="nav nav-icon-only mg-l-auto">
<!--                        <a href="" data-toggle="tooltip" title="Archive" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadConversation" style="cursor: pointer;" title="Reload conversation"/></a>-->
<!--                        <a href="" data-toggle="tooltip" title="Report Spam" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" title="Add new conversation" @click="$refs['conversation-modal'].show()"/></a>-->
                        <span class="nav-divider d-none d-sm-block"></span>
                        <a href="#" data-toggle="tooltip" title="Archive" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadConversation" style="cursor: pointer;" title="Reload conversation"/></a>
                        <a href="#" data-toggle="tooltip" title="Report Spam" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" title="Add new conversation" @click="$refs['conversation-modal'].show()"/></a>
                    </nav>
                </div><!-- mail-content-header -->
                <div class="mail-content-body"  style="overflow-y:scroll; height: 100%;">
                    <div v-if="Object.keys(activeConversation).length > 0" class="pd-20 pd-lg-25 pd-xl-30">
                        <h5 class="mg-b-30">{{activeConversation.conversationHeadSubject}}</h5>

<!--                        <h6 class="tx-semibold mg-b-0">{{activeConversation.conversationHeadMaker}}</h6>-->
                        <span>To: <span class="tx-color-03">{{activeConversation.conversationHeadGroup}}</span></span>
                        <p>Message Body: <span class="tx-color-03">{{activeConversation.conversationHeadBody}}</span></p>
                        <div class="chat-group-divider">Replies ({{activeConversation.conversationTranscripts.length}})</div>
                        <ul class="list-unstyled media-list" style="margin-bottom: 60px;">
                            <span v-for="reply in activeConversation.conversationTranscripts" v-bind:key="reply.conversationTranscriptId">
                                <li class="media">
                                    <div class="avatar"><span :class="`avatar-initial rounded-circle ${randomColor(reply.employeeFirstName)}`">{{initial(reply.employeeFirstName+" "+reply.employeeLastName)}}</span></div>
                                    <div class="media-body mg-l-15">
                                        <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
                                            <span class="tx-12 "><b>{{reply.employeeFirstName+" "+reply.employeeLastName | titleCase}}</b></span>
                                            <span class="tx-11">{{reply.conversationTranscriptCreatedAt | formatDateTime}}</span>
                                        </div>
                                        <p class="tx-12 tx-color-03 mg-b-0">{{reply.conversationTranscriptBody}}</p>
                                    </div><!-- media-body -->
                                </li>
                                <hr>
                            </span>
                        </ul>
                    </div>
                    <div v-else class="pd-20 pd-lg-25 pd-xl-30">
                        <span style="text-align: center;">
                            <h1 style="margin-top: 25%;"><b-icon-chat style="height: 100px; width: 150px;"/></h1>
<!--                            <h4>Start a new conversation</h4>-->
                            <p><b-button variant="warning">Start a new conversation</b-button></p>
                        </span>
                    </div>
                </div><!-- mail-content-body -->
                <b-form @submit.prevent="createTranscript()" v-if="Object.keys(activeConversation).length > 0" class="bf" style="bottom: -15px; position: absolute; width: 100%">
                    <b-form-group class="fg">
                        <b-input-group>
                            <b-input v-model="transcriptModel.conversationTranscriptBody" class="bi"/>
                            <b-button type="submit" class="button">Send</b-button>
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </div><!-- mail-content -->
        </div><!-- mail-wrapper -->
        <b-modal id="conversation-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="conversation-modal">
            <div class="modal-body">
                <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
                   @click="hide()">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div>
                    <h4>Start new conversation</h4>
                    <b-form @submit.prevent="createConversation" enctype="multipart/form-data">
                        <b-form-group label="Subject">
                            <b-input required v-model="conversationModel.conversationHeadSubject"/>
                        </b-form-group>
                        <b-form-group label="Body">
                            <b-textarea required v-model="conversationModel.conversationHeadBody"></b-textarea>
                        </b-form-group>
                        <b-form-group label="Group/Department">
                            <b-select required v-model="conversationModel.conversationHeadGroup">
                                <b-select-option value="ALL">All</b-select-option>
                                <b-select-option v-for="department in departments" :value="department.employerDepartmentCode" v-bind:key="department.employerDepartmentCode">{{department.employerDepartmentName}}</b-select-option>
                            </b-select>
                        </b-form-group>
                        <base-button title="start" :loading="conversation.conversationLoading"/>
                    </b-form>
                </div>
            </div><!-- modal-body -->
        </b-modal>
    </div>
</template>

<script>
    // import BaseTopCards from "../../components/card/BaseTopCards";
    // import BaseTable from "../../components/table/BaseTable";
    import BaseButton from "../../components/button/BaseButton";
    import { PlusSquareIcon, RefreshCcwIcon } from 'vue-feather-icons'
    import {mapState} from 'vuex'

    export default {
        name: 'Proprietor',
        data() {
            return {
                conversationModel:{
                    conversationHeadBody: '',
                    conversationHeadBodyType: 'TEXT',
                    conversationHeadEmployerId: '',
                    conversationHeadMaker: '',
                    conversationHeadGroup: 'ALL',
                    conversationHeadSubject: '',
                    conversationHeadType: 'MEDIA'
                },
                transcriptModel:{
                    conversationTranscriptId:0,
                    conversationTranscriptBody:'',
                    conversationTranscriptCreatedAt:'',
                    employeeFirstName:'',
                    employeeLastName:'',
                    conversationTranscriptEmployeeId: '',
                    conversationTranscriptHeadId: ''
                },
                screen:'',
                activeConversation:{},
                fields: [
                    { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'conversationFirstName', label: 'First Name', sortable: true, sortDirection: 'desc' },
                    //{ key: 'conversationMiddleName', label: 'Middle Name', sortable: true, class: 'text-left' },
                    { key: 'conversationSurname', label: 'Surname', sortable: true, class: 'text-left' },
                    { key: 'conversationGender', label: 'Gender', sortable: true, sortDirection: 'desc' },
                    { key: 'conversationDob', label: 'Date of Birth', sortable: true, class: 'text-left' },
                    { key: 'conversationEmail', label: 'Email', sortable: true, class: 'text-left' },
                    { key: 'businessName', label: 'Business Name', sortable: true, class: 'text-left' },
                    { key: 'businessType', label: 'Business Type', sortable: true, class: 'text-left' },
                    { key: 'businessRegDate', label: 'Business Reg Date', sortable: true, class: 'text-left' },
                    { key: 'actions', label: 'Action', sortable: true, class: 'text-center' }
                ],
            }
        },
        components: {
            BaseButton,
            RefreshCcwIcon,
            PlusSquareIcon,


        },
        computed: {
            cards:{
                get(){
                    return [
                        {
                            id: 0,
                            name: 'Total Conversations',
                            number: this.departments.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
                            color: 'bg-primary'
                        },
                        {
                            id: 1,
                            name: 'Active Conversations',
                            number: this.departments.filter(it => it.employerConversationStatus.toUpperCase() === 'ACTIVE').length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
                            color: 'bg-orange'
                        },
                        {
                            id: 2,
                            name: 'Pending Conversations',
                            number: this.departments.filter(it => it.employerConversationStatus.toUpperCase() === 'PENDING').length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
                            color: 'bg-success'
                        },
                        {
                            id: 3,
                            name: 'Suspended Conversations',
                            number: this.departments.filter(it => it.employerConversationStatus.toUpperCase() === 'SUSPENDED').length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
                            color: 'bg-black-8'
                        }
                    ]
                },
                set(){

                }
            },
            departments(){
                return  this.department.departments
            },
            conversations(){
                return  this.conversation.conversations
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            ...mapState([
                'department',
                'conversation',
                'auth'
            ])
        },
        methods: {
            async createConversation(){
                this.conversationModel.conversationHeadMaker = `${this.auth.userInfo.firstName} ${this.auth.userInfo.lastName}`
                this.conversationModel.conversationHeadEmployerId = localStorage.orgId
                await this.$store.dispatch("conversation/createConversation", this.conversationModel, {root: false})
                this.loadConversation();
                this.hide()
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.conversationModel).forEach(function(key,index) {
                    self.conversationModel[key] = '';
                });

            },
            hide() {
                this.$refs['conversation-modal'].hide()
            },
            loadConversation(){
                this.$store.commit('conversation/updateConversationLoading',true)
                this.$store.dispatch("conversation/updateConversations", {
                    conversationHeadEmployeeId: localStorage.orgId,
                    readAll:'YES'
                }, {root: false}).then()
            },
            createTranscript(){
                let transcript ={
                    conversationTranscriptId:0,
                    conversationTranscriptBody:'',
                    conversationTranscriptCreatedAt:'',
                    employeeFirstName:'',
                    employeeLastName:'',
                    conversationTranscriptEmployeeId: '',
                    conversationTranscriptHeadId: ''
                }
                transcript.conversationTranscriptCreatedAt = new Date()
                transcript.conversationTranscriptEmployeeId = this.auth.userInfo.firstName+' '+this.auth.userInfo.lastName+' (Admin)'
                transcript.conversationTranscriptHeadId = this.activeConversation.conversationHeadId
                transcript.employeeFirstName = this.auth.userInfo.firstName
                transcript.employeeLastName =  `${this.auth.userInfo.lastName} (Admin)`
                transcript.conversationTranscriptBody = this.transcriptModel.conversationTranscriptBody

                this.activeConversation.conversationTranscripts.unshift(transcript)
                console.log(this.transcriptModel)
                this.$store.dispatch("conversation/createTranscript", transcript, {root: false}).then()
                this.transcriptModel.conversationTranscriptBody = ''
            },
            selectConversation(_id){
                this.toggleBodyClass('addClass','mail-content-show')
                this.toggleBodyClass('addClass','mail-content-show')
                this.activeConversation = this.conversations.filter(it=>it.conversationHeadId === _id)[0]
            },
            toggleBodyClass(addRemoveClass, className) {
                const el = document.body;

                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
            navigate(){
                this.toggleBodyClass('removeClass','mail-content-show')
            },
            initial(_title){
                let titleArray = _title.split(' ')
                let firstInitial = titleArray[0].substring(0,1)
                let secondInitial = ""
                if (titleArray[1] != null)
                    secondInitial = titleArray[1].substring(0,1)
                return firstInitial+secondInitial
            },
            randomColor(_name){
                let initial = _name.substring(0,1)
                let color = ""
                if (initial.toUpperCase() === "A") color = "bg-indigo"
                else if (initial.toUpperCase() === "B") color = "bg-light"
                else if (initial.toUpperCase() === "C") color = "bg-success"
                else if (initial.toUpperCase() === "D") color = "bg-warning"
                else if (initial.toUpperCase() === "E") color = "bg-primary"
                else if (initial.toUpperCase() === "F") color = "bg-info"
                else if (initial.toUpperCase() === "G") color = "bg-danger"
                else if (initial.toUpperCase() === "H") color = "bg-dark"
                else if (initial.toUpperCase() === "I") color = "bg-muted"
                else if (initial.toUpperCase() === "J") color = "bg-indigo"
                else if (initial.toUpperCase() === "K") color = "bg-light"
                else if (initial.toUpperCase() === "L") color = "bg-warning"
                else if (initial.toUpperCase() === "M") color = "bg-info"
                else if (initial.toUpperCase() === "N") color = "bg-light"
                else if (initial.toUpperCase() === "O") color = "bg-danger"
                else if (initial.toUpperCase() === "P") color = "bg-warning"
                else if (initial.toUpperCase() === "Q") color = "bg-info"
                else if (initial.toUpperCase() === "R") color = "bg-muted"
                else if (initial.toUpperCase() === "S") color = "bg-primary"
                else if (initial.toUpperCase() === "T") color = "bg-dark"
                else if (initial.toUpperCase() === "U") color = "bg-light"
                else if (initial.toUpperCase() === "V") color = "bg-primary"
                else if (initial.toUpperCase() === "W") color = "bg-indigo"
                else if (initial.toUpperCase() === "X") color = "bg-muted"
                else if (initial.toUpperCase() === "Y") color = "bg-primary"
                else if (initial.toUpperCase() === "Z") color = "bg-warning"
                else color = "bg-primary"
                return color
            },
            activeClass(_id1,_id2){
                if(_id1 === _id2) return 'media'
                else return 'media unread'
            },
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {
            this.$store.dispatch("conversation/updateConversations", {
                conversationHeadEmployeeId: localStorage.orgId,
                readAll:'YES'
            }, {root: false}).then()
            this.$store.dispatch("department/updateDepartments", {
                readAll:'NO',
                employerId: localStorage.orgId
            }, {root: false}).then()
        }
    }


</script>

<style scoped>
    @media (min-width: 1200px){
        .mail-group {
            width: 360px;
            border-right: 1px solid rgba(72, 94, 144, 0.16);
        }
    }

    /*@media (min-width: 992px){*/
    /*    .mail-content {*/
    /*        left: 360px;*/
    /*        width: auto;*/
    /*        right: 0;*/
    /*        display: block;*/
    /*    }*/
    /*     .mail-content {*/
    /*         position: absolute;*/
    /*         top: 0;*/
    /*         left: 200vw;*/
    /*         bottom: 0;*/
    /*         width: 100vw;*/
    /*         display: block;*/
    /*         background-color: #f5f6fa;*/
    /*     }*/
    /*    div {*/
    /*        display: block;*/
    /*    }*/
    /*}*/

</style>
