<template>
    <div>
        <button v-if="loading === true" disabled class="btn btn-brand-02 btn-block">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
        </button>
        <button v-else  class="btn btn-brand-02 btn-block">{{title}}</button>
    </div>
</template>

<script>
    export default {
        props:['loading','title'],
        name: "BaseButton"
    }
</script>

<style scoped>

</style>