<template>
  <b-modal id="edit-client-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="edit-client-modal">
    <div class="modal-body">
      <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
         @click="hide()">
        <span aria-hidden="true">&times;</span>
      </a>

      <div>

        <h4>Edit Client</h4>
        <b-form @submit.prevent="editClient" enctype="multipart/form-data">
          <!--            <b-form-group label="Staff Id">-->
          <!--              <b-input-group>-->
          <!--                <b-input required placeholder="Staff Id" v-model="model.employeeStaffId"/>-->
          <!--                <the-mask placeholder="BVN" required v-model="model.employeeBvn" maxlength="11" minlength="11" :mask="['###########', '###########']" class="form-control" />-->
          <!--              </b-input-group>-->
          <!--            </b-form-group>-->
          <b-form-group label="Client Name">
            <b-input-group>
              <b-input type="text" placeholder="Client Name"  required v-model="model.clientName"/>
              <!--                <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>-->
              <!--                <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>-->
            </b-input-group>
          </b-form-group>
          <b-form-group label="Client Code">
            <b-input-group>
              <b-input type="text" placeholder="Client Code" required v-model="model.clientCode"/>
              <!--                <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>-->
              <!--                <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>-->
            </b-input-group>
          </b-form-group>
          <b-form-group label="Client Phone Number">
            <b-input-group>
              <vue-country-code @onSelect="onSelect" :preferredCountries="['vn', 'us', 'gb']">
              </vue-country-code>
              <b-input type="number" placeholder="Phone" required v-model="model.clientPhone"/>
              <b-input type="email" placeholder="Email" required v-model="model.clientEmail"/>
            </b-input-group>
          </b-form-group>
          <!--            <b-form-group label="ID Card">-->
          <!--              <b-input-group>-->
          <!--                <b-select v-model="model.employeeIdType">-->
          <!--                  <b-select-option value="">Select ID Type</b-select-option>-->
          <!--                  <b-select-option value="NATIONAL ID">NATIONAL ID</b-select-option>-->
          <!--                  <b-select-option value="INTERNATIONAL PASSPORT">INTERNATIONAL PASSPORT</b-select-option>-->
          <!--                  <b-select-option value="VOTERS CARD">VOTERS CARD</b-select-option>-->
          <!--                  <b-select-option value="DRIVER LICENCE">DRIVER LICENCE</b-select-option>-->
          <!--                  <b-select-option value="OTHERS">OTHERS</b-select-option>-->
          <!--                </b-select>-->
          <!--                <b-input type="text" placeholder="ID NUMBER" required v-model="model.employeeIdNumber"/>-->
          <!--              </b-input-group>-->
          <!--            </b-form-group>-->
          <b-form-group label="Country">
            <country-select id="orgCountry" class="form-control" :countryName="true" :regionName="true" v-model="model.clientCountry" :country="model.clientCountry" topCountry="NG" />
          </b-form-group>


          <b-form-group label="City">
            <b-input type="text" placeholder="Enter city" required v-model="model.clientCity"/>
          </b-form-group>
          <!--            <b-form-group label="Date of birth">-->
          <!--              <b-input-group>-->
          <!--                <b-input type="date" placeholder="Date of birth" required v-model="model.employeeDob"/>-->
          <!--                <b-select v-model="model.employeeGender">-->
          <!--                  <b-select-option value="">Select Gender</b-select-option>-->
          <!--                  <b-select-option value="MALE">MALE</b-select-option>-->
          <!--                  <b-select-option value="FEMALE">FEMALE</b-select-option>-->
          <!--                </b-select>-->
          <!--              </b-input-group>-->
          <!--            </b-form-group>-->

          <!--            <b-form-group label="Employment Details">-->
          <!--              <b-input-group>-->
          <!--                <b-select v-model="model.employeeDepartment">-->
          <!--                  <b-select-option value="">Select Department</b-select-option>-->
          <!--                  <b-select-option v-for="dpt in department.departments" :value="dpt.employerDepartmentCode" v-bind:key="dpt.employerDepartmentCode">{{dpt.employerDepartmentName}}</b-select-option>-->
          <!--                </b-select>-->
          <!--                <b-select v-model="model.employeeGradeCode">-->
          <!--                  <b-select-option value="">Select Grade</b-select-option>-->
          <!--                  <b-select-option v-for="grd in grade.grades" :value="grd.employerGradeCode" v-bind:key="grd.employerGradeCode">{{grd.employerGradeName}}</b-select-option>-->
          <!--                </b-select>-->
          <!--              </b-input-group>-->
          <!--            </b-form-group>-->
          <b-form-group label="Client Type">
            <b-select v-model="model.clientType">
              <b-select-option value="">Select Type</b-select-option>
              <b-select-option value="Type 1">Type 1</b-select-option>
              <b-select-option value="Type 2">Type 2</b-select-option>
            </b-select>
          </b-form-group>

          <b-form-group label="Contact Person">
            <b-input type="text" placeholder="Contact Person" required v-model="model.clientContactPerson"/>
          </b-form-group>
          <b-form-group label="Client Status">
            <b-select v-model="model.clientStatus">
              <b-select-option value="">Select Type</b-select-option>
              <b-select-option value="ACTIVE">Active</b-select-option>
              <b-select-option value="PENDING">Pending</b-select-option>
              <b-select-option value="SUSPENDED">Suspended</b-select-option>
            </b-select>
          </b-form-group>
          <base-button title="Submit" :loading="client.clientLoading" />
        </b-form>
      </div>
    </div><!-- modal-body -->
  </b-modal>
</template>

<script>



import ClientRequest from "@/model/request/ClientRequest";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import BaseButton from "@/components/button/BaseButton";
import {mapState} from "vuex";

export default {
  name: "EditClientModal",
  components:{
    BaseButton
  },
  props:['show','row'],
  data(){
    return{
      model: ClientRequest.create,
      editModel: ClientRequest.edit
    }
  },
  computed:{
    ...mapState(['client'])
  },
  methods:{
    hide() {
      this.$refs['edit-client-modal'].hide()
    },
    async editClient(){
      this.model.clientCompanyCode = localStorage.orgId;
      await StoreUtils.dispatch(StoreUtils.actions.client.UPDATE_CLIENT, this.model)
      this.hide();
    }
  },
  watch: {
    show: function () {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });
      // this.$store.dispatch('expense/updateExpenseCategory',{readAll:"YES"})
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.row).forEach(function(key,index) {
        self.model[key] = self.row[key];
      });
      this.$refs['edit-client-modal'].show()
    }
  }


}
</script>

<style scoped>

</style>