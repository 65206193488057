import apiClient from "./BaseService";

export default {
    callCreateProjectApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectCreate", payload);
    },
    callCreateProjectCommentApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectCommentCreate", payload);
    },
    callUpdateProjectApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectUpdate", payload);
    },
    callReadProjectApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectRead", payload);
    },
    callReadProjectSingleApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectReadSingle", payload);
    },
    callUpdateProjectStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectUpdateStatus", payload);
    },
    callCreateProjectTaskApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectTaskCreate", payload);
    },
    callUpdateProjectTaskApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectTaskUpdate", payload);
    },
    callReadTaskApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectTaskRead", payload);
    },
    callReadCommentApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectCommentRead", payload);
    },
    callReadProjectTaskApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("readTaskByProject", payload);
    },
    callReadTaskByProjectApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("readTaskByProject", payload);
    },
    callProjectTaskSingleApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectTaskReadSingle", payload);
    },
    callUpdateProjectTaskStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectTaskUpdateStatus", payload);
    },
    callReadProjectStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("projectStatusRead", payload);
    },
};
