import apiClient from "./BaseService";

export default {
    callCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.clientCompanyCode = localStorage.orgId;
        return apiClient.timeSheet.post("/client/create", payload);
    },
    callReadByCompanyCode(payload) {
        payload.clientCompanyCode = localStorage.orgId;
        return apiClient.timeSheet.post("/client/read-by-client-company-code", payload);
    },
    callUpdateApi(payload){
        payload.clientCompanyCode = localStorage.orgId;
        return apiClient.timeSheet.post("/client/update", payload);
    }
}