import ResourceRequest from "@/model/request/ResourceRequest";
import ResourceService from "@/service/ResourceService";
import swal from "sweetalert";
// import StoreUtils from "@/utils/BaseUtils/StoreUtils";


export const state = {
    sheet: [],
    sheetLoading: false,
    hourlyRate: ""
};

export const getters = {
    getSheet: state => {
        return state.sheet;
    },
    getHourlyRate: state => {
        return state.hourlyRate;
    },
};

export const mutations = {

    updateSheet: (state, payload) =>{
        state.sheet = payload
    },
    updateSheetLoading: (state, payload) =>{
        state.sheetLoading = payload
    },
    updateHourlyRate: (state, payload) =>{
        state.hourlyRate = payload
    }

};

export const actions = {
    readResourceSheet({commit}, payload = ResourceRequest.readResourceTimesheet){
        commit("updateSheetLoading", true)
        return ResourceService.callReadApi(payload).then(response => {
            commit("updateSheetLoading", false)
            let responseData = response.data
            if (responseData.responseCode === "00") {
                commit("updateSheet", responseData.sheet)
                commit("updateHourlyRate", responseData.resource.resourceHourlyRate)
                // swal('Success!', responseData.responseMessage, 'success')
            }else{
                swal('Error!', responseData.responseMessage, 'error')
            }
        })
            .catch(error => {
                commit("updateSheetLoading", false)
                alert(error)
                // throw error;
            });
    }
};