import apiClient from "./BaseService";

export default {
    callCreateCalenderApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("calenderCreate", payload);
    },
    callRunPayrollApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("manualProcess", payload);
    },
    callRepeatPayrollCalenderApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("payslipRetryCalender", payload);
    },
    callReadCalenderApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("calenderRead", payload);
    },
    callReadCalenderEmployeeApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("calenderReadEmployee", payload);
    },
    callReadCalenderEmployeeSingleApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("calenderReadEmployeeSingle", payload);
    },
    callCreateVariablePayApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("variablePayCreate", payload);
    },
    callProcessVariablePayApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("processVariablePay", payload);
    },
    callUploadVariablePayApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("uploadVariablePay", payload);
    },
    callUpdateVariablePayApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("variablePayUpdate", payload);
    },
    callUpdateStatusVariablePayApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("variablePayUpdateStatus", payload);
    },
    callReadVariablePayApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("variablePayRead", payload);
    },
    callCreatePayslipApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("payslipCreate", payload);
    },
    callReadPayslipApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("payslipRead", payload);
    },
    callReadPayslipSingleApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("payslipReadSingle", payload);
    },
};
