class ClientRequest {
    static create = {
        clientCode : null,
        clientCompanyCode : null,
        clientName : null,
        clientCity : null,
        clientCountry : null,
        clientType : null,
        clientEmail : null,
        clientPhone : null,
        clientContactPerson : null,
        clientStatus: null
    }
    static readByCompanyCode = {
        clientCompanyCode : null
    }

    static edit = {
        clientCode : null,
        clientCompanyCode : null,
        clientName : null,
        clientCity : null,
        clientCountry : null,
        clientType : null,
        clientEmail : null,
        clientPhone : null,
        clientContactPerson : null,
        clientStatus : null
    }
}

export default ClientRequest