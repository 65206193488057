<template>
    <div class="content ht-100v pd-0">
        <div class="content-body pd-10">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item">
                                <router-link to="#">VariablePayments</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All VariablePayments</li>
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">View All Variable Payments</h4>
                    <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
                    <!--                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLoginDate | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
                </div>
                <div class="d-none d-md-block">
                  <button class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5"  @click="showUploadModal"><upload-cloud-icon class="wd-10 mg-r-5" /> Upload VariablePayments</button>
                  <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"  v-b-modal="'variablePayment-modal'"><plus-icon class="wd-10 mg-r-5" /> Add VariablePayment</button>
                  <a class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" href="https://veezahrepo.s3.amazonaws.com/Variable_Payments.xlsx" target="_blank"><DownloadIcon class="mg-r-5"/> Download Template</a>
                  <download-excel
                            :data="variablePayments"
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <PrinterIcon class="mg-r-5"/>Export VariablePayments
                    </download-excel>
<!--                    <download-excel-->
<!--                            type="csv"-->
<!--                            :data="variablePayments"-->
<!--                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">-->
<!--                        <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}-->
<!--                    </download-excel>-->

                </div>
            </div>
            <base-top-cards :carditems="cards"/>
            <hr>

            <base-table
                    :items="variablePayments"
                    :fields="fields"
                    :show-title="true"
                    :is-busy="variablePayment.variablePaymentLoading"
                    class="mt-2"
                    filter-mode="variablePayments"
            ></base-table>
        </div>
        <b-modal id="variablePayment-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="variablePayment-modal">
            <div class="modal-body">
                <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
                   @click="hide()">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div>
                    <h4>Add new variablePayment</h4>
                    <b-form @submit.prevent="createVariablePayment" enctype="multipart/form-data">
                        <b-form-group label="Item Name">
                            <b-input type="text" placeholder="Item Name" v-model="model.employeeVariablePayItem"/>
                        </b-form-group>
                        <b-form-group label="Calender">
                            <b-select required v-model="model.employeeVariablePayCalender">
                                <b-select-option value="">Select calender</b-select-option>
                                <b-select-option v-for="cal in calenders" :value="cal.calenderDate" v-bind:key="cal.calenderDate">{{cal.calenderDate}}</b-select-option>
                            </b-select>
                        </b-form-group>
                        <b-form-group label="Employee">
                            <b-select required v-model="model.employeeVariablePayEmployeeId">
                                <b-select-option value="">Select employee</b-select-option>
                                <b-select-option v-for="emp in employees" :value="emp.employeeId" v-bind:key="emp.employeeId">{{emp.employeeFirstName}} {{emp.employeeMiddleName}} {{emp.employeeLastName}}</b-select-option>
                            </b-select>
                        </b-form-group>
                        <b-form-group label="Exempt Tax">
                            <b-select required v-model="model.employeeVariablePayItemTaxExempt">
                                <b-select-option value="YES">YES</b-select-option>
                                <b-select-option value="NO">NO</b-select-option>
                            </b-select>
                        </b-form-group>
                        <b-form-group label="Payment Type">
                            <b-select required v-model="model.employeeVariablePayItemType">
                                <b-select-option value="EARNINGS">EARNINGS</b-select-option>
                                <b-select-option value="DEDUCTIONS">DEDUCTIONS</b-select-option>
                            </b-select>
                        </b-form-group>
                        <b-form-group label="Payment Schedule">
                            <b-select required v-model="model.employeeVariablePayment">
                                <b-select-option value="PAY_NOW">PAY NOW</b-select-option>
                                <b-select-option value="PAY_WITH_SALARY">PAY WITH SALARY</b-select-option>
                            </b-select>
                        </b-form-group>
                      <b-form-group label="Debit Account">
                        <b-select v-model="model.employeeVariablePayDebitAccount">
                          <b-select-option value="">Choose salary account</b-select-option>
                          <b-select-option v-for="account in modelAccounts" :value="account.accountNumber" v-bind:key="account.accountNumber">{{account.accountNumber}} - {{account.accountBankName}}</b-select-option>
                        </b-select>
                      </b-form-group>
                        <b-form-group label="Amount">
                            <b-input type="number" v-model="model.employeeVariablePayItemAmount"/>
                        </b-form-group>
                        <base-button title="Submit" :loading="variablePayment.variablePaymentLoading"/>
                    </b-form>
                </div>
            </div><!-- modal-body -->
        </b-modal>
      <upload-csv-modal
          :show="showModal"
          :header="myHeaders"
          :is-loading="variablePayment.variablePaymentLoading"
          read-type="variablePayment/updateVariablePayments"
          upload-type="variablePayment/uploadVariablePayment"
          :read-data="readData"
          :upload-data="uploadModel"
      />
    </div>
</template>

<script>
    import BaseTopCards from "../../components/card/BaseTopCards";
    import BaseTable from "../../components/table/BaseTable";
    import BaseButton from "../../components/button/BaseButton";
    import UploadCsvModal from "@/components/modal/UploadCsvModal";
    import { PrinterIcon, PlusIcon, UploadCloudIcon } from 'vue-feather-icons'
    import {mapState} from 'vuex'

    export default {
        name: 'Loan',
        data() {
            return {
                showModal:true,
                itemRow:{},
                model:{
                    employeeVariablePayCalender: '',
                    employeeVariablePayEmployerId: '',
                    employeeVariablePayEmployeeId: '',
                    employeeVariablePayItem: '',
                    employeeVariablePayDebitAccount: '',
                    employeeVariablePayment: 'PAY_WITH_SALARY',
                    employeeVariablePayItemTaxExempt: 'YES',
                    employeeVariablePayItemType: 'EARNINGS',
                    employeeVariablePayItemAmount: ''
                },
                fields: [
                    { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'employeeVariablePayCalender', label: 'Calender', sortable: true, sortDirection: 'desc' },
                    { key: 'employeeName', label: 'Employee Name', sortable: true, class: 'text-left' },
                    { key: 'employeeVariablePayItem', label: 'Item Name', sortable: true, class: 'text-left' },
                    { key: 'employeeVariablePayItemAmount', label: 'Amount', sortable: true, class: 'text-left' },
                    { key: 'employeeVariablePayItemTaxExempt', label: 'Exempt Tax', sortable: true, class: 'text-left' },
                    { key: 'employeeVariablePayItemType', label: 'Type', sortable: true, class: 'text-left' },
                    { key: 'employeeVariablePayStatus', label: 'Status', sortable: true, class: 'text-left' },
                    { key: 'actionsStatus', label: 'Action', sortable: true, class: 'text-center' }
                ],
                myHeaders:[
                  'calender', 'countryCode', 'phone', 'item', 'amount',  'taxExempt', 'itemType', 'paymentSchedule\r'
                ],
                readData:{
                  readAll:'NO',
                  employerId: ''
                },
                uploadModel:{
                  requestType: 'bulkUpload',
                  employerId: '',
                  data: []
                },
            }
        },
        components: {
            PlusIcon,
            BaseButton,
            BaseTable,
            BaseTopCards,
            PrinterIcon,
            UploadCsvModal,
            UploadCloudIcon
        },
        computed: {
            cards:{
                get(){
                    return [
                        {
                            id: 0,
                            name: 'Total VariablePayments',
                            number: this.variablePayments.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
                            color: 'bg-primary'
                        },
                        {
                            id: 1,
                            name: 'Active VariablePayments',
                            number: this.variablePayments.filter(it => it.employeeVariablePayStatus.toUpperCase() === 'ACTIVE').length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
                            color: 'bg-orange'
                        },
                        {
                            id: 2,
                            name: 'Pending VariablePayments',
                            number: this.variablePayments.filter(it => it.employeeVariablePayStatus.toUpperCase() === 'PENDING').length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
                            color: 'bg-success'
                        },
                        {
                            id: 3,
                            name: 'Suspended VariablePayments',
                            number: this.variablePayments.filter(it => it.employeeVariablePayStatus.toUpperCase() === 'SUSPENDED').length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
                            color: 'bg-black-8'
                        }
                    ]
                },
                set(){

                }
            },
            variablePayments(){
                return  this.variablePayment.variablePayments
            },
            calenders(){
                return  this.calender.calenders
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            employees(){
                return  this.employee.employees?this.employee.employees:[]
            },
            modelAccounts:{
              get(){
                console.log(this.$store.getters['auth/activeCompany'].accounts);
                return this.$store.getters['auth/activeCompany'].accounts
              },
              // set(val){
              //     this.model.accounts = val uploadModel
              // }
            },
            ...mapState([
                'employee',
                'variablePayment',
                'calender',
                'auth'
            ])
        },
        methods: {
            showUploadModal(){
              this.readData.employerId = localStorage.orgId
              this.uploadModel.requestType = 'bulkUpload'
              this.uploadModel.maker = `${this.auth.userInfo.firstName} ${this.auth.userInfo.lastName}`
              this.uploadModel.employerId = localStorage.orgId
              this.showModal = !this.showModal
            },
            loadEmployee(){
                this.$store.dispatch("employee/updateEmployees", {
                    readAll:'NO',
                    employerId: localStorage.orgId
                }, {root: false}).then()
            },
            async createVariablePayment(){
                this.model.employeeVariablePayEmployerId = localStorage.orgId
                await this.$store.dispatch("variablePayment/createVariablePayment", this.model, {root: false})
                this.loadVariablePayment();
                this.hide();
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            loadDepartment(){
                this.$store.dispatch("department/updateDepartments", {
                    readAll:'NO',
                    employerId: localStorage.orgId
                }, {root: false}).then()
            },
            loadCalender(){
                //this.$store.commit('calender/updateCalenderLoading',true)
                this.$store.dispatch("calender/updateCalenders", {
                    employerId: localStorage.orgId,
                    readAll:'YES'
                }, {root: false}).then()
            },
            loadVariablePayment(){
                this.$store.dispatch("variablePayment/updateVariablePayments", {
                    readAll:'NO',
                    employerId: localStorage.orgId
                }, {root: false}).then()
            },
            hide() {
                this.$refs['variablePayment-modal'].hide()
            },
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {
            this.$store.dispatch("employee/updateEmployees", {
                readAll:'NO',
                employerId: localStorage.orgId
            }, {root: false}).then()
            this.$store.dispatch("variablePayment/updateVariablePayments", {
                readAll:'NO',
                employerId: localStorage.orgId
            }, {root: false}).then()
            this.loadCalender()
            this.loadEmployee()
        }
    }


</script>

<style scoped>

</style>
