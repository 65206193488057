<template>
  <b-modal id="project-task-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="project-task-create-modal">
    <div class="modal-body">
      <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
         @click="hide()">
        <span aria-hidden="true">&times;</span>
      </a>
      <div>
        <h6>Task: {{row.projectTaskTitle}}</h6>
        <h6>Description:  {{row.projectTaskDescription}}</h6>
        <br>
        <p v-if="project.commentLoading" style="text-align: center;"><b-spinner variant="primary"/></p>
        <div v-else>
          <ul v-for="comment in project.projectComments" v-bind:key="comment.projectCommentId" class="list-unstyled">
            <b-media tag="li">
              <template #aside>
                <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial(comment.employeeFirstName +' '+comment.employeeLastName)}}</span></div>
              </template>
              <b-row>
                <b-col>
                  <h6 class="mt-0 mb-1">
                    <span style="color: #969696; text-decoration-style: wavy">
                      {{ comment.employeeFirstName }} {{ comment.employeeLastName }}
                    </span>
                  </h6>
                </b-col>
                <b-col>
                  <p style="color: #969696;">{{ comment.projectCommentCreatedAt }}</p>
                </b-col>
              </b-row>
              <p style="margin-top: -15px; font-size: 13px;" class="mb-0">
                {{ comment.projectCommentBody }}
              </p>
            </b-media>
          </ul>
        </div>
      </div>
    </div><!-- modal-body -->
  </b-modal>
</template>
<script>
import { mapState } from "vuex"
// import BaseButton from "../button/BaseButton";
export default {
  props:['show','row'],
  components:{

  },
  name: 'ConfirmModal',
  data(){
    return{
      password: '',
      model:{
        projectTaskTitle: '',
        projectTaskProjectId: '',
        projectTaskDescription: '',
        projectTaskEmployerId: '',
        projectTaskResource: ''
      },
    }
  },
  methods:{
    hide() {
      this.$refs['project-task-create-modal'].hide()
    },
    initial(_title){
      let titleArray = _title.split(' ')
      let firstInitial = titleArray[0].substring(0,1)
      let secondInitial = ""
      if (titleArray[1] != null)
        secondInitial = titleArray[1].substring(0,1)
      return firstInitial+secondInitial
    },
    async submit(){
      this.model.projectTaskEmployerId = localStorage.orgId
      if (this.model.type === 'edit')
        await this.$store.dispatch("project/editProjectTask", this.model, {root: false});
      else {
        this.model.taskEmployeeId = this.row.employeeId
        await this.$store.dispatch("project/createProjectTask", this.model, {root: false});
      }
      this.hide();
      this.loadTasks()
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });

    },
    gradeCode(){
      return  localStorage._gradeCode
    },
    projects(){
      return  this.project.projects
    },
    loadProject(){
      this.$store.dispatch('project/updateProjects', {
        readAll: 'YES',
        employer: localStorage.orgId,
        employee: ''
      })
    },
    loadTasks(){
      this.$store.dispatch("project/updateTasks", {
        readAll:'YES',
        employer: localStorage.orgId,
        employee: '',
        projectId: localStorage._projectId
      }, {root: false}).then()
      this.$store.dispatch("project/updateProjectTasks", {
        readAll:'YES',
        employer: localStorage.orgId,
        employee: '',
        projectId: localStorage._projectId
      }, {root: false}).then()
    },
    hideauth: function () {
      this.password = '';
      this.$refs['project-task-create-modal'].hide();
    },
    currentDate(){
      let date = new Date()
      date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
      console.log(date)
      return date
    },
  },
  mounted() {
    //this.model = this.row readSkillCategory
    this.$store.dispatch('employee/readSkillCategory',{readAll:"YES"})
  },
  computed:{
    ...mapState(['grade','auth','employee','project']),
    employees(){
      return  this.employee.employees?this.employee.employees:[]
    },
  },
  watch: {
    show: function () {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });
      console.log(':::::::clicked::::::::')
      this.loadProject()
      this.model.projectTaskProjectId = this.row.projectTaskProjectId
      this.$store.dispatch('project/updateProjectComments',{
        readAll:'NO',
        employer: localStorage.orgId,
        commentType: 'TASK',
        itemId: this.row.projectTaskId
      })
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.row).forEach(function(key,index) {
        self.model[key] = self.row[key];
      });
      this.$refs['project-task-create-modal'].show()
    }
  },
}
</script>
