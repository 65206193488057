<template>
  <div class="content ht-100v pd-0">

        <div class="content-body pd-10">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item">
                                <router-link to="#">SLAs</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All SLAs</li>
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">View All SLAs</h4>
                    <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
                  <!--                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLoginDate | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
                </div>
                <div class="d-none d-md-block">
                    <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"  v-b-modal="'sla-modal'"><plus-icon class="wd-10 mg-r-5" /> Add SLA</button>
                </div>
            </div>
            <base-top-cards :carditems="cards"/>
            <hr>

            <base-table
                :items="slas"
                :fields="fields"
                :show-title="true"
                :is-busy="sla.slaLoading"
                class="mt-2"
                filter-mode="balances"
            ></base-table>
        </div>

    <!--    <span v-else-if="auth.screenView === 'panel'">-->
    <!--          <div class="content-body pd-0">-->
    <!--        <div class="mail-wrapper mail-wrapper-two">-->
    <!--            <div class="mail-group" style="overflow-y:scroll; height: 100%;">-->
    <!--                <div class="mail-group-header justify-content-between">-->
    <!--                    <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">Clients</h6>-->
    <!--                  &lt;!&ndash;                    <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20"><refresh-ccw-icon title="Add new employee"/></h6>&ndash;&gt;-->
    <!--                    <div class="dropdown tx-13">-->
    <!--                        <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">-->
    <!--                            <refresh-ccw-icon @click="loadEmployee" style="cursor: pointer;" title="Reload employee"/>-->
    <!--                            <plus-square-icon style="cursor: pointer;" title="Add new client" @click="$refs['client-modal'].show()"/>-->
    <!--                        </h6>-->
    <!--                      &lt;!&ndash;                        <span class="tx-color-03">Sort:</span> <a href="" class="dropdown-link link-02">Date</a>&ndash;&gt;-->
    <!--                    </div>&lt;!&ndash; dropdown &ndash;&gt;-->
    <!--                </div>&lt;!&ndash; mail-group-header &ndash;&gt;-->
    <!--                <div class="mail-group-body">-->

    <!--                    <label class="mail-group-label">Clients</label>-->
    <!--                    <bar-loader v-if="client.clientLoading" class="custom-class" color="#6d0606" loading="loading" style="width: 100%;" sizeUnit="px"></bar-loader>-->
    <!--                    <ul class="list-unstyled media-list mg-b-0">-->
    <!--                        <span v-for="client in clients" v-bind:key="client.clientCode">-->
    <!--                            <li @click="selectEmployee(employee.employeeId)" :class="activeClass(employee.employeeId,activeEmployee.employeeId)">-->
    <!--                                <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial(employee.employeeFirstName+' '+employee.employeeLastName)}}</span></div>-->
    <!--                                <div class="media-body mg-l-15">-->
    <!--                                    <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">-->
    <!--                                        <span class="tx-12">{{employee.employeeFirstName+' '+employee.employeeMiddleName+' '+employee.employeeLastName | titleCase}}</span>-->
    <!--                                        <span class="tx-11">{{employee.employeeCreatedAt | formatDateTime}}</span>-->
    <!--                                    </div>-->
    <!--                                    <h6 class="tx-13">{{employee.employeeDetailRole | titleCase}}</h6>-->
    <!--                                  &lt;!&ndash;                                    <p class="tx-12 tx-color-03 mg-b-0">{{employee.employeeRole | formatTextWithEllipsisThirty}}</p>&ndash;&gt;-->
    <!--                                </div>&lt;!&ndash; media-body &ndash;&gt;-->
    <!--                            </li>-->
    <!--                        </span>-->
    <!--                    </ul>-->
    <!--                </div>&lt;!&ndash; mail-group-body &ndash;&gt;-->
    <!--            </div>&lt;!&ndash; mail-group &ndash;&gt;-->

    <!--            <div class="mail-content">-->
    <!--                <div class="mail-content-header">-->
    <!--                    <a href="" id="mailContentClose" class="link-02 d-none d-lg-block d-xl-none mg-r-20"><i data-feather="arrow-left"></i></a>-->
    <!--                    <b-icon-arrow-left v-if="this.$vssWidth < 1200" @click="navigate()" style="height: 20px; width: 50px; margin-left: -20px; margin-right: -20px; cursor: pointer;" class=" mg-t-8"/>-->
    <!--                    <div v-if="Object.keys(activeEmployee).length > 0" class="media">-->
    <!--                        <div class="avatar avatar-sm  mg-l-10"><span class="avatar-initial rounded-circle bg-secondary">{{initial(activeEmployee.employeeFirstName+' '+activeEmployee.employeeLastName)}}</span></div>-->
    <!--                        <div class="media-body mg-l-10">-->
    <!--                            <h6 class="mg-b-2 tx-13">{{activeEmployee.employeeFirstName+' '+activeEmployee.employeeMiddleName+' '+activeEmployee.employeeLastName | titleCase}}</h6>-->
    <!--                            <span class="d-block tx-11 tx-color-03">{{activeEmployee.employeeCreatedAt | formatDateTime}}</span>-->
    <!--                        </div>&lt;!&ndash; media-body &ndash;&gt;-->
    <!--                    </div>&lt;!&ndash; media &ndash;&gt;-->
    <!--                    <nav class="nav nav-icon-only mg-l-auto">-->
    <!--&lt;!&ndash;                        <a href="" data-toggle="tooltip" title="Archive" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadEmployee" style="cursor: pointer;" title="Reload employee"/></a>&ndash;&gt;-->
    <!--                      &lt;!&ndash;                        <a href="" data-toggle="tooltip" title="Report Spam" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" title="Add new employee" @click="$refs['employee-modal'].show()"/></a>&ndash;&gt;-->
    <!--                        <span class="nav-divider d-none d-sm-block"></span>-->
    <!--                        <a href="#" data-toggle="tooltip" title="Archive" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadEmployee" style="cursor: pointer;" title="Reload employee"/></a>-->
    <!--                        <a href="#" data-toggle="tooltip" title="Report Spam" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" title="Add new employee" @click="$refs['employee-modal'].show()"/></a>-->
    <!--                    </nav>-->
    <!--                </div>&lt;!&ndash; mail-content-header &ndash;&gt;-->
    <!--                <div class="mail-content-body"  style="overflow-y:scroll; height: 100%;">-->
    <!--                    &lt;!&ndash; TABS &ndash;&gt;-->
    <!--                    <span v-if="Object.keys(activeEmployee).length > 0">-->
    <!--                      <br>-->
    <!--                      <b-tabs content-class="mt-3" style="z-index: 10;" justified>-->
    <!--                        <b-tab title="Contact Information" class="pd-12" active>-->
    <!--                          <br>-->
    <!--                          <h6>Personal Details</h6>-->
    <!--                          <hr>-->
    <!--                          <b-row>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">FIRST NAME</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeFirstName | titleCase }}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">MIDDLE NAME</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeMiddleName | titleCase }}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">LAST NAME</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeLastName | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">GENDER</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeGender | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">DATE OF BIRTH</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDob | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                          </b-row>-->
    <!--                          <br>-->
    <!--                          <h6>Contact Details</h6>-->
    <!--                          <hr>-->
    <!--                          <b-row>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">MOBILE PHONE</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeePhoneNumber | titleCase }}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">EMAIL ADDRESS</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeEmail | titleCase }}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">DEPARTMENT</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDetailDepartment | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">STAFF ID</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDetailStaffId | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">NATIONALITY</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeNationality | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">JOB POSITION</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeDetailRole | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">ID TYPE</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeIdType | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                            <b-col sm="3" style="line-height: 11px;">-->
    <!--                              <p style="color: #8392a5; font-size: 11px;">ID NUMBER</p>-->
    <!--                              <p style="font-weight: lighter;">{{ activeEmployee.employeeIdNumber | titleCase}}</p>-->
    <!--                            </b-col>-->
    <!--                          </b-row>-->
    <!--                          <br>-->
    <!--                          <h6>Skills <plus-square-icon style="cursor: pointer;" title="Add new employee" @click="openModal('skill', {}, 'create')"/></h6>-->
    <!--                          <hr style="margin-bottom: -8px;">-->
    <!--                          <b-row>-->
    <!--                            <b-col md="3" sm="3" class="border-left border-primary" style="line-height: 12px; margin-left: 20px; margin-top: 15px;" v-for="skill in this.employee.skills" v-bind:key="skill.skillId">-->
    <!--                                <p style="font-weight: lighter; margin-bottom: 8px;">{{ skill.skillName | titleCase }} &nbsp;-->
    <!--                                  <b-icon-pencil style="cursor: pointer;" @click="openModal('skill',skill, 'edit')"/></p>-->
    <!--                                <p style="color: #8392a5; font-size: 11px;  margin-bottom: 8px;">{{ skill.skillCategoryName.toUpperCase() }}</p>-->
    <!--                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ skill.skillSkillEffectiveDate }}</p>-->
    <!--                            </b-col>-->
    <!--                          </b-row>-->
    <!--                          <br>-->
    <!--                          <br>-->
    <!--                          <h6>Education <plus-square-icon style="cursor: pointer;" title="Add new employee" @click="openModal('education', {}, 'create')"/></h6>-->
    <!--                          <hr style="margin-bottom: -8px;">-->
    <!--                          <b-row>-->
    <!--                            <b-col md="3" class="border-left border-primary" style="line-height: 12px; margin-left: 20px; margin-top: 15px;" v-for="education in this.employee.educations" v-bind:key="education.educationId">-->
    <!--                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ education.educationSchoolName | titleCase }} &nbsp;-->
    <!--                                  <b-icon-pencil style="cursor: pointer;" @click="openModal('education',education, 'edit')"/></p>-->
    <!--                                <p style="font-weight: lighter; color: #8392a5; font-size: 11px;  margin-bottom: 8px;">-->
    <!--                                  <span>{{education.educationDegree}}</span>-->
    <!--                                  <span v-if="education.educationDegree">{{', '+education.educationField}}</span>-->
    <!--                                  <span v-else>{{education.educationField}}</span>-->
    <!--                                  <span v-if="education.educationGrade || education.educationField">{{', '+education.educationGrade}}</span>-->
    <!--                                  <span v-else>{{education.educationGrade}}</span>-->
    <!--                                </p>-->
    <!--                                <p style="font-weight: lighter; color: #8392a5;  margin-bottom: 8px;">{{ education.educationStartYear }} - {{ education.educationEndYear }} </p>-->
    <!--                                <p style="font-weight: lighter; color: #8392a5;  margin-bottom: 8px;">Activities/Society: {{ education.educationActivities }}</p>-->
    <!--                                <p style="font-weight: lighter; color: #8392a5;  margin-bottom: 8px;">{{ education.educationDescription }} </p>-->
    <!--                            </b-col>-->
    <!--                          </b-row>-->
    <!--                          <br>-->
    <!--                          <br>-->
    <!--                          <h6>Employment History <plus-square-icon style="cursor: pointer;" title="Add new employee" @click="openModal('employment', {}, 'create')"/></h6>-->
    <!--                          <hr style="margin-bottom: -8px;">-->
    <!--                          <b-row>-->
    <!--                            <b-col md="3" class="border-left border-primary" style="line-height: 12px; margin-left: 20px; margin-top: 15px;" v-for="employment in this.employee.employments" v-bind:key="employment.employmentId">-->
    <!--                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ employment.employeeDetailRole | titleCase }} &nbsp;-->
    <!--                                  <b-icon-pencil v-if="!employment.employeeDetailEmployerId.match(/^[0-9]+$/)" style="cursor: pointer;" @click="openModal('employment',employment, 'edit')"/></p>-->
    <!--                                <p style="color: #8392a5; font-size: 11px;  margin-bottom: 8px;">{{ employment.employeeDetailEmployerName.toUpperCase() }}-->
    <!--                                  {{(employment.employeeDetailDepartment?' . ':'')+employment.employeeDetailDepartment.toUpperCase() }}-->
    <!--                                  {{(employment.employeeDetailType?'. ':'')+employment.employeeDetailType.toUpperCase() }}-->
    <!--                                </p>-->
    <!--                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ employment.employeeDetailStartDate.toLowerCase() }} - {{ employment.employeeDetailEndDate.toLowerCase() }}</p>-->
    <!--                                <p style="font-weight: lighter;  margin-bottom: 8px;">{{ employment.employeeDetailLocation }}</p>-->
    <!--                            </b-col>-->
    <!--                          </b-row>-->
    <!--                          <br><br><br><br><br><br><br><br>-->
    <!--                        </b-tab>-->
    <!--                        <b-tab class="pd-12" title="Projects And Task">-->
    <!--                          <br>-->
    <!--                          <project-task :row="activeEmployee"/>-->
    <!--                        </b-tab>-->
    <!--                      </b-tabs>-->
    <!--                    </span>-->
    <!--                  &lt;!&ndash; / &ndash;&gt;-->
    <!--                    <div v-else class="pd-20 pd-lg-25 pd-xl-30">-->
    <!--                        <span style="text-align: center;">-->
    <!--                            <h1 style="margin-top: 25%;"><b-icon-people style="height: 100px; width: 150px;"/></h1>-->
    <!--                            <p><b-button  @click="$refs['employee-modal'].show()" variant="warning">Add new employee</b-button></p>-->
    <!--                        </span>-->
    <!--                    </div>-->
    <!--                </div>&lt;!&ndash; mail-content-body &ndash;&gt;-->
    <!--            </div>&lt;!&ndash; mail-content &ndash;&gt;-->

    <!--        </div>&lt;!&ndash; mail-wrapper &ndash;&gt;-->
    <!--    </div>-->
    <!--      </span>-->
    <!--    <span v-else>-->
    <!--        <div class="content-body pd-12">-->
    <!--          <b-row>-->
    <!--          <b-col md="2" sm="4" cols="6"  v-for="employee in employees" v-bind:key="employee.employeeId">-->
    <!--            <div class="card card-profile">-->
    <!--              <img src="@/assets/img/default-image-orange.png" class="card-img-top" alt="">-->
    <!--              <div class="card-body tx-13">-->
    <!--                <div>-->
    <!--                  <a href="">-->
    <!--                    <div class="avatar avatar-lg"><img :src="employee.employeeProfileImage" class="rounded-circle" alt=""></div>-->
    <!--                  </a>-->
    <!--                  <h5><a href="">{{ employee.employeeFirstName+' '+employee.employeeMiddleName+' '+employee.employeeLastName | titleCase | formatTextWithEllipsis}}</a></h5>-->
    <!--                  <p>{{ employee.employeeDetailRole | titleCase | formatTextWithEllipsis}}</p>-->
    <!--                  <button class="btn btn-block btn-white" @click="selectEmployee(employee.employeeId)">View</button>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>&lt;!&ndash; card &ndash;&gt;-->
    <!--          </b-col>-->
    <!--          </b-row>-->
    <!--        </div>-->
    <!--      </span>-->


    <b-modal id="sla-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="sla-modal">
      <div class="modal-body">
        <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
           @click="hide()">
          <span aria-hidden="true">&times;</span>
        </a>

        <div>
          <h4>Add new SLA</h4>
          <b-form @submit.prevent="createSla" enctype="multipart/form-data">
            <!--            <b-form-group label="Staff Id">-->
            <!--              <b-input-group>-->
            <!--                <b-input required placeholder="Staff Id" v-model="model.employeeStaffId"/>-->
            <!--                <the-mask placeholder="BVN" required v-model="model.employeeBvn" maxlength="11" minlength="11" :mask="['###########', '###########']" class="form-control" />-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->
            <b-form-group label="SLA Code">
              <b-input-group>
                <b-input type="text" placeholder="SLA Code" required v-model="model.slaCode"/>
                <!--                <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>-->
                <!--                <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>-->
              </b-input-group>
            </b-form-group>


<!--            <b-form-group label="SLA Resource Code">-->
<!--              <b-input-group>-->
<!--                <b-input type="text" placeholder="SLA Resource Code" required v-model="model.slaResourceCode"/>-->
<!--                &lt;!&ndash;                <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>&ndash;&gt;-->
<!--                &lt;!&ndash;                <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>&ndash;&gt;-->
<!--              </b-input-group>-->
<!--            </b-form-group>-->

            <b-form-group label="SLA Resource Code">
              <b-select v-model="model.slaResourceCode" required placeholder="SLA Resource Code">
                <b-select-option  :value="employee.employeeDetailStaffId"  v-for="(employee, index) in employee.employees" :key="index">{{employee.employeeFirstName}} {{employee.employeeLastName}}: {{ employee.employeeDetailStaffId }}</b-select-option>
<!--                <b-select-option value="Type 2">Type 2</b-select-option>-->
              </b-select>
            </b-form-group>

            <b-form-group label="SLA Client Code">
              <b-select v-model="model.slaClientCode" required placeholder="SLA Client Code">
                <b-select-option :value="client.clientCode"  v-for="(client, index) in client.clients" :key="index">{{client.clientName}}:{{ client.clientCode }}</b-select-option>
                <!--                <b-select-option value="Type 2">Type 2</b-select-option>-->
              </b-select>
            </b-form-group>




<!--            <b-form-group label="SLA Client Code">-->
<!--              <b-input-group>-->
<!--                <b-input type="text" placeholder="SLA Client Code" required v-model="model.slaClientCode"/>-->
<!--                &lt;!&ndash;                <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>&ndash;&gt;-->
<!--                &lt;!&ndash;                <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>&ndash;&gt;-->
<!--              </b-input-group>-->
<!--            </b-form-group>-->

            <b-form-group label="SLA Day">
              <b-input type="number" placeholder="Enter SLA Day" required v-model="model.slaDay"/>
            </b-form-group>

<!--            <b-form-group label="SLA Type">-->
<!--              <b-input type="number" placeholder="Enter SLA Type" required v-model="model.slaType"/>-->
<!--            </b-form-group>-->


            <b-form-group label="SLA Type">
              <b-select v-model="model.slaType" placeholder="Select SLA Type">
                <b-select-option value="">Select Type</b-select-option>
                <b-select-option value="Type 1">Type 1</b-select-option>
                <b-select-option value="Type 2">Type 2</b-select-option>
              </b-select>
            </b-form-group>



            <b-form-group label="SLA Hourly Rate">
              <b-input-group>
                <b-input type="number" step="0.01" placeholder="SLA Hourly Rate" required v-model="model.slaHourlyRate"/>
                <!--                <b-input type="text" placeholder="Middle Name" v-model="model.employeeMiddleName"/>-->
                <!--                <b-input type="text" placeholder="Last Name" required v-model="model.employeeLastName"/>-->
              </b-input-group>
            </b-form-group>

            <div class="form-flex">
              <div class="form-flex-c">
                <b-form-group label="SLA Start Date">

    <!--                <vue-country-code @onSelect="onSelect" :preferredCountries="['vn', 'us', 'gb']">-->
    <!--                </vue-country-code>-->
    <b-input type="date"  placeholder="SLA Start Date" required v-model="model.slaStartDate"/>
  </b-form-group>
              </div>
              <div class="form-flex-c">
                <b-form-group label="SLA End Date">
    <b-input type="date" placeholder="SLA End Date" required v-model="model.slaEndDate"/>
  </b-form-group>
              </div>
            </div>

            <div class="form-flex">
              <div class="form-flex-c">
                <b-form-group label="SLA Hour Start">

                  <!--                <vue-country-code @onSelect="onSelect" :preferredCountries="['vn', 'us', 'gb']">-->
                  <!--                </vue-country-code>-->
                  <b-input type="number"  placeholder="SLA Hour Start" required v-model="model.slaHourStart" />
                </b-form-group>
              </div>
              <div class="form-flex-c">
                <b-form-group label="SLA Hour End">
                  <b-input type="number" placeholder="SLA Hour End" required v-model="model.slaHourEnd" />
                </b-form-group>
              </div>


            </div>

<!--            <b-form-group label="Client Phone Number">-->
<!--              <b-input-group>-->
<!--&lt;!&ndash;                <vue-country-code @onSelect="onSelect" :preferredCountries="['vn', 'us', 'gb']">&ndash;&gt;-->
<!--&lt;!&ndash;                </vue-country-code>&ndash;&gt;-->
<!--                <b-input type="number" placeholder="SLA Day" required v-model="model.slaDay"/>-->
<!--                <b-input type="text" placeholder="SLA Type" required v-model="model.slaType"/>-->
<!--              </b-input-group>-->
<!--            </b-form-group>-->
            <!--            <b-form-group label="ID Card">-->
            <!--              <b-input-group>-->
            <!--                <b-select v-model="model.employeeIdType">-->
            <!--                  <b-select-option value="">Select ID Type</b-select-option>-->
            <!--                  <b-select-option value="NATIONAL ID">NATIONAL ID</b-select-option>-->
            <!--                  <b-select-option value="INTERNATIONAL PASSPORT">INTERNATIONAL PASSPORT</b-select-option>-->
            <!--                  <b-select-option value="VOTERS CARD">VOTERS CARD</b-select-option>-->
            <!--                  <b-select-option value="DRIVER LICENCE">DRIVER LICENCE</b-select-option>-->
            <!--                  <b-select-option value="OTHERS">OTHERS</b-select-option>-->
            <!--                </b-select>-->
            <!--                <b-input type="text" placeholder="ID NUMBER" required v-model="model.employeeIdNumber"/>-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->
<!--            <b-form-group label="Country">-->
<!--              <country-select id="orgCountry" class="form-control" :countryName="true" :regionName="true" v-model="model.clientCountry" :country="model.clientCountry" topCountry="NG" />-->
<!--            </b-form-group>-->



            <!--            <b-form-group label="Date of birth">-->
            <!--              <b-input-group>-->
            <!--                <b-input type="date" placeholder="Date of birth" required v-model="model.employeeDob"/>-->
            <!--                <b-select v-model="model.employeeGender">-->
            <!--                  <b-select-option value="">Select Gender</b-select-option>-->
            <!--                  <b-select-option value="MALE">MALE</b-select-option>-->
            <!--                  <b-select-option value="FEMALE">FEMALE</b-select-option>-->
            <!--                </b-select>-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->

            <!--            <b-form-group label="Employment Details">-->
            <!--              <b-input-group>-->
            <!--                <b-select v-model="model.employeeDepartment">-->
            <!--                  <b-select-option value="">Select Department</b-select-option>-->
            <!--                  <b-select-option v-for="dpt in department.departments" :value="dpt.employerDepartmentCode" v-bind:key="dpt.employerDepartmentCode">{{dpt.employerDepartmentName}}</b-select-option>-->
            <!--                </b-select>-->
            <!--                <b-select v-model="model.employeeGradeCode">-->
            <!--                  <b-select-option value="">Select Grade</b-select-option>-->
            <!--                  <b-select-option v-for="grd in grade.grades" :value="grd.employerGradeCode" v-bind:key="grd.employerGradeCode">{{grd.employerGradeName}}</b-select-option>-->
            <!--                </b-select>-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->

<!--            <b-form-group label="Contact Person">-->
<!--              <b-input type="text" placeholder="Contact Person" required v-model="model.clientContactPerson"/>-->
<!--            </b-form-group>-->
            <base-button title="Submit" :loading="sla.slaLoading"/>
          </b-form>
        </div>
      </div><!-- modal-body -->
    </b-modal>


  </div>
</template>

<script>
import BaseTopCards from "../../components/card/BaseTopCards";
import BaseTable from "../../components/table/BaseTable";
import {mapState} from "vuex";
import SLARequest from "@/model/request/SLARequest";
import BaseButton from "@/components/button/BaseButton";
import StoreUtils from "@/utils/BaseUtils/StoreUtils";
import ClientRequest from "@/model/request/ClientRequest";
export default {
  name: "SLA",
  components: { BaseTopCards, BaseTable, BaseButton },
  data(){
    return{
      model: SLARequest.create,
      readModel: SLARequest.readByCompany,
      readClientModel: ClientRequest.readByCompanyCode,
      fields: [
        // { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'slaId', label: 'SLA Id', sortable: true, sortDirection: 'desc' },
        { key: 'slaCode', label: 'SLA Code', sortable: true, sortDirection: 'desc' },
        { key: 'slaResourceCode', label: 'Resource Code', sortable: true, class: 'text-left' },
        { key: 'slaClientCode', label: 'Client Code', sortable: true, class: 'text-left' },

        { key: 'slaType', label: 'Type', sortable: true, class: 'text-left' },
        { key: 'slaDay', label: 'Day', sortable: true, class: 'text-left' },
        { key: 'slaStartDate', label: 'Start Date', sortable: true, class: 'text-left' },
        { key: 'slaEndDate', label: 'End Date', sortable: true, class: 'text-left' },
        { key: 'slaHourStart', label: 'Start Hour', sortable: true, class: 'text-left' },
        { key: 'slaHourEnd', label: 'End Hour', sortable: true, class: 'text-left' },
        // { key: 'employeeDetailDate', label: 'Date of Reg.', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'slaStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'actionsSla', label: 'Action', sortable: true, class: 'text-center' }
      ]
    }
  },
  computed:{
    cards:{
      get(){
        return [
          {
            id: 0,
            name: 'Total SLAs',
            number: this.slas.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
            color: 'bg-primary'
          },
          {
            id: 1,
            name: 'Active SLAs',
            number: this.slas.filter(it => it.slaStatus.toUpperCase() === 'ACTIVE').length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
            color: 'bg-orange'
          },
          {
            id: 2,
            name: 'Pending SLAs',
            // number: this.employees.filter(it => it.employeeDetailStatus.toUpperCase() === 'PENDING').length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
            number: this.slas.filter(it => it.slaStatus.toUpperCase() === 'PENDING').length,
            color: 'bg-success'
          },
          {
            id: 3,
            name: 'Suspended SLAs',
            // number: this.employees.filter(it => it.employeeDetailStatus.toUpperCase() === 'SUSPENDED').length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
            number: this.slas.filter(it => it.slaStatus.toUpperCase() === 'SUSPENDED').length,
            color: 'bg-black-8'
          }
        ]
      },
      set(){

      }
    },
    slas(){
      return  this.sla.slas?this.sla.slas:[]
    },
    ...mapState([
      'employee','sla', 'auth', 'client'])
  },
  methods:{
    hide() {
      this.$refs['sla-modal'].hide()
      // this.$refs['employee-upload-modal'].hide()
    },
    onSelect({name, iso2, dialCode}) {
      this.model.employeeCountryCode =  dialCode
      console.log(name, iso2, dialCode);
    },
    async createSla(){
      this.model.slaCompanyCode = localStorage.orgId;
      await StoreUtils.dispatch(StoreUtils.actions.sla.CREATE_SLA, this.model)
      this.hide();
    }
  },
  mounted(){
    this.readClientModel.clientCompanyCode = localStorage.orgId;
    StoreUtils.dispatch(StoreUtils.actions.client.READ_BY_COMPANY_CODE, this.readClientModel);

    this.readModel.slaCompanyCode = localStorage.orgId;
    StoreUtils.dispatch(StoreUtils.actions.sla.READ_BY_COMPANY_CODE, this.readModel);
  }
}
</script>

<style scoped>
.form-flex{
  display: flex;
  justify-content: space-between;
  gap: 10px;

}
.form-flex-c{
  width: 50%;
}

</style>