<template>
    <div class="content-body">
        <div class="container pd-x-0">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item"><a href="#">Onboarding</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Settings</li>
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">{{$t('sidebar.settings')}}</h4>
                    <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}}  {{auth.userInfo.lastName}}</p>
<!--                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.lastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
                </div>
                <div class="d-none">
                    <router-link to="/onboarding/new-officer" class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><plus-icon class="wd-10 mg-r-5" /> {{$t('extra.newOfficer')}}</router-link>
                </div>
            </div>

            <!--TODO TOP CARDS-->
<!--            <top-cards></top-cards>-->
            <!--TODO GROWTH-->
            <onboard-settings-form/>
            <!--TODO REVENUE-->
            <!-- <revenue></revenue>-->
            <!--TODO HISTORY-->
            <!-- <history></history>-->
        </div><!-- container -->
    </div>
</template>

<script>
    // @ is an alias to /src
    // import TopCards from "../../components/card/TopCards";
    // import Growth from "../../components/card/Growth";
    import OnboardSettingsForm from "../../components/form/OnboardSettingsForm";
    // import Revenue from "../../components/card/Revenue";
    // import History from "../../components/card/History";
    import {PlusIcon} from 'vue-feather-icons'
    import {mapState} from 'vuex'
    // import NewAccountButton from "../../components/button/NewAccountButton";
    export default {
        name: 'Home',
        components:{
            // NewAccountButton,
            // TopCards,
            OnboardSettingsForm,
            PlusIcon,
            // History
        },
        data(){
            return{
                model:{
                    employerId:'',
                    employerName:'',
                    data:''
                },
                showUpload:true,
            }
        },
        methods:{
            hide() {
                this.$refs['new-cheque-modal'].hide()
            },
        },
        computed:{
            ...mapState([
                'company',
                'auth',
            ]),

        },
        created() {
            //this.$store.dispatch("account/updateAccounts", {orgId: localStorage.orgId}, {root: false}).then()
        },
        mounted() {
            console.log(this.$route.name)
        }
    }
</script>
