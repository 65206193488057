import apiClient from "./BaseService";

export default {
    callCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employerGradeCreate", payload);
    },
    callReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employerGradeRead", payload);
    },
    callCreateGradeSalaryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employerGradeSalaryCreate", payload);
    },
    callUpdateGradeSalaryApi(payload) {
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employerGradeSalaryUpdate", payload);
    },
    callUpdateStatusGradeSalaryApi(payload) {
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employerGradeSalaryUpdateStatus", payload);
    },
    callGradeSalaryReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        return apiClient.qooSquare.post("employerGradeSalaryRead", payload);
    },
};
