<template>
    <div class="content ht-100v pd-0">
        <div class="content-body pd-10">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item">
                                <router-link to="#">Grade Salary</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Grade Salaries</li>
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">View Grade Salaries for Grade Code ({{gradeCode}})</h4>
                    <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
                    <!--                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLoginDate | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
                </div>
                <div class="d-none d-md-block">
                    <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"  v-b-modal="'grade-salary-modal'"><plus-icon class="wd-10 mg-r-5" /> Add Grade Salary</button>
                    <download-excel
                            :data="grades"
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
                    </download-excel>
                    <download-excel
                            type="csv"
                            :data="grades"
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
                    </download-excel>

                </div>
            </div>
            <base-top-cards :carditems="cards"/>
            <hr>

            <base-table
                    :items="gradeSalaries"
                    :fields="fields"
                    :show-title="true"
                    :is-busy="grade.gradeLoading"
                    class="mt-2"
                    filter-mode="balances"
            ></base-table>
        </div>
        <b-modal id="grade-salary-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="grade-salary-modal">
            <div class="modal-body">
                <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
                   @click="hide()">
                    <span aria-hidden="true">&times;</span>
                </a>
                <div>
                    <h4>Add new grade salary</h4>
                    <b-form @submit.prevent="createGradeSalary" enctype="multipart/form-data">
                        <b-form-group label="Salary Item">
                            <b-input required v-model="model.employerGradeSalaryItem"/>
                        </b-form-group>
                        <b-form-group label="Amount">
                            <b-input type="number" step="0.01" required v-model="model.employerGradeSalaryItemAmount"/>
                        </b-form-group>
                        <b-form-group label="Serial">
                            <b-input type="number"  required v-model="model.employerGradeSalaryItemSerial"/>
                        </b-form-group>
                        <b-form-group label="Exempt Tax">
                            <b-select required v-model="model.employerGradeSalaryItemTaxExempt">
                                <b-select-option  value="YES">YES</b-select-option>
                                <b-select-option  value="NO">NO</b-select-option>
                            </b-select>
                        </b-form-group>
                        <b-form-group label="Debit Account">
                            <b-select v-model="model.employerGradeSalaryDebitAccount">
                              <b-select-option value="">Choose salary account</b-select-option>
                              <b-select-option v-for="account in modelAccounts" :value="account.accountNumber" v-bind:key="account.accountNumber">{{account.accountNumber}} - {{account.accountBankName}}</b-select-option>
                            </b-select>
                        </b-form-group>
                        <b-form-group label="Type">
                            <b-select required v-model="model.employerGradeSalaryItemType">
                                <b-select-option  value="EARNINGS">EARNINGS</b-select-option>
                                <b-select-option  value="DEDUCTIONS">DEDUCTIONS</b-select-option>
                            </b-select>
                        </b-form-group>
                        <base-button title="Submit" :loading="grade.gradeLoading"/>
                    </b-form>
                </div>
            </div><!-- modal-body -->
        </b-modal>
    </div>
</template>

<script>
    import BaseTopCards from "../../components/card/BaseTopCards";
    import BaseTable from "../../components/table/BaseTable";
    import BaseButton from "../../components/button/BaseButton";
    import { PrinterIcon, PlusIcon } from 'vue-feather-icons'
    import {mapState} from 'vuex'

    export default {
        name: 'Loan',
        data() {
            return {
                model:{
                    employerGradeSalaryEmployerId: '',
                    employerGradeSalaryGradeCode: '',
                    employerGradeSalaryItem: '',
                    employerGradeSalaryItemAmount: '',
                    employerGradeSalaryItemSerial: '',
                    employerGradeSalaryDebitAccount: '',
                    employerGradeSalaryItemTaxExempt: 'YES',
                    employerGradeSalaryItemType: 'EARNINGS'
                },
                fields: [
                    { key: 'employerGradeSalaryItemSerial', label: 'Serial', sortable: true, sortDirection: 'desc' },
                    { key: 'employerGradeSalaryItem', label: 'Salary Item', sortable: true, class: 'text-left' },
                    { key: 'employerGradeSalaryItemAmount', label: 'Salary Amount', sortable: true, class: 'text-right' },
                    { key: 'employerGradeSalaryPeriod', label: 'Salary Period', sortable: true, class: 'text-left' },
                    { key: 'employerGradeSalaryItemTaxExempt', label: 'Exempt Tax', sortable: true, class: 'text-left' },
                    { key: 'employerGradeSalaryItemType', label: 'Type', sortable: true, class: 'text-left' },
                    { key: 'employerGradeSalaryStatus', label: 'Status', sortable: true, class: 'text-left' },
                    { key: 'actionsGradeSalary', label: 'Action', sortable: true, class: 'text-center' }
                ],
            }
        },
        components: {
            PlusIcon,
            BaseTable,
            BaseButton,
            BaseTopCards,
            PrinterIcon
        },
        computed: {
            cards:{
                get(){
                    return [
                        {
                            id: 0,
                            name: 'Total Grade Salary',
                            number: this.gradeSalaries.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
                            color: 'bg-primary'
                        },
                        {
                            id: 1,
                            name: 'Total Earnings',
                            number: this.gradeSalaries.reduce((init, curr) => (
                                init+=parseFloat(curr.employerGradeSalaryItemType==="EARNINGS"?curr.employerGradeSalaryItemAmount:'0.00')
                            ),0).toFixed(2),
                            color: 'bg-orange'
                        },
                        {
                            id: 2,
                            name: 'Total Deductions',
                            number: this.gradeSalaries.reduce((init, curr) => (
                                init+=parseFloat(curr.employerGradeSalaryItemType==="DEDUCTIONS"?curr.employerGradeSalaryItemAmount:'0.00')
                            ),0).toFixed(2),
                            color: 'bg-success'
                        },
                        {
                            id: 3,
                            name: 'Net Salary',
                            number:this.gradeSalaries.reduce((init, curr) => (
                                init+=parseFloat(curr.employerGradeSalaryItemType!=="EARNINGS"?'0.00':curr.employerGradeSalaryItemAmount.toString().replace(/,/g,''))
                            ),0).toFixed(2)-this.gradeSalaries.reduce((init, curr) => (
                                init+=parseFloat(curr.employerGradeSalaryItemType!=="DEDUCTIONS"?'0.00':curr.employerGradeSalaryItemAmount.toString().replace(/,/g,''))
                            ),0).toFixed(2),
                            color: 'bg-black-8'
                        }
                    ]
                },
                set(){

                }
            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            grades(){
                return  this.grade.grades
            },
            gradeSalaries(){
                return  this.grade.gradeSalaries
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            modelAccounts:{
              get(){
                console.log(this.$store.getters['auth/activeCompany'].accounts);
                return this.$store.getters['auth/activeCompany'].accounts
              },
              // set(val){
              //     this.model.accounts = val uploadModel
              // }
            },
            ...mapState([
                'grade',
                'auth'
            ])
        },
        methods: {
            async createGradeSalary(){
                this.model.employerGradeSalaryGradeCode = localStorage._gradeCode;
                this.model.employerGradeSalaryMaker = `${this.auth.userInfo.firstName} ${this.auth.userInfo.lastName}`;
                this.model.employerGradeSalaryEmployerId = localStorage.orgId;
                await this.$store.dispatch("grade/createGradeSalary", this.model, {root: false});
                this.loadGrade();
                this.hide();
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            loadGrade(){
                this.$store.dispatch("grade/updateGradeSalaries", {
                    readAll:'NO',
                    employerGradeSalaryEmployerId: localStorage.orgId,
                    employerGradeSalaryGradeCode: localStorage._gradeCode
                }, {root: false}).then()
            },
            hide() {
                this.$refs['grade-salary-modal'].hide()
            },
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {
            this.$store.dispatch("grade/updateGradeSalaries", {
                readAll:'NO',
                employerGradeSalaryEmployerId: localStorage.orgId,
                employerGradeSalaryGradeCode: localStorage._gradeCode
            }, {root: false}).then()
        }
    }


</script>

<style scoped>

</style>
